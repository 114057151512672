<div *ngIf="!isEntree">
<h2>Détails sortie [ {{sortie!.typeSortie}} ]</h2>

<mat-dialog-content>



<div class="row">

	<div class="col-md-12">

	<div class="row" *ngIf="sortie!.prenom"><div class="col-md-3">
		Client:</div><div class="col-md-9" (click)="clickName(0, sortie!.Idclient)" style="cursor: pointer; color: aqua;">{{sortie!.nom | uppercase}}, {{sortie!.prenom}}
  </div>
	</div>

	<div class="row" *ngIf="!sortie!.prenom"><div class="col-md-3">
		Animal:</div><div class="col-md-9" (click)="clickName(sortie!.animalType, sortie!.Idanimal)" style="cursor: pointer; color: aqua;">{{sortie!.nom}}
	</div>
	</div>

	<!-- <div class="row"><div class="col-md-3">
		Age:</div><div class="col-md-9"> {{sortie!.age}}
	</div>
	</div> -->

	<div class="row"><div class="col-md-3">
		Date:</div><div class="col-md-9"> {{sortie!.dateSortie}}
	</div>
	</div>

	<div class="row"><div class="col-md-3">
		PAF:</div><div class="col-md-9"> {{sortie!.PAF}}€
	</div>
	</div>
	<div class="row" *ngIf="sortie!.placeur"><div class="col-md-3">
		Placeur:</div><div class="col-md-9">{{sortie!.placeur}}
	</div>
	</div>
	<div class="row" *ngIf="sortie!.localisation"><div class="col-md-3">
		Localisation:</div><div class="col-md-9">{{sortie!.localisation}}
	</div>
	</div>
	<div class="row" *ngIf="sortie!.commentaire"><div class="col-md-3">
		Commentaire:</div><div class="col-md-9">{{sortie!.commentaire}}
	</div>
	</div>

</div>
</div>

<div class="row" *ngIf="sortie!.typeSortie == 'Adoption'">
<div class="col-md-12">
Les détails de l'adoption sont disponible dans le panel adoption de la fiche animale.
</div>
</div>


</mat-dialog-content>
</div>

<div *ngIf="isEntree">
<h2>Détails entrée [ {{entree!.typeEntree}} ]</h2>

<mat-dialog-content>

<div class="row">

	<div class="col-md-12">

	<div class="row" *ngIf="entree!.Prenom"><div class="col-md-4">
		Client:</div><div class="col-md-8" (click)="clickName(0, entree!.Idclient)" style="cursor: pointer; color: aqua;">{{entree!.nom | uppercase}}, {{entree!.Prenom}}
	</div>
	</div>

	<div class="row" *ngIf="!entree!.Prenom"><div class="col-md-4">
		Animal:</div><div class="col-md-8" (click)="clickName(entree!.animalType, entree!.Idanimal)" style="cursor: pointer; color: aqua;">{{entree!.nom}}
	</div>
	</div>

	<!-- <div class="row"><div class="col-md-3">	 -->
		<!-- Type:</div><div class="col-md-9">  -->
	<!-- </div> -->
	<!-- </div> -->


	<!-- <div class="row"><div class="col-md-3">
		Age:</div><div class="col-md-9"> {{entree!.age}}
	</div>
	</div> -->


	<div class="row"><div class="col-md-4">
		Date:</div><div class="col-md-8"> {{entree!.dateEntree}}
	</div>
	</div>

	<div class="row"><div class="col-md-4">
		PAF:</div><div class="col-md-8"> {{entree!.PAF}}€
	</div>
	</div>
	<div class="row" *ngIf="entree!.durePrevue"><div class="col-md-4">
		Durée prévue:</div><div class="col-md-8">{{entree!.durePrevue}} jours
	</div>
	</div>
	<div class="row" *ngIf="entree!.numeroCession"><div class="col-md-4">
		Numéro de cession:</div><div class="col-md-8">	{{entree!.numeroCession}}
	</div>
	</div>

</div>
</div>

</mat-dialog-content>
</div>

<div class="row">

	<div class="col-md-4">

		<mat-dialog-actions>
		    <button class="mat-raised-button"(click)="close()">Fermer</button>
		</mat-dialog-actions>

	</div>

		<div class="col-md-5">
		</div>
		<div class="col-md-3">

		<mat-dialog-actions  *ngIf="isEntree">
		    <button [color]="deleteConfirm" mat-raised-button (click)="delete('entreeanimal', 'id', entree!.id)">Supprimer</button>
		</mat-dialog-actions>

		<mat-dialog-actions  *ngIf="!isEntree">
		    <button [color]="deleteConfirm" mat-raised-button (click)="delete('sortieanimal', 'idSortie', sortie!.idSortie)">Supprimer</button>
		</mat-dialog-actions>

	</div>

</div>

