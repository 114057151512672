<div class="row">

<div class="col-md-10">
	<div class="card card-user">

	  <div class="card-body">

<div>
  <ngx-datatable  class="material" [rows]="loggings" [columns]="columns"
  [rowHeight]="40" [limit]="20" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true" [sorts]="[{prop: 'date', dir: 'desc'}]">

  </ngx-datatable>
</div>

</div>
</div>
</div>

<div class="col-md-2">
	<div class="card card-user">
	  <div class="card-body">
	  
		  <div class="card-header">
			<h5 class="card-title">Filtrer par</h5>
		  </div>
		  
		  
		  <form id="form-fitler">
			<div class="col-md-12">
			
			<div class="form-group">
				<label>Identifiant</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['login']" (keyup)="updateFilter()">
		  </div>
		  
		  
		  </div>
			
			  
			  
			   <div class="row">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter()">Reset</button>
			</div>
			</div>

		</form>
	

</div>
</div>
</div>

</div>
