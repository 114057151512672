<!--
<div class="row">
<div class="col-md-2">

	<div class="card card-user"  *ngIf="animal">

	  <div class="card-header">
		<h5 class="card-title">{{ animal.nom | uppercase}}</h5>
	  </div>

	  <div class="card-body">
		<form>


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>sexe</label>

						<input type="text"
								class="form-control"
							   name="sexe"

							   [formControl]="sexeControl"
							   [matAutocomplete]="auto0"
							   [(ngModel)]="animal!.sexe"/>

						<mat-autocomplete autoActiveFirstOption #auto0="matAutocomplete" (optionSelected)="updateField('animaux', 'sexe')">
						  <mat-option *ngFor="let option of filteredsexeOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date adoptable</label>
					<input type="text" name="dateAdoptable" class="form-control" matInput [matDatepicker]="dateAdoptable" [(ngModel)]="animal!.dateAdoptable" (dateChange)="updateField('animaux', 'dateAdoptable')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateAdoptable"></mat-datepicker-toggle>
					<mat-datepicker #dateAdoptable ></mat-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de naissance</label>
					<input type="text" name="dateNaissance" class="form-control" matInput [matDatepicker]="dateNaissance" [(ngModel)]="animal!.dateNaissance"
					(dateChange)="updateField('animaux', 'dateNaissance')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Race</label>

						<input type="text"
								class="form-control"
							   name="race"

							   [formControl]="raceControl"
							   [matAutocomplete]="auto"
							   [(ngModel)]="animal!.race"/>

						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="updateField('animaux', 'race')">
						  <mat-option *ngFor="let option of filteredRaceOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>

			<div class="form-group">
				<label>Age</label>

				<select name="filtreStatut" class="form-control"
					[(ngModel)]="animal!.age" (change)="updateField('animaux', 'age')">

				  <option value="Cud">Cud</option>
				  <option value="Adulte">Adulte</option>

				</select>
			  </div>


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Détail robe (1)</label>

						<input type="text"
								class="form-control"
							   name="robe1"
							   [formControl]="robe1Control"
							   [matAutocomplete]="auto2"
							   [(ngModel)]="animal!.robe1">

						<mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="updateField('chiens', 'robe1')">
						  <mat-option *ngFor="let option of filteredRobe1Options | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Détail robe (2)</label>

						<input type="text"
								class="form-control"
							   name="robe2"
							   [formControl]="robe2Control"
							   [matAutocomplete]="auto3"
							   [(ngModel)]="animal!.robe2">

						<mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="updateField('chiens', 'robe2')">
						  <mat-option *ngFor="let option of filteredRobe2Options | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>



			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Site</label>

						<input type="text"
								class="form-control"
							   name="site"

							   [formControl]="siteControl"
							   [matAutocomplete]="auto11"
							   [(ngModel)]="animal!.site"/>

						<mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete" (optionSelected)="updateField('animaux', 'site')">
						  <mat-option *ngFor="let option of filteredSiteOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label for="transfertIdentification">Transfert d'identification effectué</label>
					<input type="checkbox" name="transfertIdentification" class="form-control" [(ngModel)]="animal!.transfertIdentification" (change)="updateField('animaux', 'transfertIdentification')">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Numéro d'identification</label>
					<input type="text" name="identificationAnimal" class="form-control" [(ngModel)]="animal!.identificationAnimal" (focusout)="updateField('animaux', 'identificationAnimal')">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date d'identification</label>
					<input type="text" name="dateIdentification" class="form-control" matInput [matDatepicker]="dateIdentification" [(ngModel)]="animal!.dateIdentification" (dateChange)="updateField('animaux', 'dateIdentification')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateIdentification"></mat-datepicker-toggle>
					<mat-datepicker #dateIdentification></mat-datepicker>
				</div>
			</div>



			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Numéro de passerpot</label>
					<input type="text" name="numeroPasseport" class="form-control" [(ngModel)]="animal!.numeroPasseport" (focusout)="updateField('animaux', 'numeroPasseport')">
				  </div>
				</div>
			</div>

			<hr class="rounded">

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire comportement</label>
				<textarea class="form-control textarea" [(ngModel)]="animal!.commentaireComportement" name="commentaireComportement" (focusout)="updateField('animaux', 'commentaireComportement')"></textarea>
			  </div>
			</div>
		  </div>

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Autre commentaire</label>
				<textarea class="form-control textarea" [(ngModel)]="animal!.commentaireAutre" name="commentaireAutre" (focusout)="updateField('animaux', 'commentaireAutre')"></textarea>
			  </div>
			</div>
		  </div>
		  </form>
	  </div>
  </div>
  </div>




	<div class="col-md-2">

		<div class="card card-user"  *ngIf="animal">
	
		  <div class="card-header">
			<h5 class="card-title">Fiche santé</h5>
		  </div>
	
		  <div class="card-body">
			<form>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Stérilisé</label>
						<input type="checkbox" name="sterilisation" class="form-control" [(ngModel)]="animal!.sterilisation" (change)="updateField('animaux', 'sterilisation')">
					  </div>
					</div>
				</div>
	
	
	
				<div class="row"  *ngIf="animal!.sterilisation">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date de stérilisation</label>
						<input type="text" name="sterilisationDate" class="form-control" matInput [matDatepicker]="sterilisationDate" [(ngModel)]="animal!.sterilisationDate" (dateChange)="updateField('animaux', 'sterilisationDate')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="sterilisationDate"></mat-datepicker-toggle>
						<mat-datepicker #sterilisationDate></mat-datepicker>
					</div>
				</div>
	
				<hr class="rounded">
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Vaccination Lepto</label>
						<input type="text" name="dateVacc1" class="form-control" matInput [matDatepicker]="dateVacc1" [(ngModel)]="animal!.dateVacc1" (dateChange)="updateField('animaux', 'dateVacc1');updateDateVacc('dateVacc1')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateVacc1"></mat-datepicker-toggle>
						<mat-datepicker #dateVacc1></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Rappel Lepto</label>
						<input type="text" name="rappelVacc1" class="form-control" matInput [matDatepicker]="rappelVacc1" [(ngModel)]="animal!.rappelVacc1" (dateChange)="updateField('animaux', 'rappelVacc1')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="rappelVacc1"></mat-datepicker-toggle>
						<mat-datepicker #rappelVacc1></mat-datepicker>
					</div>
				</div>
	
				<hr class="rounded">
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date Parvo / Carré</label>
						<input type="text" name="dateVacc2" class="form-control" matInput [matDatepicker]="dateVacc2" [(ngModel)]="animal!.dateVacc2" (dateChange)="updateField('animaux', 'dateVacc2');updateDateVacc('dateVacc2')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateVacc2"></mat-datepicker-toggle>
						<mat-datepicker #dateVacc2></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Rappel Parvo / Carré</label>
						<input type="text" name="rappelVacc2" class="form-control" matInput [matDatepicker]="rappelVacc2" [(ngModel)]="animal!.rappelVacc2" (dateChange)="updateField('animaux', 'rappelVacc2')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="rappelVacc2"></mat-datepicker-toggle>
						<mat-datepicker #rappelVacc2></mat-datepicker>
					</div>
				</div>
	
	
				<hr class="rounded">
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date Toux du chenil </label>
						<input type="text" name="dateVacc3" class="form-control" matInput [matDatepicker]="dateVacc3" [(ngModel)]="animal!.dateVacc3" (dateChange)="updateField('animaux', 'dateVacc3');updateDateVacc('dateVacc3')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateVacc3"></mat-datepicker-toggle>
						<mat-datepicker #dateVacc3></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Rappel Toux du chenil </label>
						<input type="text" name="rappelVacc3" class="form-control" matInput [matDatepicker]="rappelVacc3" [(ngModel)]="animal!.rappelVacc3" (dateChange)="updateField('animaux', 'rappelVacc3')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="rappelVacc3"></mat-datepicker-toggle>
						<mat-datepicker #rappelVacc3></mat-datepicker>
					</div>
				</div>
	
				<hr class="rounded">
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date antipuce</label>
						<input type="text" name="dateAntipuce" class="form-control" matInput [matDatepicker]="dateAntipuce" [(ngModel)]="animal!.dateAntipuce" (dateChange)="updateField('chiens', 'dateAntipuce');updateDateVacc('dateAntipuce')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateAntipuce"></mat-datepicker-toggle>
						<mat-datepicker #dateAntipuce></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Rappel antipuce</label>
						<input type="text" name="rappelAntipuce" class="form-control" matInput [matDatepicker]="rappelAntipuce" [(ngModel)]="animal!.rappelAntipuce" (dateChange)="updateField('chiens', 'rappelAntipuce')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="rappelAntipuce"></mat-datepicker-toggle>
						<mat-datepicker #rappelAntipuce></mat-datepicker>
					</div>
				</div>
	
				<hr class="rounded">
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date vermifuge</label>
						<input type="text" name="dateVermifuge" class="form-control" matInput [matDatepicker]="dateVermifuge" [(ngModel)]="animal!.dateVermifuge" (dateChange)="updateField('chiens', 'dateVermifuge');updateDateVacc('dateVermifuge')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateVermifuge"></mat-datepicker-toggle>
						<mat-datepicker #dateVermifuge></mat-datepicker>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Rappel vermifuge</label>
						<input type="text" name="rappelVermifuge" class="form-control" matInput [matDatepicker]="rappelVermifuge" [(ngModel)]="animal!.rappelVermifuge" (dateChange)="updateField('chiens', 'rappelVermifuge')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="rappelVermifuge"></mat-datepicker-toggle>
						<mat-datepicker #rappelVermifuge></mat-datepicker>
					</div>
				</div>
	
	
				<hr class="rounded">
	
	
	
			  <div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Commentaire santé</label>
					<textarea class="form-control textarea" [(ngModel)]="animal!.commentaireSante" name="commentaireSante"  (focusout)="updateField('animaux', 'commentaireSante')"></textarea>
				  </div>
				</div>
			  </div>
			  </form>
		  </div>
	  </div>
	  </div>
	
	
	  <div class="col-md-2">
	
		<div class="card card-user"  *ngIf="animal">
	
		  <div class="card-header">
			<h5 class="card-title">Statut / Localisation</h5>
		  </div>
	
		  <div class="card-body">
			<form>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Présent au refuge</label>
						<input type="checkbox" name="auRefuge" class="form-control" [(ngModel)]="animal!.auRefuge" (change)="updateField('animaux', 'auRefuge');auRefuge()">
					  </div>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Ajouté sur site web</label>
						<input type="checkbox" name="ajouteSurSite" class="form-control" [(ngModel)]="animal!.ajouteSurSite" (change)="updateField('animaux', 'ajouteSurSite')">
					  </div>
					</div>
				</div>
	
	
				<hr class="rounded">
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Réservé</label>
						<input type="checkbox" name="Reservation" class="form-control" [(ngModel)]="animal!.Reservation" (change)="updateField('animaux', 'Reservation')">
					  </div>
					</div>
				</div>
	
				<div class="row"  *ngIf="animal!.Reservation">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date de réservation</label>
						<input type="text" name="dateReservation" class="form-control" matInput [matDatepicker]="dateReservation" [(ngModel)]="animal!.dateReservation" (dateChange)="updateField('animaux', 'dateReservation')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateReservation"></mat-datepicker-toggle>
						<mat-datepicker #dateReservation></mat-datepicker>
					</div>
				</div>
	
				<div class="row"  *ngIf="animal!.Reservation">
					<div class="col-md-12">
						<div class="form-group">
						<label>Réservé par client</label>
	
							<input type="text"
									class="form-control"
								   name="clientReservation"
								   [formControl]="resaClientsControl"
								   [matAutocomplete]="auto12"
								   [(ngModel)]="animal!.clientReservation">
	
							<mat-autocomplete autoActiveFirstOption #auto12="matAutocomplete"
							(optionSelected)="updateField('animaux', 'clientReservation')">
							  <mat-option *ngFor="let option of filteredResaClientsOptions | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
				<div class="row"  *ngIf="animal!.Reservation">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Commentaire réservation</label>
					<textarea class="form-control textarea" [(ngModel)]="animal!.commentaireReservation" name="commentaireReservation"  (focusout)="updateField('animaux', 'commentaireReservation')"></textarea>
				  </div>
				</div>
			  </div>
	
				<hr class="rounded">
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Remis au maître</label>
						<input type="checkbox" name="remisMaitre" class="form-control" [(ngModel)]="animal!.remisMaitre" (change)="updateField('animaux', 'remisMaitre');horsRefuge()">
					  </div>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Statut spécial</label>
						<input type="checkbox" name="statusSpecial" class="form-control" [(ngModel)]="animal!.statusSpecial" (change)="updateField('animaux', 'statusSpecial')">
					  </div>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>CONFIDENTIEL</label>
						<input type="checkbox" name="statusSpecial" class="form-control" [(ngModel)]="animal!.confidentiel" (change)="updateField('animaux', 'confidentiel')">
					  </div>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Adopté</label>
						<input type="checkbox" name="adopte" class="form-control" [(ngModel)]="animal!.adopte" (change)="updateField('animaux', 'adopte');horsRefuge()">
					  </div>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>En famille d'accueil</label>
						<input type="checkbox" name="familleAccueil" class="form-control" [(ngModel)]="animal!.familleAccueil"  (change)="updateField('animaux', 'familleAccueil');horsRefuge()">
					  </div>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Volé</label>
						<input type="checkbox" name="vole" class="form-control" [(ngModel)]="animal!.vole" (change)="updateField('animaux', 'vole');horsRefuge()">
					  </div>
					</div>
				</div>
	
				<hr class="rounded">
	
				<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Décédé</label>
						<input type="checkbox" name="DCD" class="form-control" [(ngModel)]="animal!.DCD" (change)="updateField('animaux', 'DCD');horsRefuge()">
					  </div>
					</div>
				</div>
	
	
				<div class="row"  *ngIf="animal!.DCD">
					<div class="col-md-12">
						<div class="form-group">
						<label>Dans / hors refuge</label>
	
							<input type="text"
									class="form-control"
								   name="lieuDCD"
	
								   [formControl]="dcdControl"
								   [matAutocomplete]="auto13"
								   [(ngModel)]="animal!.lieuDCD"/>
	
							<mat-autocomplete autoActiveFirstOption #auto13="matAutocomplete" (optionSelected)="updateField('animaux', 'lieuDCD')">
							  <mat-option *ngFor="let option of filteredDCDOptions | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
	
				<div class="row"  *ngIf="animal!.DCD">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date décès</label>
						<input type="text" name="dateDeces" class="form-control" matInput [matDatepicker]="dateDeces" [(ngModel)]="animal!.dateDeces" (dateChange)="updateField('animaux', 'dateDeces')">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateDeces"></mat-datepicker-toggle>
						<mat-datepicker #dateDeces></mat-datepicker>
					</div>
				</div>
	
				<div class="row"  *ngIf="animal!.DCD">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Commentaire décès</label>
					<textarea class="form-control textarea" [(ngModel)]="animal!.commentaireDeces" name="commentaireDeces" (focusout)="updateField('animaux', 'commentaireDeces')"></textarea>
				  </div>
				</div>
			  </div>
	
			  </form>
		  </div>
	  </div>
	  </div>
	
	
	
	
	  <div class="col-md-6">
	
	
			<div class="card card-user"  *ngIf="entrees">
			  <div class="card-header">
				<h5 class="card-title">Entrées</h5>
			  </div>
			  <div class="card-body">
				<ngx-datatable  class="material" [rows]="entrees" [columns]="columnsEntrees"
	  (activate)="onSelect($event)"  [limit]="10" [headerHeight]="50"  [footerHeight]="30" [scrollbarH]="true">
				</ngx-datatable>
			  </div>
			 </div>
	
			<div class="card card-user"  *ngIf="sorties">
			  <div class="card-header">
				<h5 class="card-title">Sorties</h5>
			  </div>
			  <div class="card-body">
				<ngx-datatable  class="material" [rows]="sorties" [columns]="columnsSorties"
	  (activate)="onSelect($event)"  [limit]="10" [headerHeight]="50"  [footerHeight]="30" [scrollbarH]="true">
				</ngx-datatable>
			  </div>
			 </div>
	
	
	
	
			<div class="card card-user"  *ngIf="adoption">
	
	
	
			  <div class="card-header">
				<h5 class="card-title">Adoptions</h5>
			  </div>
	
	
	
			  <div class="card-body">
	
				<form>
	
				<div class="row">
	
	
					<div class="col-md-3">
					  <div class="form-group">
						<label>Date sortie</label>
						<input type="text" name="dateSortie" class="form-control" matInput [matDatepicker]="dateSortie" [(ngModel)]="sortieAdoption!.dateSortie" (dateChange)="customUpdateField('sortieanimal', 'idSortie', this.adoption.id, 'dateSortie', this.sortieAdoption)">
	
					  </div>
					</div>
					<div class="col-md-1 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateSortie"></mat-datepicker-toggle>
						<mat-datepicker #dateSortie></mat-datepicker>
					</div>
	
	
					<div class="col-md-4">
					  <div class="form-group">
						<label>Numéro de contrat</label>
						<input type="text" name="numContrat" class="form-control" [(ngModel)]="adoption.numContrat" (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'numContrat', this.adoption)">
					  </div>
					</div>
	
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
						<label>Adoptant</label>
	
							<input type="text"
									class="form-control"
								   name="adoptant"
								   [formControl]="clientsControl"
								   [matAutocomplete]="auto7">
	
							<mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieanimal', 'idSortie', this.adoption.id, 'Idclient', this.clientsControl.value.split('(')[1].split(')')[0])">
							  <mat-option *ngFor="let option of filteredClientsOptions | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
				</div>
	
				<div class="row">
					<div class="col-md-4">
					  <div class="form-group">
						<label>Suivi urgent nécessaire</label>
						<input type="checkbox" name="suiviUrgent" class="form-control" [(ngModel)]="adoption.suiviUrgent" (change)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'suiviUrgent', this.adoption)">
					  </div>
					</div>
	
	
	
						<div class="col-md-3">
						  <div class="form-group">
							<label>Date suivi urgent</label>
							<input type="text" name="dateSuiviUrgent" class="form-control" matInput [matDatepicker]="dateSuiviUrgent" [(ngModel)]="adoption.dateSuiviUrgent" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateSuiviUrgent', this.adoption)">
	
						  </div>
						</div>
						<div class="col-md-1 pl-1">
						<label class="datePickerIconLabel">_____</label>
						<mat-datepicker-toggle matSuffix [for]="dateSuiviUrgent"></mat-datepicker-toggle>
							<mat-datepicker #dateSuiviUrgent></mat-datepicker>
						</div>
	
				</div>
	
	
				<div class="row">
	
				<div class="col-md-4">
	
				<h6 class="card-title">Détails appel</h6>
	
	
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date d'envoi du SMS</label>
						<input type="text" name="dateEnvoiSMS" class="form-control" matInput [matDatepicker]="dateEnvoiSMS" [(ngModel)]="adoption.dateEnvoiSMS" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiSMS', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateEnvoiSMS"></mat-datepicker-toggle>
						<mat-datepicker #dateEnvoiSMS></mat-datepicker>
					</div>
				</div>
	
	
				<hr class="rounded">
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date d'envoi du dossier d'appel</label>
						<input type="text" name="dateEnvoiDossier" class="form-control" matInput [matDatepicker]="dateEnvoiDossier" [(ngModel)]="adoption.dateEnvoiDossier" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiDossier', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateEnvoiDossier"></mat-datepicker-toggle>
						<mat-datepicker #dateEnvoiDossier></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
						<label>Bénévol en charge de l'appel</label>
	
							<input type="text"
									class="form-control"
								   name="benevoleAppel"
								   [formControl]="benevolesControlAppel"
								   [matAutocomplete]="auto4">
	
							<mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleAppel', this.benevolesControlAppel.value)">
							  <mat-option *ngFor="let option of filteredBenevolesOptionsAppel | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date d'appel</label>
						<input type="text" name="dateAppel" class="form-control" matInput [matDatepicker]="dateAppel" [(ngModel)]="adoption.dateAppel" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateAppel', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateAppel"></mat-datepicker-toggle>
						<mat-datepicker #dateAppel></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
						<label>Avis appel</label>
	
							<input type="text"
									class="form-control"
								   name="avisAppel"
								   [formControl]="avisControl1"
								   [matAutocomplete]="auto14">
	
							<mat-autocomplete autoActiveFirstOption #auto14="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'avisAppel', this.avisControl1.value)">
							  <mat-option *ngFor="let option of filteredAvisOptions1 | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
	
	
			  <div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Commentaire appel</label>
					<textarea class="form-control textarea" [(ngModel)]="adoption.commentaireAppel" name="commentaireAppel" (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'commentaireAppel', this.adoption)"></textarea>
				  </div>
				</div>
			  </div>
	
	
	
			  </div>
	
	
			  <div class="col-md-4">
	
				<h6 class="card-title">Détails inspection</h6>
	
				<div class="row"><label>Date d'envoi dossier inspection</label></div>
				<div class="row">
	
					<div class="col-md-9">
					  <div class="form-group">
	
						<input type="text" name="dateEnvoiDossierInsp" class="form-control" matInput [matDatepicker]="dateEnvoiDossierInsp" [(ngModel)]="adoption.dateEnvoiDossierInsp" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiDossierInsp', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel"></label>
					<mat-datepicker-toggle matSuffix [for]="dateEnvoiDossierInsp"></mat-datepicker-toggle>
						<mat-datepicker #dateEnvoiDossierInsp></mat-datepicker>
					</div>
				</div>
	
				<hr class="rounded">
	
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
						<label>Bénévol pour l'inspection</label>
	
							<input type="text"
									class="form-control"
								   name="benevoleInsp"
								   [formControl]="benevolesControlInsp"
								   [matAutocomplete]="auto5">
	
							<mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleInsp', this.benevolesControlInsp.value)">
							  <mat-option *ngFor="let option of filteredBenevolesOptionsInsp | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
	
	
				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date de l'inspection</label>
						<input type="text" name="dateInspection" class="form-control" matInput [matDatepicker]="dateInspection" [(ngModel)]="adoption.dateInspection" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateInspection', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateInspection"></mat-datepicker-toggle>
						<mat-datepicker #dateInspection></mat-datepicker>
					</div>
				</div>
	
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
						<label>Avis inspection</label>
	
							<input type="text"
									class="form-control"
								   name="avisAppel"
								   [formControl]="avisControl2"
								   [matAutocomplete]="auto15">
	
							<mat-autocomplete autoActiveFirstOption #auto15="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'avisInspection', this.avisControl2.value)">
							  <mat-option *ngFor="let option of filteredAvisOptions2 | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
	
	
			  <div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Commentaire inspection</label>
					<textarea class="form-control textarea" [(ngModel)]="adoption.commentaireInspection" name="commentaireInspection"  (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'commentaireInspection', this.adoption)"></textarea>
				  </div>
				</div>
			  </div>
	
	
	
			  </div>
	
	
	
	
	
	
				<div class="col-md-4">
	
				<h6 class="card-title">Détails 2de inspection</h6>
	
				<div class="row">
						<div class="col-md-12">
						  <div class="form-group">
							<label>Seconde inspection nécessaire
	
							</label>
							<input type="checkbox" name="secondeVisite" class="form-control" [(ngModel)]="adoption.secondeVisite"  (change)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'secondeVisite', this.adoption)">
						  </div>
						</div>
					</div>
	
				<br />
	
	
				<div class="row" *ngIf="adoption.secondeVisite">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date d'envoi dossier 2de inspection</label>
						<input type="text" name="dateEnvoiDossierInsp2" class="form-control" matInput [matDatepicker]="dateEnvoiDossierInsp2" [(ngModel)]="adoption.dateEnvoiDossierInsp2" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiDossierInsp2', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">______________ _____________</label>
					<mat-datepicker-toggle matSuffix [for]="dateEnvoiDossierInsp2"></mat-datepicker-toggle>
						<mat-datepicker #dateEnvoiDossierInsp2></mat-datepicker>
					</div>
				</div>
	
	
				<hr class="rounded" *ngIf="adoption.secondeVisite">
	
				<div class="row" *ngIf="adoption.secondeVisite">
					<div class="col-md-12">
						<div class="form-group">
						<label>Bénévol pour la 2de inspection</label>
	
							<input type="text"
									class="form-control"
								   name="benevoleInsp2"
								   [formControl]="benevolesControl2deInsp"
								   [matAutocomplete]="auto6">
	
							<mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleInsp2', this.benevolesControl2deInsp.value)">
							  <mat-option *ngFor="let option of filteredBenevolesOptions2deInsp | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
				<div class="row" *ngIf="adoption.secondeVisite">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date de la 2de inspection</label>
						<input type="text" name="dateInspection2" class="form-control" matInput [matDatepicker]="dateInspection2" [(ngModel)]="adoption.dateInspection2" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateInspection2', this.adoption)">
	
					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateInspection2"></mat-datepicker-toggle>
						<mat-datepicker #dateInspection2></mat-datepicker>
					</div>
				</div>
	
	
				<div class="row"  *ngIf="adoption.secondeVisite">
					<div class="col-md-12">
						<div class="form-group">
						<label>Avis 2de inspection</label>
	
							<input type="text"
									class="form-control"
								   name="avisInspection2"
								   [formControl]="avisControl3"
								   [matAutocomplete]="auto16">
	
							<mat-autocomplete autoActiveFirstOption #auto16="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'avisInspection2', this.avisControl3.value)">
							  <mat-option *ngFor="let option of filteredAvisOptions3 | async" [value]="option">
								{{option}}
							  </mat-option>
							</mat-autocomplete>
	
					</div>
					</div>
				</div>
	
	
	
			  <div class="row" *ngIf="adoption.secondeVisite">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Commentaire 2de inspection</label>
					<textarea class="form-control textarea" [(ngModel)]="adoption.commentaireInspection2" name="commentaireInspection2" (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'commentaireInspection2', this.adoption)"></textarea>
				  </div>
				</div>
			  </div>
	
	
	
			  </div>
	
	
	
	
				</div>
	
			  </form>
			  </div>
			 </div>
	
	
		</div>
	
	
	</div>
-->

<div class="row">

  <div (click)="closePopup()" class="croix-rouge">❌</div>

	<div class="col-md-6">

	<div class="card card-user"  *ngIf="animal">

	  <div class="card-footer">

			<h5 class="card-title">
        <input type="text" name="nomAnimal" matInput [(ngModel)]="animal.nom" (ngModelChange)="updateField('animaux', 'nom')" style="width: 50%;">

        - {{ animal.race }} {{ sexeStatut }} - {{ dateNaissanceStatut }}
      </h5>

	  </div>
    </div>
  </div>


  <div class="col-md-6">

	<div class="card card-user"  *ngIf="animal">

	  <div class="card-footer">
			<h5 class="card-title"> <span [style.color]="statusColor">{{statutAnimal}}</span></h5>

	  </div>
    </div>
  </div>

</div>


<div class="row">
<div class="col-md-4">

	<div class="card card-user"  *ngIf="animal">

	  <div class="card-footer">
		<h5 class="card-title">Général</h5>
	  </div>

	  <div class="card-footer">

		<form>

		<div class="row">

			<div class="col-md-6">


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label>sexe</label>

						<select name="sexe" class="form-control"
							[(ngModel)]="animal!.sexe" required (change)="updateField('animaux', 'sexe');updateAnimalStatus()">

						  <option value="Femelle">Femelle</option>
						  <option value="Male">Male</option>

						</select>
					</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date adoptable</label>
					<input type="text" name="dateAdoptable" pattern=".*\S.*" required readonly class="form-control" matInput [matDatepicker]="dateAdoptable" [(ngModel)]="animal.dateAdoptable" (dateChange)="updateField('animaux', 'dateAdoptable')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateAdoptable"></mat-datepicker-toggle>
					<mat-datepicker #dateAdoptable ></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date entrée</label>
					<input type="text" name="dateDerniereEntree" pattern=".*\S.*" required readonly class="form-control" matInput [matDatepicker]="dateDerniereEntree" [(ngModel)]="animal.dateDerniereEntree"
          (dateChange)="updateField('animaux', 'dateDerniereEntree');updateAnimalStatus()">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDerniereEntree"></mat-datepicker-toggle>
					<mat-datepicker #dateDerniereEntree ></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date sortie</label>
					<input disabled type="text" name="dateDerniereSortie" pattern=".*\S.*" required readonly class="form-control" matInput [matDatepicker]="dateDerniereSortie" [(ngModel)]="animal.dateDerniereSortie">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDerniereSortie"></mat-datepicker-toggle>
					<mat-datepicker #dateDerniereSortie ></mat-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de naissance</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateNaissance" class="form-control" matInput [matDatepicker]="dateNaissance" [(ngModel)]="animal.dateNaissance"
					(dateChange)="updateField('animaux', 'dateNaissance');updateAnimalStatus()">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Race</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="race"

							   [formControl]="raceControl"
							   [matAutocomplete]="auto"
							   [(ngModel)]="animal.race"
							   (focusout)="updateField('animaux', 'race')"/>

						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="updateField('animaux', 'race')">
						  <mat-option *ngFor="let option of filteredRaceOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>


			<div class="form-group">
				<label>Age</label>

				<select name="filtreStatut" class="form-control"
					[(ngModel)]="animal.age" required (change)="updateField('animaux', 'age')">

				  <option value="Cud">Cud</option>
				  <option value="Adulte">Adulte</option>

				</select>
			  </div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Animal croisé</label>
					<input type="checkbox" name="croisement" pattern=".*\S.*" required class="form-control" [(ngModel)]="animal.croisement" (change)="updateField('animaux', 'croisement');updateAnimalStatus()">
				  </div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Détail robe (1)</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="robe1"
							   [formControl]="robe1Control"
							   [matAutocomplete]="auto2"
							   [(ngModel)]="animal.robe1"
							   (focusout)="updateField('animaux', 'robe1')">

						<mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="updateField('animaux', 'robe1')">
						  <mat-option *ngFor="let option of filteredRobe1Options | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Détail robe (2)</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="robe2"
							   [formControl]="robe2Control"
							   [matAutocomplete]="auto3"
							   [(ngModel)]="animal.robe2"
							   (focusout)="updateField('animaux', 'robe2')">

						<mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="updateField('animaux', 'robe2')">
						  <mat-option *ngFor="let option of filteredRobe2Options | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>



			</div>

			<div class="col-md-6">

			<div class="row">
				<div class="col-md-12">

					<div class="form-group">
						<label>Site</label>

						<select name="site" class="form-control"
							[(ngModel)]="animal.site" required (change)="updateField('animaux', 'site')">

						  	
							<option *ngFor="let option of siteOptions" [value]="option">{{option}}</option>

						</select>
					  </div>

				</div>
			</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label for="transfertIdentification">Transfert d'identification effectué</label>
					<input type="checkbox" name="transfertIdentification" class="form-control" [(ngModel)]="animal.transfertIdentification" (change)="updateField('animaux', 'transfertIdentification')">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Numéro d'identification</label>
					<input type="text" pattern=".*\S.*" required name="identificationAnimal" class="form-control" [(ngModel)]="animal.identificationAnimal" (focusout)="updateField('animaux', 'identificationAnimal')">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date d'identification</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateIdentification" class="form-control" matInput [matDatepicker]="dateIdentification" [(ngModel)]="animal.dateIdentification" (dateChange)="updateField('animaux', 'dateIdentification')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateIdentification"></mat-datepicker-toggle>
					<mat-datepicker #dateIdentification></mat-datepicker>
				</div>
			</div>



			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Numéro de passeport</label>
					<input type="text" pattern=".*\S.*" required name="numeroPasseport" class="form-control" [(ngModel)]="animal.numeroPasseport" (focusout)="updateField('animaux', 'numeroPasseport')" required pattern=".*\S.*">
				  </div>
				</div>
			</div>

			<hr class="rounded">

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire comportement</label>
				<textarea class="form-control textarea" [(ngModel)]="animal.commentaireComportement" name="commentaireComportement" (focusout)="updateField('animaux', 'commentaireComportement')"></textarea>
			  </div>
			</div>
		  </div>

		  <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Autre commentaire</label>
            <textarea class="form-control textarea" [(ngModel)]="animal.commentaireAutre" name="commentaireAutre" (focusout)="updateField('animaux', 'commentaireAutre')"></textarea>
          </div>
        </div>
		  </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>A prévoir</label>
            <textarea class="form-control textarea" [(ngModel)]="animal.commentaireAPrevoir" name="commentaireAPrevoir" (focusout)="updateField('animaux', 'commentaireAPrevoir')"></textarea>
          </div>
        </div>
		  </div>

		  </div>
		  </div>
		  </form>
	  </div>
  </div>
  </div>


  <!--


			================== FICHE SANTE ==================

	/-->


  <div class="col-md-4">

	<div class="card card-user"  style="background-color: #EBFFF8" *ngIf="animal">

	  <div class="card-footer">
		<h5 class="card-title">Santé</h5>
	  </div>

	  <div class="card-body">

		<form>

		<div class="row">
			<div class="col-md-6">

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Stérilisé</label>
					<input type="checkbox" name="sterilisation" class="form-control" [(ngModel)]="animal.sterilisation" (change)="updateField('animaux', 'sterilisation')">
				  </div>
				</div>
			</div>

			<div class="row"  *ngIf="animal.sterilisation && !animal.sterilisationDateIconnue">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de stérilisation</label>
					<input type="text" pattern=".*\S.*" required readonly name="sterilisationDate" class="form-control" matInput [matDatepicker]="sterilisationDate" [(ngModel)]="animal.sterilisationDate" (dateChange)="updateField('animaux', 'sterilisationDate')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="sterilisationDate"></mat-datepicker-toggle>
					<mat-datepicker #sterilisationDate></mat-datepicker>
				</div>
			</div>

			<div class="row" *ngIf="animal.sterilisation">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Date stérilisation inconnue</label>
					<input type="checkbox" name="sterilisationDateIconnue" class="form-control" [(ngModel)]="animal.sterilisationDateIconnue" (change)="updateField('animaux', 'sterilisationDateIconnue')">
				  </div>
				</div>
			</div>

			<hr class="rounded">


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date vaccination 1</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateVacc1" class="form-control" matInput [matDatepicker]="dateVacc1" [(ngModel)]="animal.dateVacc1" (dateChange)="updateField('animaux', 'dateVacc1');updateDateVacc('dateVacc1')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateVacc1"></mat-datepicker-toggle>
					<mat-datepicker #dateVacc1></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Rappel vaccination 1</label>
					<input type="text" pattern=".*\S.*" required readonly name="rappelVacc1" class="form-control" matInput [matDatepicker]="rappelVacc1" [(ngModel)]="animal.rappelVacc1" (dateChange)="updateField('animaux', 'rappelVacc1')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="rappelVacc1"></mat-datepicker-toggle>
					<mat-datepicker #rappelVacc1></mat-datepicker>
				</div>
			</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date vaccination 2</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateVacc2" class="form-control" matInput [matDatepicker]="dateVacc2" [(ngModel)]="animal.dateVacc2" (dateChange)="updateField('animaux', 'dateVacc2');updateDateVacc('dateVacc2')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateVacc2"></mat-datepicker-toggle>
					<mat-datepicker #dateVacc2></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Rappel vaccination 2</label>
					<input type="text" pattern=".*\S.*" required readonly name="rappelVacc2" class="form-control" matInput [matDatepicker]="rappelVacc2" [(ngModel)]="animal.rappelVacc2" (dateChange)="updateField('animaux', 'rappelVacc2')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="rappelVacc2"></mat-datepicker-toggle>
					<mat-datepicker #rappelVacc2></mat-datepicker>
				</div>
			</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date vaccination 3</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateVacc3" class="form-control" matInput [matDatepicker]="dateVacc3" [(ngModel)]="animal.dateVacc3" (dateChange)="updateField('animaux', 'dateVacc3');updateDateVacc('dateVacc3')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateVacc3"></mat-datepicker-toggle>
					<mat-datepicker #dateVacc3></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Rappel vaccination 3</label>
					<input type="text" pattern=".*\S.*" required readonly name="rappelVacc3" class="form-control" matInput [matDatepicker]="rappelVacc3" [(ngModel)]="animal.rappelVacc3" (dateChange)="updateField('animaux', 'rappelVacc3')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="rappelVacc3"></mat-datepicker-toggle>
					<mat-datepicker #rappelVacc3></mat-datepicker>
				</div>
			</div>

			<hr class="rounded">

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire santé</label>
				<textarea class="form-control textarea" [(ngModel)]="animal.commentaireSante" name="commentaireSante"  (focusout)="updateField('animaux', 'commentaireSante')"></textarea>
			  </div>
			</div>
		  </div>

		</div>
		<div class="col-md-6">


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date fiv</label>
					<input type="text" pattern=".*\S.*" required readonly name="datefiv" class="form-control" matInput [matDatepicker]="datefiv" [(ngModel)]="animal.dateFIV" (dateChange)="updateField('animaux', 'dateFIV')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="datefiv"></mat-datepicker-toggle>
					<mat-datepicker #datefiv></mat-datepicker>
				</div>
			</div>


			<div class="row">

				<div class="col-md-6">

					<div class="form-group">
						<label>fiv</label>

						<input type="checkbox" name="fiv" class="form-control" [(ngModel)]="animal.fiv" (change)="updateField('animaux', 'fiv')">


					</div>

				</div>

				<div class="col-md-6">

					<div class="form-group">
						<label>felv</label>

						<input type="checkbox" name="felv" class="form-control" [(ngModel)]="animal.felv" (change)="updateField('animaux', 'felv')">


					</div>

				</div>

			</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label>Type antipuce</label>

						<select name="typeAntipuce" class="form-control"
							[(ngModel)]="animal!.typeAntipuce" required (change)="updateField('animaux', 'typeAntipuce')">

							<option value="Autre">Autre</option>
							<option value="Advantage">Advantage</option>
							<option value="Advantix">Advantix</option>
							<option value="Advocate">Advocate</option>
							<option value="Bravecto">Bravecto</option>
							<option value="Broadline">Broadline</option>
							<option value="Comfortis">Comfortis</option>
							<option value="Credelio">Credelio</option>
							<option value="Effipro">Effipro</option>
							<option value="Evicto">Evicto</option>
							<option value="Frontline">Frontline</option>
							<option value="Selehold">Selehold</option>
							<option value="Stronghold">Stronghold</option>
							<option value="Stronghold +">Stronghold +</option>

						</select>
					</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date antipuce</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateAntipuce" class="form-control" matInput [matDatepicker]="dateAntipuce" [(ngModel)]="animal.dateAntipuce" (dateChange)="updateField('animaux', 'dateAntipuce');updateDateVacc('dateAntipuce')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateAntipuce"></mat-datepicker-toggle>
					<mat-datepicker #dateAntipuce></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Rappel antipuce</label>
					<input type="text" pattern=".*\S.*" required readonly name="rappelAntipuce" class="form-control" matInput [matDatepicker]="rappelAntipuce" [(ngModel)]="animal.rappelAntipuce" (dateChange)="updateFieldDate('animaux', 'rappelAntipuce')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="rappelAntipuce"></mat-datepicker-toggle>
					<mat-datepicker #rappelAntipuce></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Encodé dans le passeport</label>
					<input type="checkbox" name="antipucePasseport" class="form-control" [(ngModel)]="animal.antipucePasseport" (change)="updateField('animaux', 'antipucePasseport')">
				  </div>
				</div>
			</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label>Type vermifuge</label>

						<select name="typeVermifuge" class="form-control"
							[(ngModel)]="animal!.typeVermifuge" required (change)="updateField('animaux', 'typeVermifuge')">

							<option value="Autre">Autre</option>
							<option value="Broadline">Broadline</option>
							<option value="Caniquantel">Caniquantel</option>
							<option value="Catminth">Catminth</option>
							<option value="Dronthal">Dronthal</option>
							<option value="Milbemax">Milbemax</option>
							<option value="Panacur">Panacur</option>
							<option value="Procox">Procox</option>
							<option value="Profender">Profender</option>
							<option value="Selehold">Selehold</option>

						</select>
					</div>
				</div>
			</div>


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date vermifuge</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateVermifuge" class="form-control" matInput [matDatepicker]="dateVermifuge" [(ngModel)]="animal.dateVermifuge" (dateChange)="updateField('animaux', 'dateVermifuge');updateDateVacc('dateVermifuge')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateVermifuge"></mat-datepicker-toggle>
					<mat-datepicker #dateVermifuge></mat-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Rappel vermifuge</label>
					<input type="text" pattern=".*\S.*" required readonly name="rappelVermifuge" class="form-control" matInput [matDatepicker]="rappelVermifuge" [(ngModel)]="animal.rappelVermifuge" (dateChange)="updateFieldDate('animaux', 'rappelVermifuge')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="rappelVermifuge"></mat-datepicker-toggle>
					<mat-datepicker #rappelVermifuge></mat-datepicker>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Encodé dans le passeport</label>
					<input type="checkbox" name="vermifugePasseport" class="form-control" [(ngModel)]="animal.vermifugePasseport" (change)="updateField('animaux', 'vermifugePasseport')">
				  </div>
				</div>
			</div>

		  </div>
		  </div>
		  </form>
	  </div>
  </div>
  </div>


    <!--


			================== STATUS LOCALISATION ==================

	/-->


  <div class="col-md-4">

	<div class="card card-user"  style="background-color: #f2d1ff" *ngIf="animal">
		<div class="card-footer">
			<h5 class="card-title">Photo</h5>
		</div>
	
		<div class="card-body">
			<div class="form-group">
				<div style="display: flex; justify-content: space-between; align-items: center;">
					<form method="post" enctype="multipart/form-data" style="width: 350px;">
						<input id="upload" style="opacity: 100; position: relative;" type="file" name="img_file" class="form-control" accept=".jpg" title="Upload PHOTO" (change)="handleFileInput($event)"/>
					</form>	
				
					<button [color]="deleteConfirm" mat-raised-button (click)="onSubmit()">OK</button>
				</div>
				<div style="display: flex; align-items: center; width: 100%; justify-content: center;">
					<img [src]="imageToShow"
					alt="Place image title"
					*ngIf="!isImageLoading; else noImageFound" style="margin-top: 15px; max-height: 150px; border-radius: 25px;">
					<ng-template #noImageFound>
						<img src="" alt="Aucune image">
					</ng-template>	
				</div>
				
			</div>	
		</div>
		
	</div>

	<div class="card card-user"  style="background-color: #FFFFD1" *ngIf="animal">

	  <div class="card-footer">
		<h5 class="card-title">Statut</h5>
	  </div>

	  <div class="card-body">

		<form>

		<div class="row">
			<div class="col-md-6">


			<!-- disabled -->
			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Présent au refuge</label>
					<input [disabled]="!admin" type="checkbox" name="auRefuge" class="form-control" [(ngModel)]="animal.auRefuge" (change)="updateField('animaux', 'auRefuge');auRefuge();updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Ajouté sur site web</label>
					<input type="checkbox" name="ajouteSurSite" class="form-control" [(ngModel)]="animal.ajouteSurSite" (change)="updateField('animaux', 'ajouteSurSite');updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<!-- disabled -->
			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Remis au maître</label>
					<input disabled type="checkbox" name="remisMaitre" class="form-control" [(ngModel)]="animal.remisMaitre" (change)="updateField('animaux', 'remisMaitre');horsRefuge();updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Statut spécial</label>
					<input type="checkbox" name="statusSpecial" class="form-control" [(ngModel)]="animal.statusSpecial" (change)="updateField('animaux', 'statusSpecial');updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Confidentiel</label>
					<input type="checkbox" name="confidentiel" class="form-control" [(ngModel)]="animal.confidentiel" (change)="updateField('animaux', 'confidentiel');updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<!-- disabled -->
			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Adopté</label>
					<input disabled type="checkbox" name="adopte" class="form-control" [(ngModel)]="animal.adopte" (change)="updateField('animaux', 'adopte');horsRefuge();updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<!-- disabled -->
			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>En famille d'accueil</label>
					<input disabled type="checkbox" name="familleAccueil" class="form-control" [(ngModel)]="animal.familleAccueil"  (change)="updateField('animaux', 'familleAccueil');enFA();updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<!-- disabled -->
			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Volé</label>
					<input disabled type="checkbox" name="vole" class="form-control" [(ngModel)]="animal.vole" (change)="updateField('animaux', 'vole');horsRefuge();updateAnimalStatus()">
				  </div>
				</div>
			</div>


		</div>
		<div class="col-md-6">



			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label>Local</label>

						<select name="Local" class="form-control"
							[(ngModel)]="animal!.Local" required (change)="updateField('animaux', 'Local')">

							<option value="Parcours Jaune">Parcours Jaune</option>
							<option value="Parcours Rose">Parcours Rose</option>
							<option value="Parcours Orange">Parcours Orange</option>
							<option value="Parcours blanc 1">Parcours blanc 1</option>
							<option value="Parcours blanc 2">Parcours blanc 2</option>
							<option value="Parcours blanc 3">Parcours blanc 3</option>
							<option value="Parcours pension">Parcours pension</option>
							<option value="Parcours libre">Parcours libre</option>
							<option value="Infirmerie 1">Infirmerie 1</option>
							<option value="Infirmerie 2">Infirmerie 2</option>
							<option value="Infirmerie 3">Infirmerie 3</option>
							<option value="Infirmerie 4">Infirmerie 4</option>
							<option value="Soins">Soin</option>
							<option value="Quarantaine 1">Quarantaine 1</option>
							<option value="Quarantaine 2">Quarantaine 2</option>
							<option value="Quarantaine 3">Quarantaine 3</option>
							<option value="Quarantaine 4">Quarantaine 4</option>
							<option value="Quarantaine 5">Quarantaine 5</option>
							<option value="VT Chastre">VT Chastre</option>

						</select>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label>Localisation cage</label>

						<input type="text" name="localisationCage" class="form-control"
							[(ngModel)]="animal!.localisationCage" required (change)="updateField('animaux', 'localisationCage')">


					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label>Localisation ordre</label>

						<input type="text" name="localisationOrdre" class="form-control"
							[(ngModel)]="animal!.localisationOrdre" required (change)="updateField('animaux', 'localisationOrdre')">


					</div>
				</div>
			</div>

			<hr style="rounded"/>

		<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Réservé</label>
					<input type="checkbox" name="reservation" class="form-control" [(ngModel)]="animal.reservation" (change)="updateField('animaux', 'reservation');updateAnimalStatus()">
				  </div>
				</div>
			</div>

			<div class="row"  *ngIf="animal.reservation">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de réservation</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateReservation" class="form-control" matInput [matDatepicker]="dateReservation" [(ngModel)]="animal.dateReservation" (dateChange)="updateField('animaux', 'dateReservation')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateReservation"></mat-datepicker-toggle>
					<mat-datepicker #dateReservation></mat-datepicker>
				</div>
			</div>

			<div class="row"  *ngIf="animal.reservation">
				<div class="col-md-12">
					<div class="form-group">
					<label>Réservé par client</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="clientReservation"
							   [formControl]="resaClientsControl"
							   [matAutocomplete]="auto12"
							   [(ngModel)]="animal.clientReservation">

						<mat-autocomplete autoActiveFirstOption #auto12="matAutocomplete"
						(optionSelected)="updateField('animaux', 'clientReservation')">
						  <mat-option *ngFor="let option of filteredResaClientsOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>

			<div class="row"  *ngIf="animal.reservation">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire réservation</label>
				<textarea class="form-control textarea" [(ngModel)]="animal.commentaireReservation" name="commentaireReservation"  (focusout)="updateField('animaux', 'commentaireReservation')"></textarea>
			  </div>
			</div>
		  </div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
				  <div class="form-group">
					<label>Décédé</label>
					<input type="checkbox" name="dcd" class="form-control" [(ngModel)]="animal.dcd" (change)="updateField('animaux', 'dcd');horsRefuge();updateAnimalStatus()">
				  </div>
				</div>
			</div>


			<div class="row"  *ngIf="animal.dcd">
				<div class="col-md-12">
					<div class="form-group">
						<label>Lieu du décès</label>

						<select name="sexe" class="form-control"
							[(ngModel)]="animal.lieuDCD" required (change)="updateField('animaux', 'lieuDCD')">

						  <option value="Au refuge">Au refuge</option>
						  <option value="Hors refuge">Hors refuge</option>

						</select>
					</div>
				</div>
			</div>


			<div class="row"  *ngIf="animal.dcd">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date décès</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateDeces" class="form-control" matInput [matDatepicker]="dateDeces" [(ngModel)]="animal.dateDeces" (dateChange)="updateField('animaux', 'dateDeces')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDeces"></mat-datepicker-toggle>
					<mat-datepicker #dateDeces></mat-datepicker>
				</div>
			</div>

			<div class="row"  *ngIf="animal.dcd">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire décès</label>
				<textarea class="form-control textarea" [(ngModel)]="animal.commentaireDeces" name="commentaireDeces" (focusout)="updateField('animaux', 'commentaireDeces')"></textarea>
			  </div>
			</div>
			</div>
			</div>
		  </div>

		  </form>
		</div>
	</div>
	</div>

  	<div *ngIf="donneesDisplay" class="col-md-8">

		<div class="card card-user" *ngIf="animal">

			<div class="card-footer">
			  <h5 class="card-title">Autres</h5>
			</div>
	  
			<div class="card-body">
	  
			  <form>
				<div *ngFor="let donnee of donneesDisplay; index as i;">
					
					<div class="col-md-6">
						<div class="form-group" style="padding-left: 15px;">
							<label>{{decamelize(donnee.nom, " ")}}</label>

							<div *ngIf="getType(donnee.valeur) === 'string'" >
								<input type="text" class="form-control" [(ngModel)]="donnee.valeur" (focusout)="updateFieldDisplay('animaux', donnee.nom, donnee.valeur)" [ngModelOptions]="{standalone: true}">
							</div>

							<div *ngIf="getType(donnee.valeur) === 'boolean'" >			
								<input type="checkbox" [(ngModel)]="donnee.valeur" (change)="updateFieldDisplay('animaux', donnee.nom, donnee.valeur)" [ngModelOptions]="{standalone: true}">
							</div>
					
							<div *ngIf="getType(donnee.valeur) === 'Date'" style="display: flex;">
								<input type="text" class="form-control" matInput readonly [matDatepicker]="i" [(ngModel)]="donnee.valeur"
										(dateChange)="updateFieldDisplay('animaux', donnee.nom, donnee.valeur)" [ngModelOptions]="{standalone: true}">
								<label class="datePickerIconLabel">_____</label>
								<mat-datepicker-toggle matSuffix [for]="i" ></mat-datepicker-toggle>
								<mat-datepicker #i></mat-datepicker>
							</div>
						</div>
					</div>
				</div>

			  </form>
			</div>
		</div>
  	</div>


  <div class="col-md-6">


		<div class="card card-user"  *ngIf="entrees">
		  <div class="card-footer">
			<h5 class="card-title">Entrées</h5>
		  </div>
		  <div class="card-body">
			<ngx-datatable
				class="material"
				[rows]="entrees"
				[columns]="columnsEntrees"
  (activate)="onSelect($event)" [headerHeight]="50"  [footerHeight]="0" [scrollbarH]="true"
  [sorts]="[{prop: 'dateEntree', dir: 'desc'}]" [rowHeight]="40" [messages]="messages">
			</ngx-datatable>

		  </div>
		 </div>

	</div>

  <div class="col-md-6">


		<div class="card card-user"  *ngIf="sorties">
		  <div class="card-footer">
			<h5 class="card-title">Sorties</h5>
		  </div>
		  <div class="card-body">
			<ngx-datatable  class="material" [rows]="sorties" [columns]="columnsSorties"
  (activate)="onSelect($event)"  [headerHeight]="50" [rowHeight]="40" [footerHeight]="0" [scrollbarH]="true"
  [sorts]="[{prop: 'dateSortie', dir: 'desc'}]" [messages]="messages">
			</ngx-datatable>
		  </div>
		 </div>
  </div>


	<!-- ADOPTIONS -->



 <div class="col-md-12">


		<div class="card card-user"  *ngIf="adoption">



		  <div class="card-footer">
			<h5 class="card-title">
				Suivi
				<span style="color:white">___</span>

        <!--
				<button  mat-raised-button (click)="switchAdoption('prev')">
					<mat-icon aria-hidden="false" aria-label="entrée / sorties"  fontSet="material-icons-outlined">chevron_left</mat-icon>
				</button>
				<button  mat-raised-button (click)="switchAdoption('next')">
					<mat-icon aria-hidden="false" aria-label="entrée / sorties"  fontSet="material-icons-outlined">chevron_right</mat-icon>
				</button>
        -->
			</h5>
		  </div>



		  <div class="card-body">

			<div class="row mb-5">

				<div class="col-md-6">

					<div class="row">


					<div class="col-md-2">
					  <div class="form-group">
						<label>Changement de contrat</label>
						<input type="checkbox" disabled name="changementContrat" class="form-control" [(ngModel)]="adoption.changementContrat">
					  </div>
					</div>

						<div class="col-md-3">
						  <div class="form-group">
							<label>Date sortie</label>
							<input disabled type="text" pattern=".*\S.*" required name="dateSortie" class="form-control" [(ngModel)]="sortieAdoption!.dateSortie" (dateChange)="customUpdateField('sortieanimal', 'idSortie', this.adoption.id, 'dateSortie', this.sortieAdoption)">

						  </div>
						</div>


						<div class="col-md-3">
						  <div class="form-group">
							<label>Numéro de contrat</label>
							<input disabled type="text" pattern=".*\S.*" required name="numContrat" class="form-control" [(ngModel)]="adoption.numContrat" (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'numContrat', this.adoption)">
						  </div>
						</div>


						<div class="col-md-4">
							<div class="form-group">
							<label>Adoptant</label>

								<input disabled type="text" pattern=".*\S.*" required
										class="form-control"
									   name="adoptant" [(ngModel)]="adoptant">


						</div>
					</div>

					</div>
				</div>

				<div class="col-md-6">

					<div class="row">
					<div class="col-md-2">
					  <div class="form-group">
						<label>Suivi urgent nécessaire</label>
						<input type="checkbox" name="suiviUrgent" class="form-control" [(ngModel)]="adoption.suiviUrgent" (change)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'suiviUrgent', this.adoption)">
					  </div>
					</div>


					<div class="col-md-4" *ngIf="adoption.suiviUrgent">

					<div class="form-group">
							<label>Type suivi urgent</label>

							<select name="typeSuiviUrgent" class="form-control"
								[(ngModel)]="adoption!.typeSuiviUrgent" required (change)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'typeSuiviUrgent', this.adoption!.typeSuiviUrgent)">

							  <option value="Appel">Appel urgent nécessaire</option>
							  <option value="Visite">Visite urgente nécessaire</option>

							</select>
						</div>

					</div>


						<div class="col-md-4" *ngIf="adoption.suiviUrgent">
					<div class="row">
						<div class="col-md-9">
						  <div class="form-group">
							<label>Date suivi urgent</label>
							<input type="text" pattern=".*\S.*" required readonly name="dateSuiviUrgent" class="form-control" matInput [matDatepicker]="dateSuiviUrgent" [(ngModel)]="adoption.dateSuiviUrgent" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateSuiviUrgent', this.adoption)">

						  </div>
						</div>
						<div class="col-md-3 pl-1">
						<label class="datePickerIconLabel">_____</label>
						<mat-datepicker-toggle matSuffix [for]="dateSuiviUrgent"></mat-datepicker-toggle>
							<mat-datepicker #dateSuiviUrgent></mat-datepicker>
						</div>
						</div>
						</div>

				</div>




				</div>
			</div>




			<div class="row">

			<div class="col-md-4">

			<h6 class="card-title">Détails appel</h6>


		<div class="row">
			<div class="col-md-6">




			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date d'envoi du SMS</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateEnvoiSMS" class="form-control" matInput [matDatepicker]="dateEnvoiSMS" [(ngModel)]="adoption.dateEnvoiSMS" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiSMS', this.adoption)">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateEnvoiSMS"></mat-datepicker-toggle>
					<mat-datepicker #dateEnvoiSMS></mat-datepicker>
				</div>
			</div>


			<hr class="rounded">


			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date d'envoi du dossier d'appel</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateEnvoiDossier" class="form-control" matInput [matDatepicker]="dateEnvoiDossier" [(ngModel)]="adoption.dateEnvoiDossier" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiDossier', this.adoption)">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateEnvoiDossier"></mat-datepicker-toggle>
					<mat-datepicker #dateEnvoiDossier></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Bénévole en charge de l'appel</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="benevoleAppel"
							   [(ngModel)]="adoption.benevoleAppel"
							   [formControl]="benevolesControlAppel"
							   [matAutocomplete]="auto4"
							   (focusout)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleAppel', this.benevolesControlAppel.value)">

						<mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleAppel', this.benevolesControlAppel.value)">
						  <mat-option *ngFor="let option of filteredBenevolesOptionsAppel | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>


		</div>
		<div class="col-md-6">

			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date d'appel</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateAppel" class="form-control" matInput [matDatepicker]="dateAppel" [(ngModel)]="adoption.dateAppel" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateAppel', this.adoption)">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateAppel"></mat-datepicker-toggle>
					<mat-datepicker #dateAppel></mat-datepicker>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12">

				<div class="form-group">
						<label>Avis appel</label>

						<select name="avisAppel" class="form-control"
							[(ngModel)]="adoption!.avisAppel" required (change)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'avisAppel', this.adoption!.avisAppel)">

						  <option value="Bon">Bon</option>
						  <option value="Moyen">Moyen</option>
						  <option value="Moyen">Mauvais</option>

						</select>
					</div>

				</div>
			</div>


			<div class="row">
				<div class="col-md-12">

				<div class="form-group">
						<label>2e appel nécessaire</label>

						<input type="checkbox" name="deuxiemeAppelNecessaire" class="form-control"
							[(ngModel)]="adoption!.deuxiemeAppelNecessaire" required (change)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'deuxiemeAppelNecessaire', this.adoption!.deuxiemeAppelNecessaire)" />
					</div>

				</div>
			</div>



		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire appel</label>
				<textarea class="form-control textarea" [(ngModel)]="adoption.commentaireAppel" name="commentaireAppel" (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'commentaireAppel', this.adoption)"></textarea>
			  </div>
			</div>
		  </div>



		  </div>
	  </div>
	</div>

		  <div class="col-md-4" style="border: #e3e5e8;border-style:none solid none solid">

			<h6 class="card-title">Détails inspection</h6>

			<div class="row">
				<div class="col-md-6">

				<label>Date d'envoi dossier inspection</label>
				<div class="row">

					<div class="col-md-9">
					  <div class="form-group">

						<input type="text" pattern=".*\S.*" required readonly name="dateEnvoiDossierInsp" class="form-control" matInput [matDatepicker]="dateEnvoiDossierInsp" [(ngModel)]="adoption.dateEnvoiDossierInsp" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiDossierInsp', this.adoption)">

					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel"></label>
					<mat-datepicker-toggle matSuffix [for]="dateEnvoiDossierInsp"></mat-datepicker-toggle>
						<mat-datepicker #dateEnvoiDossierInsp></mat-datepicker>
					</div>
				</div>

			<hr class="rounded">

			<div class="row">
				<div class="col-md-12">
					<div class="form-group">
					<label>Bénévole pour l'inspection</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="benevoleInsp"
							   [(ngModel)]="adoption.benevoleInsp"
							   [formControl]="benevolesControlInsp"
							   [matAutocomplete]="auto5"
							   (focusout)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleInsp', this.benevolesControlInsp.value)">

						<mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleInsp', this.benevolesControlInsp.value)">
						  <mat-option *ngFor="let option of filteredBenevolesOptionsInsp | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>



			<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de l'inspection</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateInspection" class="form-control" matInput [matDatepicker]="dateInspection" [(ngModel)]="adoption.dateInspection" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateInspection', this.adoption)">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateInspection"></mat-datepicker-toggle>
					<mat-datepicker #dateInspection></mat-datepicker>
				</div>
			</div>


		</div>
		<div class="col-md-6">

			<div class="row">
				<div class="col-md-12">

				<div class="form-group">
						<label>Avis inspection</label>

						<select name="avisInspection" class="form-control"
							[(ngModel)]="adoption!.avisInspection" required (change)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'avisInspection', this.adoption!.avisInspection)">

						  <option value="Bon">Bon</option>
						  <option value="Moyen">Moyen</option>
						  <option value="Moyen">Mauvais</option>

						</select>
					</div>



				</div>
			</div>



		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire inspection</label>
				<textarea class="form-control textarea" [(ngModel)]="adoption.commentaireInspection" name="commentaireInspection"  (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'commentaireInspection', this.adoption)"></textarea>
			  </div>
			</div>
		  </div>

		  </div>
		  </div>
		  </div>






		<div class="col-md-4">

		<h6 class="card-title">Détails 2de inspection</h6>

		<div class="row">
			<div class="col-md-6">

			<div class="row">
					<div class="col-md-12">
					  <div class="form-group">
						<label>Seconde inspection nécessaire

						</label>
						<input type="checkbox" name="secondeVisite" class="form-control" [(ngModel)]="adoption.secondeVisite"  (change)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'secondeVisite', this.adoption)">
					  </div>
					</div>
				</div>

			<br />


			<div class="row" *ngIf="adoption.secondeVisite">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date d'envoi dossier 2de inspection</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateEnvoiDossierInsp2" class="form-control" matInput [matDatepicker]="dateEnvoiDossierInsp2" [(ngModel)]="adoption.dateEnvoiDossierInsp2" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateEnvoiDossierInsp2', this.adoption)">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">______________ _____________</label>
				<mat-datepicker-toggle matSuffix [for]="dateEnvoiDossierInsp2"></mat-datepicker-toggle>
					<mat-datepicker #dateEnvoiDossierInsp2></mat-datepicker>
				</div>
			</div>


			<hr class="rounded" *ngIf="adoption.secondeVisite">

			<div class="row" *ngIf="adoption.secondeVisite">
				<div class="col-md-12">
					<div class="form-group">
					<label>Bénévole pour la 2de inspection</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="benevoleInsp2"
							   [(ngModel)]="adoption.benevoleInsp2"
							   [formControl]="benevolesControl2deInsp"
							   [matAutocomplete]="auto6">

						<mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete" (optionSelected)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'benevoleInsp2', this.benevolesControl2deInsp.value)">
						  <mat-option *ngFor="let option of filteredBenevolesOptions2deInsp | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
			</div>




		</div>
		<div class="col-md-6">

			<div class="row" *ngIf="adoption.secondeVisite">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de la 2de inspection</label>
					<input type="text" pattern=".*\S.*" required readonly name="dateInspection2" class="form-control" matInput [matDatepicker]="dateInspection2" [(ngModel)]="adoption.dateInspection2" (dateChange)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'dateInspection2', this.adoption)">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateInspection2"></mat-datepicker-toggle>
					<mat-datepicker #dateInspection2></mat-datepicker>
				</div>
			</div>


			<div class="row"  *ngIf="adoption.secondeVisite">
				<div class="col-md-12">

				<div class="form-group">
						<label>Avis 2de inspection</label>

						<select name="avisInspection2" class="form-control"
							[(ngModel)]="adoption!.avisInspection2" required (change)="customUpdateFieldValue('sortieadoption', 'id', this.adoption.id, 'avisInspection2', this.adoption!.avisInspection2)">

						  <option value="Bon">Bon</option>
						  <option value="Moyen">Moyen</option>
						  <option value="Moyen">Mauvais</option>

						</select>
					</div>



				</div>
			</div>



		  <div class="row" *ngIf="adoption.secondeVisite">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire 2de inspection</label>
				<textarea class="form-control textarea" [(ngModel)]="adoption.commentaireInspection2" name="commentaireInspection2" (focusout)="customUpdateField('sortieadoption', 'id', this.adoption.id, 'commentaireInspection2', this.adoption)"></textarea>
			  </div>
			</div>
		  </div>



		  </div>
		  </div>




			</div>


		  </div>
		 </div>


	</div>




	<div class="row">


		<div class="col-md-12">



		  	<div class="card-footer">

  				<mat-dialog-actions>
				    <button [color]="deleteConfirm" mat-raised-button (click)="delete('animaux', 'id', animal!.id);delete('animaux', 'id', animal!.id);">Supprimer</button>
            <button [color]="deleteConfirm" mat-raised-button (click)="closePopup()">Valider</button>
				</mat-dialog-actions>


			  </div>



		</div>

	</div>


</div>
