import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AnimalComponent } from './animal/animal.component';
import { ClientsComponent } from './clients/clients.component';
import { DonsComponent } from './dons/dons.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { DonDetailsComponent } from './don-details/don-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NouvelleEntreeComponent } from './nouvelle-entree/nouvelle-entree.component';
import { NouvelleSortieComponent } from './nouvelle-sortie/nouvelle-sortie.component';
import { NouveauDonComponent } from './nouveau-don/nouveau-don.component';
import { NouveauClientComponent } from './nouveau-client/nouveau-client.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { LoginComponent } from './login/login.component';
import { LoggingComponent } from './logging/logging.component';
import { RapportsComponent } from './rapports/rapports.component';
import { RechercherComponent } from './rechercher/rechercher.component';
import { PersonnalisationComponent } from './personnalisation/personnalisation.component';
import { NouveauUserComponent } from './nouveau-user/nouveau-user.component';

const routes: Routes = [
  {path: '', redirectTo: 'rechercher', pathMatch: 'full'},
  {path: 'animaux/:id', component: AnimalComponent, data: {page: 'animals', title: 'Gestion des animaux'}},
  {path: 'clients', component: ClientsComponent, data: {page: 'clients', title: 'Gestion des cliens'}},
  {path: 'details-client/:id', component: ClientDetailsComponent, data: {page: 'clients', title: 'Gestion des clients - Détails'}},
  {path: 'dons', component: DonsComponent, data: {page: 'dons', title: 'Gestion des dons'}},
  {path: 'details-don/:id', component: DonDetailsComponent, data: {page: 'dons', title: 'Gestion des dons'}},
  {path: 'dashboard', component: DashboardComponent, data: {page: 'dashboard', title: "Accueil et vue d'ensemble"}},
  {path: 'nouvelle-entree', component: NouvelleEntreeComponent, data: {page: 'clients', title: "Nouvelle entrée"}},
  {path: 'nouvelle-sortie', component: NouvelleSortieComponent, data: {page: 'clients', title: "Nouvelle sortie"}},
  {path: 'nouveau-don', component: NouveauDonComponent, data: {page: 'dons', title: "Gestion des dons"}},
  {path: 'nouveau-client', component: NouveauClientComponent, data: {page: 'nouveauClient', title: "Nouveau client"}},
  {path: 'nouveau-user', component: NouveauUserComponent, data: {page: 'nouveauUser', title: "Nouveau user"}},
  {path: 'admin', component: AdminPanelComponent, data: {page: 'admin', title: "Gestion des accès"}},
  {path: 'connexion', component: LoginComponent, data: {page: 'login', title: "Connexion"}},
  {path: 'logging', component: LoggingComponent, data: {page: 'logging', title: "Liste des activités par les ayants-accès"}},
  {path: 'rapports', component: RapportsComponent, data: {page: 'rapports', title: "Générations des rapports"}},
  {path: 'personnalisation', component: PersonnalisationComponent, data: {page: 'personnalisation', title: "Personnalisation de l'interface"}},
  {path: 'rechercher', component: RechercherComponent, data: {page: 'rechercher', title: "Rechercher parmi les animaux"}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
