import { Component, OnInit } from '@angular/core';

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { ApiService } from '../api.service';

@Component({
  selector: 'app-rapports',
  templateUrl: './rapports.component.html',
  styleUrls: ['./rapports.component.css']
})
export class RapportsComponent implements OnInit {

	type: number = 1;
	animals: string = "tous";
	firstDay: string = "";
	today2: string = "";

	visitesRealisees: boolean = false;
	dossierEnvoye: boolean = false;

	benevolesOptions: string[] = [];
	benevolesControl = new FormControl();
    filteredBenevolesOptions?: Observable<string[]>;

  constructor(
	private apiService: ApiService
	) { }

  ngOnInit(): void {

	  this.firstDay = new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0];
	  this.today2 = new Date().toISOString().split('T')[0];

	  // Bénévoles
	this.apiService.getBenevoles()
		.subscribe(options => {

			options.forEach( (option) => {
					this.benevolesOptions!.push(option.nom+', '+option.prenom)
			  });
		})

	this.filteredBenevolesOptions = this.benevolesControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBenevole(value))
    );

  }

  private _filterBenevole(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.benevolesOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
  }

  genererRapport(): void {


	if (this.type == 3) {

	  var url: string = encodeURI(this.apiService.PHP_API_SERVER_PARAMS+"rapport="+this.type+"&date1="+this.firstDay+"&date2="+this.today2+"&animals="+this.animals+"&auth="+localStorage.getItem('auth')+"&ref="+localStorage.getItem('refuge'));

	} else if (this.type == 4) {

		var url: string = encodeURI(this.apiService.PHP_API_SERVER_PARAMS
				+"rapport="+this.type
				+"&date1="+this.firstDay
				+"&date2="+this.today2
				+"&animals="+this.animals
				+"&auth="+localStorage.getItem('auth')
				+"&ref="+localStorage.getItem('refuge'));

	} else if (this.type == 5) {


		var params = "rapport="+this.type+"&date1="+this.firstDay+"&date2="+this.today2+"&real="+this.visitesRealisees+"&doss="+this.dossierEnvoye;

		if (this.benevolesControl.value) {

			params +=  "&benevol=" + this.benevolesControl.value;

		}

		var url: string = encodeURI(this.apiService.PHP_API_SERVER_PARAMS+params+"&auth="+localStorage.getItem('auth')+"&ref="+localStorage.getItem('refuge'));

	} else {

		var url: string = encodeURI(this.apiService.PHP_API_SERVER_PARAMS
				+"rapport="+this.type
				+"&date1="+this.firstDay
				+"&date2="+this.today2
				+"&animals="+this.animals
				+"&auth="+localStorage.getItem('auth')
				+"&ref="+localStorage.getItem('refuge'));

	}

	window.open(url, "_blank");

  }

}
