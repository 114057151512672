<div class="row">

<div class="col-md-6">
	<div class="card card-user">
	  <div class="card-header">
		<h5 class="card-title">Nouveau don</h5>
	  </div>
	  <div class="card-body">
			
			
		<div class="col-md-12">
		  <form>
		  
		  <div class="row">
		  
		  <div class="col-md-6">
			
			  <div class="form-group" >
					<label [class.red]="nonNom">Client donateur</label>
					
						<input type="text"
							class="form-control"
							   name="adoptant"
							   [formControl]="clientsControl"
							   [matAutocomplete]="auto7">
							   
						<mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
						  <mat-option *ngFor="let option of filteredClientsOptions | async" (onSelectionChange)="setClient(option)" [value]="option.nom + ', ' + option.prenom">
							{{option.nom + ', ' + option.prenom}}
						  </mat-option>
						</mat-autocomplete>
				  
				</div>		  
		  </div>		  
		
		<div class="col-md-6">

			  <div class="form-group">
				<label [class.red]="nonPay">Paiement</label>
				
				<select name="filtresexe" class="form-control" 
					[(ngModel)]="don.typeDon" (change)="setPay()">
					
				  <option value="Banque">Banque</option>
				  <option value="Accueil cash">Accueil cash</option>
				  <option value="Accueil BC">Accueil BC</option>
				  <option value="Paypal">Paypal</option>
				  <option value="Facebook">Facebook</option>
				  <option value="Autre">Autre</option>
				</select>

				<div *ngIf="don.typeDon === 'Autre'">
					<input type="text" [(ngModel)]="autreType" [ngModelOptions]="{standalone: true}">
				</div>
			  </div>
			  </div>
			  
		  </div>
			
			<div class="row">
			
				<div class="col-md-6 pr-1">
				
				  <div class="form-group">
					<label [class.red]="nonMon">Montant (€)</label>
					<input type="number" name="montantDon" class="form-control" (keyup)="setMon()" [(ngModel)]="don.montantDon" >
				  </div>
				</div>

				
				<div class="col-md-4">		
				  <div class="form-group">
					<label>Date du don</label>
					<input type="text" name="dateNaissance" class="form-control" matInput readonly [matDatepicker]="dateNaissance" [(ngModel)]="don.dateDon">
					
				  </div>		  
				</div>
				
				<div class="col-md-2 pl-1">	
				<label class="datePickerIconLabel">_____ ____ ____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>
		</div>
		  
		  
		  <div class="row">
			
				<div class="col-md-6 pr-1">
				
				<!-- <div class="form-group"> -->
					<!-- <label>Les informations sont correctes</label> -->
					<!-- <input type="checkbox" name="croisement" class="form-control" [(ngModel)]="confirmed" > -->
				  <!-- </div> -->
				
			</div>
			
				<div class="col-md-6 ">
			<div class="update pl-1">
			  <button type="submit" class="btn btn-primary btn-round" 
			  (click)="ajouterDon()"
			  [disabled]="!confirmed">Ajouter don</button>
			</div>
		  </div>
		  </div>
		</form>

			</div>
			
		</div>
		</div>
		
		