<!--
	<div class="row">

<div class="col-md-10">
	<div class="card card-user">

	  <div class="card-body">

<div>
  <ngx-datatable  class="material" [rows]="animals" [columns]="columns"
  (activate)="onSelect($event)" [limit]="20" [rowHeight]="40" [limit]="15" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true" [sorts]="[{prop: 'id', dir: 'desc'}]" >

  </ngx-datatable>
</div>

</div>
</div>
</div>

<div class="col-md-2">
	<div class="card card-user">
	  <div class="card-body">
	  
		  <div class="card-header">
			<h5 class="card-title">Filtrer par</h5>
		  </div>
		  
		  
		  <form id="form-fitler">
			<div class="col-md-12">	
			
			  <div class="form-group">
				<label>Identification</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['identificationAnimal']" (keyup)="updateFilter()">
			  </div>
			
			
			  <div class="form-group">
				<label>N° de contrat</label>
				<input type="text" name="filtreContrat" class="form-control" [(ngModel)]="filtres['numContrat']" (keyup)="updateFilter()">
			  </div>
			  
			  
			  <div class="form-group">
				<label>Statut</label>
				
				<select name="filtreStatut" class="form-control" 
					[(ngModel)]="filtres['statutAnimal']"
					(change)="updateFilter()">
					
					<option value=""></option>
				  <option value="adopte">Adopté</option>
				  <option value="auRefuge">Au refuge</option>
				  <option value="familleAccueil">Famille d'accueil</option>
				  <option value="auRefuge/FA">Au refuge/FA</option>
				  <option value="DCD">Décédé</option>
				  <option value="Reservation">Réservé</option>
				  
				</select>
			  </div>

		  

			  <div class="form-group">
				<label>Nom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['nom']" (keyup)="updateFilter()">
			  </div>
						  
			  
				<div class="form-group">
					<label>Race</label>
					
						<input type="text"
								class="form-control"
							   name="race"
							   
							   [formControl]="raceControl"
							   [matAutocomplete]="auto"
							   [(ngModel)]="filtres['race']"/>

						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="updateFilter()">
						  <mat-option *ngFor="let option of filteredRaceOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>
				  
				</div>	
			
			  
			  
			  <div class="form-group">
				<label>sexe</label>
				
				<select name="filtresexe" class="form-control" 
					[(ngModel)]="filtres['sexe']"
					(change)="updateFilter()">
					
					<option value=""></option>					
				  <option value="Femelle">Femelle</option>
				  <option value="Male">Male</option>
				</select>
			  </div>
		  

			  <div class="form-group">
				<label>Naissance après</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateNaissance1']" (change)="updateFilter()">
			  </div>
		  

			  <div class="form-group">
				<label>Naissance avant</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateNaissance2']" (change)="updateFilter()">
			  </div>
			
			  
			  
			   <div class="row">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter()">Supprimer</button>
			</div>
			</div>
		  
		  
		  </div>
		</form>
	

</div>
</div>
</div>

</div>
-->

<div class="row">

<div class="col-md-12">
	<div class="card card-user">
	  <div class="card-body">

		  <form id="form-fitler">
			<div class="col-md-12" style="column-count: 9;">


			  <div class="form-group">
				<label>Identification</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['identificationAnimal']" (keyup)="updateFilter()">
			  </div>




			  <div class="form-group">
				<label>Statut</label>

				<select name="filtreStatut" class="form-control"
					[(ngModel)]="filtres['statutAnimal']"
					(change)="updateFilter()">

					<option value=""></option>
				  <option value="adopte">Adopté</option>
				  <option value="auRefuge">Au refuge</option>
				  <option value="familleAccueil">Famille d'accueil</option>
				  <option value="auRefuge">Au refuge</option>
				  <option value="dcd">Décédé</option>
				  <option value="remisMaitre">Remis Maître</option>
				  <option value="reservation">Réservé</option>
				  <option value="statusSpecial">Statut spécial</option>

				</select>
			  </div>



			  <div class="form-group">
				<label>Nom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['nomAnimal']" (keyup)="updateFilter()">
			  </div>


				<div class="form-group">
					<label>Race</label>

						<input type="text"
								class="form-control"
							   name="race"

							   [formControl]="raceControl"
							   [matAutocomplete]="auto"
							   [(ngModel)]="filtres['race']"
							   (keyup)="updateFilter()"/>

						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="updateFilter()">
						  <mat-option *ngFor="let option of filteredRaceOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>


			  <div class="form-group">
				<label>Sexe</label>

				<select name="filtresexe" class="form-control"
					[(ngModel)]="filtres['Sexe']"
					(change)="updateFilter()">

					<option value=""></option>
				  <option value="Femelle">Femelle</option>
				  <option value="Male">Male</option>
				</select>
			  </div>



			  <div class="form-group">
				<label>Age</label>

				<select name="filtreAge" class="form-control"
					[(ngModel)]="filtres['age']"
					(change)="updateFilter()">

					<option value=""></option>
				  <option value="Cud">Cud</option>
				  <option value="Adulte">Adulte</option>
				</select>
			  </div>



			  <div class="form-group">
				<label>Naissance après</label>
				<input type="date" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateNaissance1']" (change)="updateFilter()">
			  </div>


			  <div class="form-group">
				<label>Naissance avant</label>
				<input type="date" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateNaissance2']" (change)="updateFilter()">
			  </div>


			  <div class="form-group">
				<label>N° contrat adoption</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['numContratAdoption']" (keyup)="updateFilter()">
			  </div>



			  <div class="form-group">
				<label>N° cession</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['numeroCession']" (keyup)="updateFilter()">
			  </div>



			  <div class="form-group">
				<label>Localité in</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['localite']" (keyup)="updateFilter()">
			  </div>



			  <div class="form-group">
				<label>Localité out</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['VilleAdoptant']" (keyup)="updateFilter()">
			  </div>



			  <div class="form-group">
				<label>Entrée après</label>
				<input type="date" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateDerniereEntree1']" (change)="updateFilter()">
			  </div>



			  <div class="form-group">
				<label>Entrée avant</label>
				<input type="date" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateDerniereEntree2']" (change)="updateFilter()">
			  </div>



			  <div class="form-group">
				<label>Rappel dans _ jours</label>
				<input type="text" name="rappelDans" class="form-control" [(ngModel)]="filtres['rappelDans']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Stérilisé</label>

				<select name="filtresterilise" class="form-control"
					[(ngModel)]="filtres['sterilisation']"
					(change)="updateFilter()">

					<option value=""></option>
				  <option value="1">Stérilisé</option>
				  <option value="0">Non-stérilisé</option>
				</select>
			  </div>

			  <div class="form-group">
		  	<label>Local</label>

						<select name="Local" class="form-control" [(ngModel)]="filtres['Local']"
					(change)="updateFilter()">

							<option value=""></option>
							<option value="Parcours Jaune">Parcours Jaune</option>
							<option value="Parcours Rose">Parcours Rose</option>
							<option value="Parcours Orange">Parcours Orange</option>
							<option value="Parcours blanc 1">Parcours blanc 1</option>
							<option value="Parcours blanc 2">Parcours blanc 2</option>
							<option value="Parcours blanc 3">Parcours blanc 3</option>
							<option value="Parcours pension">Parcours pension</option>
							<option value="Parcours libre">Parcours libre</option>
							<option value="Infirmerie 1">Infirmerie 1</option>
							<option value="Infirmerie 2">Infirmerie 2</option>
							<option value="Infirmerie 3">Infirmerie 3</option>
							<option value="Infirmerie 4">Infirmerie 4</option>
							<option value="Soins">Soin</option>
							<option value="Quarantaine 1">Quarantaine 1</option>
							<option value="Quarantaine 2">Quarantaine 2</option>
							<option value="Quarantaine 3">Quarantaine 3</option>
							<option value="Quarantaine 4">Quarantaine 4</option>
							<option value="Quarantaine 5">Quarantaine 5</option>
							<option value="VT Chastre">VT Chastre</option>

						</select>

					</div>



			   <div class="row">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter()">Reset</button>
			</div>
			</div>


		  </div>
		</form>


</div>
</div>
</div>

<div class="col-md-12">
	<div class="card card-user">

	  <div class="card-body">

<div>
 <div [class.display]="!columnsOk">
	<ngx-datatable  class="material" [rows]="animals"
	(activate)="onSelect($event)" [sorts]="[{ prop: 'dateDerniereEntree', dir: 'asc' }]" [limit]="60"
	[rowHeight]="40" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">
		<ngx-datatable-column *ngFor="let col of columns;" [name]="col.name" [frozenLeft]="col.frozenLeft">
			<ng-template ngx-datatable-header-template>
				{{ col.name }}
			</ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				<span>
					{{ value | animauxPipe }}
				</span>
			</ng-template>
		</ngx-datatable-column>
	</ngx-datatable>
 </div>
  

  <div class="spinner" [class.display]="!loading">
    <mat-progress-spinner
        color="warm"
        mode="indeterminate">
    </mat-progress-spinner>
  </div>

  <div class="paginator">

    <div class="icons-page">
      <mat-icon style="cursor: pointer; font-size: xx-large;" class="icon-page" (click)="onPaginated(1)">first_page</mat-icon>
      <mat-icon style="cursor: pointer; font-size: xx-large;" class="icon-page" (click)="onPaginated(2)">keyboard_double_arrow_left</mat-icon>
      <mat-icon style="cursor: pointer; font-size: xx-large;" class="icon-page" (click)="onPaginated(3)">chevron_left</mat-icon>
    </div>

    <div>{{currentPage+1}} sur {{nbPages}}</div>

    <div class="icons-page">
      <mat-icon style="cursor: pointer; font-size: xx-large;" class="icon-page" (click)="onPaginated(4)">chevron_right</mat-icon>
      <mat-icon style="cursor: pointer; font-size: xx-large;" class="icon-page" (click)="onPaginated(5)">keyboard_double_arrow_right</mat-icon>
      <mat-icon style="cursor: pointer; font-size: xx-large;" class="icon-page" (click)="onPaginated(6)">last_page</mat-icon>
    </div>
  </div>
</div>

</div>
</div>
</div>

</div>

