import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Client } from '../client';
import { ApiService } from '../api.service';


// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ClientDetailsComponent } from '../client-details/client-details.component';

@Component({
  selector: 'app-nouveau-client',
  templateUrl: './nouveau-client.component.html',
  styleUrls: ['./nouveau-client.component.css']
})
export class NouveauClientComponent implements OnInit {

  client: Client = new Client();

  clients: Client[] = [];

  localitesOptions: string[] = [];
	localitesControl = new FormControl();
  filteredLocalitesOptions?: Observable<string[]>;

  constructor(
	private router: Router,
	private apiService: ApiService,
  public dialog: MatDialog,
  private _snackBar: MatSnackBar
	) { }

  ngOnInit(): void {

	  this.client.personne = "Physique";
	  this.client.benevole = "Non"

		this.getVilles();
    this.getClients();
		this.filteredLocalitesOptions = this.localitesControl.valueChanges.pipe(
	      startWith(''),
	      map(value => this._filterLocalites(value))
    );


  }

  private _filterLocalites(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.localitesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  getClients(): void {
    this.apiService.getClients().subscribe(x => {
      this.clients = x;
    })
  }

  creerNouveauClient(): void {
    console.log("nouveauclient");

	  this.client.nom = this.client.nom.toUpperCase()
	  this.client.prenom = this.client.prenom.toUpperCase()

	  if (this.client.vip) {
		  this.client.vipDate = new Date();
	  }

	  if (this.client.adresse) {
		  this.client.adresse = this.client.adresse.toUpperCase();
	  }

    this.client.refuge = +localStorage.getItem('refuge')!;

    if (!this.clients.includes(this.client)) {
      this.apiService.ajouterNouvelObjet(this.client, 'clients').subscribe(id => {
        console.log("return from put " + id);
        this._snackBar.open('Client créé', 'OK');
  
        const dialogPosition: DialogPosition = {
          left: '15vw'
        };
  
        const dialogRef = this.dialog.open(ClientDetailsComponent, {
          data: id,
          height: '80vh',
          width: '80vw',
          position: dialogPosition,
          disableClose: true,
          autoFocus: true
        });
  
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          this.getClients();
          this.router.navigateByUrl('/clients/');
        });
  
      });
    } else {
      window.alert("Ce client existe déjà");
    }

  }

  getVilles(): void {

		this.apiService.getLocalites()
				.subscribe(villes => {

					villes.forEach( (option) => {
						this.localitesOptions!.push(option.Ville)
					});

				})

  }

}
