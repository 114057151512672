import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { SelectionType } from '@swimlane/ngx-datatable';

import { ApiService } from '../api.service';

import { Client } from '../client';
import { Animal } from '../animal';
import { Chat } from '../chat';
import { Chien } from '../chien';
import { EntreeAnimal } from '../entreeanimal';
import { EntreePension } from '../entreepension';
import { EntreeAbandon } from '../entreeabandon';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ClientDetailsComponent } from '../client-details/client-details.component';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnimalDetailsComponent } from '../animal-details/animal-details.component';
import { AnimalType } from '../animalType';

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
  selector: 'app-nouvelle-entree',
  templateUrl: './nouvelle-entree.component.html',
  styleUrls: ['./nouvelle-entree.component.css']
})
export class NouvelleEntreeComponent implements OnInit {

  @ViewChild('tableClients') tableClients: any;
  @ViewChild('tableAnimaux') tableAnimaux: any;

	nouveauClient: boolean = false;
	clientExistant: boolean = false;
	clientEditerButton: boolean = false;
	clientEditer: boolean = false;

	nouvelAnimal: boolean = false;
	animalExistant: boolean = false;
  animalEditerButton: boolean = false;

	// création nouveau client/ animal
	clientId: number = 0;
	animalId: number = 0;

	// client existant
	client?: Client = new Client();
	clients: Client[] = [];
	fullData: Client[] = [];
	filtres: Dictionary<string> = {};

  localitesOptions: string[] = [];
	localitesControl = new FormControl();
  filteredLocalitesOptions?: Observable<string[]>;

	benevolesOptions: string[] = ['Non', 'Oui', 'Inactif'];
	benevolesControl = new FormControl();
  filteredBenevolesOptions?: Observable<string[]>;
	sexeOptionsClient: string[] = ['Homme', 'Femme'];
	sexeControlClient = new FormControl();
  filteredsexeOptionsClient?: Observable<string[]>;

	// animal existant
	animal?: Animal = new Animal();
	typeOptions: string[] = ['Chien', 'Chat'];
	typeControl = new FormControl();
  filteredTypeOptions?: Observable<string[]>;
	animaux: Animal[] = [];
	fullDataAnimaux: Animal[] = [];
	filtresAnimaux: Dictionary<string> = {};

	// nouvel animal
	typeControl2 = new FormControl();
  filteredTypeOptions2?: Observable<string[]>;
	sexeOptions: string[] = ['Male', 'Femelle'];
	sexeControl = new FormControl();
  filteredsexeOptions?: Observable<string[]>;

	// entrée
	entree: EntreeAnimal = new EntreeAnimal();
	pension: EntreePension = new EntreePension();
	abandon: EntreeAbandon = new EntreeAbandon();
	entreeOptions: string[] = ['Abandon', 'Pension', 'Retour', 'Saisie', 'Sauvage', 'Trouvé'];
	entreeControl = new FormControl();
  filteredEntreeOptions?: Observable<string[]>;

  types: AnimalType[] = [];

	// format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');

	columns = [
    { prop: 'nom', name: "Nom", width: 125 },
    { prop: 'prenom', name: "Prénom", width: 95  },
    { prop: 'dateNaissance', name: "Date naissance", width: 105 },
    { prop: 'adresse',  	name: 'Adresse' },
    { prop: 'idLocalite',  	name: 'CP', width: 55},
    { prop: 'tel1',	name: 'Tel 1', width: 125 },
    { prop: 'tel2',	name: 'Tel 2', width: 125 },
    { prop: 'email',	name: 'Email' },
    { prop: 'adoptant',  	name: 'Adopt.', pipe: this.booleanPipe(), width: 65 },
    { prop: 'benevole',  	name: 'Benev.', pipe: this.benevolePipe(), width: 65 },
    { prop: 'donateur',  	name: 'Donat.', pipe: this.booleanPipe(), width: 65 },
    { prop: 'surListeNoire',  	name: 'LN', pipe: this.booleanPipe(), width: 65 },
    { prop: 'testamentaire',  	name: 'Testa.', pipe: this.booleanPipe(), width: 65 },
    { prop: 'vip',  	name: 'VIP', pipe: this.booleanPipe(), width: 65 },
    { prop: 'niveauAcces',  	name: 'Accès', width: 100 },
    ];

	columnsAnimaux = [
			{ prop: 'dateDerniereSortie', name: "Date sortie", width: 120 , pipe: this.datePipe()},
			{ prop: 'nom', name: "Nom", width: 100  },
			{ prop: 'animalType', name: "Type", width: 55 , pipe: this.typePipe() },
			{ prop: 'reservation', name: "Réservé", width: 70, pipe: this.booleanPipe() },
			{ prop: 'auRefuge', name: "Au refuge", width: 75, pipe: this.booleanPipe() },
			{ prop: 'familleAccueil', name: "FA", width: 70, pipe: this.booleanPipe() },
			{ prop: 'adopte', name: "Adopté", width: 70, pipe: this.booleanPipe() },
			{ prop: 'statusSpecial', name: "Spécial", width: 70, pipe: this.booleanPipe() },
			{ prop: 'confidentiel', name: "Confidentiel", width: 70, pipe: this.booleanPipe() },
			{ prop: 'dcd', name: "Décédé", width: 70, pipe: this.booleanPipe() },
			//{ prop: 'numeroPasseport',  	name: 'Passeport', width: 150  },
			//{ prop: 'identificationAnimal',  	name: 'Identification', width: 150  }
			];


	selectionType: SelectionType = SelectionType.single;

  constructor(private apiService: ApiService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {

	  this.entree.dateEntree = new Date();
	  this.client!.benevole = "Non"
	  this.animal!.site = "Perwez"

	  this.resetFilter('client');
	  this.resetFilter('animal');

		this.getVilles();
		this.filteredLocalitesOptions = this.localitesControl.valueChanges.pipe(
	      startWith(''),
	      map(value => this._filterLocalites(value))
    );

    this.apiService.getRefugeAnimalTypes(+localStorage.getItem('refuge')!).subscribe(
        x => {
          this.types = x;
        }
    );


	this.filteredTypeOptions = this.typeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterType(value))
		);

	this.filteredTypeOptions2 = this.typeControl2.valueChanges.pipe(
      startWith(''),
      map(value => this._filterType(value))
		);

	this.filteredsexeOptions = this.sexeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filtersexe(value))
    );

	this.filteredEntreeOptions = this.entreeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterEntree(value))
    );

	this.filteredsexeOptionsClient = this.sexeControlClient.valueChanges.pipe(
      startWith(''),
      map(value => this._filtersexeClient(value))
    );

	this.filteredBenevolesOptions = this.benevolesControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBenevole(value))
    );
  }

  booleanPipe () {
	return {transform: (value: boolean) => (Boolean(Number(value)) == true) ? '✔️' : ''};
	}

  benevolePipe () {
    return {transform: (value: string) => (value == "Oui") ? '✔️' : ''};
  }

  censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  typePipe () {
    return {transform: (value: string) => {
      let rep = "autre";
      this.types.forEach(type => {
        if (value === type.animaltype.toString()) {
          rep = type.type;
        }
      });
      return rep;
    }};
  }

  private _filterLocalites(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.localitesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterEntree(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.entreeOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filtersexe(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.sexeOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filtersexeClient(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.sexeOptionsClient.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterBenevole(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.benevolesOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
  }

  updateField(table: string, field: string): void {

	  var value = (this.client as any)[field];
	  value = (field == 'adresse') ? value.toUpperCase() : value;

	  this.apiService.updateTable(table, 'id', this.client!.id, field, value)
		.subscribe(options => {});

  }

  editerClient(): void {

  	/*this.apiService.updateObjet(this.client, 'id', this.client!.id, 'clients').subscribe()*/

  }

  resetClient(): void {

		this.client = new Client()

  }

  creerNouveauClient(): void {

  	this.client!.nom = this.client!.nom.toUpperCase()
	  this.client!.prenom = this.client!.prenom.toUpperCase()

	  if (this.client!.vip) {
		  this.client!.vipDate = new Date();
	  }

	  if (this.client!.adresse) {
		  this.client!.adresse = this.client!.adresse.toUpperCase();
	  }

	  this.client!.pays = 'Belgique';

    this.client!.refuge = +localStorage.getItem('refuge')!;

	  this.apiService.ajouterNouvelObjet(this.client!, 'clients')
	  	.subscribe(idClient => {

			  this.clientId = idClient;

	  })

  }

  creerNouvelAnimal(): void {


  	this.animal!.nom = this.animal!.nom.toUpperCase();

    this.animal!.refuge = +localStorage.getItem('refuge')!;

	  this.apiService.ajouterNouvelObjet(this.animal!, 'animaux').subscribe(message => {

      
		  this.animalId = message;
      /*
		  if (this.animal!.animalType == 'Chien') {

			  var chien: Chien = new Chien();
			  chien.id = this.animalId;
			  chien.nomAnimal = this.animal!.nom;

			  this.apiService.ajouterNouvelObjet(chien, 'chiens').subscribe();

		  } else {

			  var chat: Chat = new Chat();
			  chat.id = this.animalId;
			  chat.nomAnimal = this.animal!.nom;

			  this.apiService.ajouterNouvelObjet(chat, 'chats').subscribe();

		  }
      */

	  });

  }

  creerNouvelEntree(): void {

	  this.entree.Idanimal = this.animalId;
	  this.entree.Idclient = this.clientId;
    this.entree.refuge = +localStorage.getItem('refuge')!;
	  console.log(this.entree)

	  this.apiService.ajouterNouvelObjet(this.entree, 'entreeanimal')
	  	.subscribe(idEntree => {

		  this.entree.id = idEntree;

		  if (this.entree.typeEntree == 'Abandon') {

			  this.abandon!.id = this.entree.id;
			  this.abandon.numeroCession = this.entree.numeroCession
			  this.apiService.ajouterNouvelObjet(this.abandon, 'entreeabandon').subscribe();

		  } else if (this.entree.typeEntree == 'Pension') {

			  this.pension!.id = this.entree.id;
			  this.apiService.ajouterNouvelObjet(this.pension, 'entreepension').subscribe();

		  }

		// Mise à jour statut animal
		var dateAdoptable = new Date(this.entree.dateEntree);
		if (this.entree.typeEntree == 'Trouvé') {

			dateAdoptable.setDate(dateAdoptable.getDate() + 10);

		}

		this.animal!.dateAdoptable = dateAdoptable;
		this.animal!.auRefuge = true;
		this.animal!.remisMaitre = false;
		this.animal!.familleAccueil = false;
		this.animal!.vole = false;
		this.animal!.adopte = false;
		this.animal!.dcd = false;
		this.animal!.reservation = false;
		this.animal!.dateDerniereEntree = this.entree.dateEntree;

		this.apiService.updateObjet(this.animal!, 'id', this.animalId, 'animaux').subscribe(message => {

				console.log(this.animal!);
				console.log("return from put " + message);

				this.router.navigate(['/animal/'+this.animal!.animalType]);

        const dialogPosition: DialogPosition = {
          left: '15vw'
        };

        const dialogRef = this.dialog.open(AnimalDetailsComponent, {
          data: this.animalId,
          height: '80vh',
          width: '80vw',
          position: dialogPosition,
          disableClose: true,
          autoFocus: true
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
        
			});

	  })

  }

  datePipe () {
	return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
	}

  private _filterType(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.typeOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  getClients(): void {
	this.apiService.getClients()
	  .subscribe(clients => {
		  this.fullData = clients;
		  this.clients = clients;
	  })
  }

  getAnimaux(): void {
	this.apiService.getAnimaux()
	  .subscribe(animaux => {

		  this.fullDataAnimaux = animaux;
		  this.animaux = animaux;
		  this.updateFilterAnimaux()

	  })
  }

  onSelect(event: any, type: string): void{

	if (event['type'] == 'click') {

		if (type == 'client') {

			this.clientId = event['row']['id'];
			this.client = <Client> event['row'];
			this.clientEditerButton = true


		} else if (type == 'animal') {

			this.animalId = event['row']['id'];
			this.animal!.animalType = event['row']['animalType'];
      this.animalEditerButton = true

		}

	 }

  }

  resetFilter(type: string): void {

	  if (type == 'client') {

    for (let key in this.filtres) {
      this.filtres[key] = "";
    }
	  this.clients = this.fullData;

	  } else if (type == 'animal') {

	  this.filtresAnimaux['animalType'] = '';
	  this.filtresAnimaux['nom'] = '';
	  this.filtresAnimaux['numeroPasseport'] = '';
	  this.filtresAnimaux['identificationAnimal'] = '';
	  this.animaux = this.fullDataAnimaux;

	  }


  }

  updateFilterClients() {

	/*var temp = this.fullData;

	  for (let key in this.filtres) {

		let value = this.filtres[key];

		if (value != '') {

			const val = (key == 'dateNaissance') ? value : value.toLowerCase();

			temp = temp.filter(function (d: Client) {

			  if ((d as any)[key] != undefined) {

				  if (key == 'dateNaissance') {

					  var dPipe = new DatePipe('fr-FR');
					  var valTab = dPipe.transform(val, 'dd/MM/yyyy')
					  var valObj = dPipe.transform((d as any)[key], 'dd/MM/yyyy')

					  console.log(valTab);
					  console.log(valObj);

						return valTab == valObj;

					} else {

						return (d as any)[key].toLowerCase().indexOf(val) !== -1 || !val;

				  }

			  }

			  return false;

			});

		}

	  }

    this.clients = temp;
    */

  var temp = this.fullData;

  for (let key in this.filtres) {

  let value = this.filtres[key];

  if (value != '') {

    const val = (key == 'dateNaissance' || key == 'statut') ? value : value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    temp = temp.filter(function (d: Client) {
      if (key == 'tel' && (d.tel1 || d.tel2)) {
        let tempTel1 = "";
        let tempTel2 = "";

        if (d.tel1) {
          tempTel1 = d.tel1.replace(/[&\/\\#,+()$~%.' ":*?<>{}]/g, '');
          tempTel1 = tempTel1.substring(0, value.length);
        }

        if (d.tel2) {
          tempTel2 = d.tel2.replace(/[&\/\\#,+()$~%.' ":*?<>{}]/g, '');
          tempTel2 = tempTel2.substring(0, value.length);
        }

        return (tempTel1 == value) || (tempTel2 == value);
      }

      if (key == 'statut' || (d as any)[key] != undefined) {

        if (key == 'dateNaissance') {

          let splt = val.split('/')
          var valObj = new Date(+splt[2], +splt[1]-1, +splt[0]);
          valObj.setHours(0,0,0,0);

          var valTab = new Date((d as any)[key]);
          valTab.setHours(0,0,0,0);

          //console.log(valObj);
          //console.log(valTab);
          //console.log(valTab.getTime() == valObj.getTime())

          return valTab.getTime() == valObj.getTime();

        } else if (key == 'statut') {

          console.log((d as any)[val]);
          if ((d as any)[val] == "Oui") {
            return true;
          } else {
            return Boolean(Number((d as any)[val]));
          }

        } else {

          return (d as any)[key].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(val) !== -1 || !val;
          }

      }

      return false;

    });

  }

  }

  this.clients = temp;


    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  updateFilterAnimaux() {

		var temp = this.fullDataAnimaux;
    console.log(this.filtresAnimaux);

	  for (let key in this.filtresAnimaux) {

			let value = this.filtresAnimaux[key];

			if (value != '') {

				const val = value.toLowerCase();

				temp = temp.filter(function (d: Animal) {

				  if ((d as any)[key] != undefined) {

			  		let val1 = 'auRefuge'
			  		let val2 = 'familleAccueil'

						return (!Boolean(Number((d as any)[val1])) || !Boolean(Number((d as any)[val2]))) && ((d as any)[key].toLowerCase().indexOf(val) !== -1 || !val);

				  }

				  return false;

				});

			}

	  }

    this.animaux = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;

  }

  choixClient(choix: string): void {

	  if (choix == 'nouveau') {

			this.client = new Client();
			this.client.benevole = "Non"
			this.clientId= 0;

		  this.nouveauClient = true;
		  this.clientExistant = false;
		  this.clientEditer = false;

	  } else if (choix == 'existant') {

		  this.getClients();

		  this.nouveauClient = false;
		  this.clientExistant = true;
		  this.clientEditer = false;

	  } else if (choix == 'editer') {

		  this.nouveauClient = false;
		  this.clientExistant = false;
		  this.clientEditer = true;

	  }


  }

  choixAnimal(choix: string): void {

	  if (choix == 'nouveau') {

		  this.nouvelAnimal = true;
		  this.animalExistant = false;

	  } else if (choix == 'existant') {

		  this.getAnimaux();

		  this.nouvelAnimal = false;
		  this.animalExistant = true;

	  }


  }

  getLocalite(){

	  this.apiService.getLocaliteID(this.client!.idLocalite).subscribe(
		ville => {

			this.client!.localite = ville[0].Ville;

		})

  }

  getVilles(): void {

		this.apiService.getLocalites()
				.subscribe(villes => {

					villes.forEach( (option) => {
						this.localitesOptions!.push(option.Ville)
					});

				})

  }

  onEdit(): void{

    if (this.client) {

      const dialogPosition: DialogPosition = {
        left: '15vw'
      };

      const dialogRef = this.dialog.open(ClientDetailsComponent, {
        data: this.clientId,
        height: '80vh',
        width: '80vw',
        position: dialogPosition,
        disableClose: true,
        autoFocus: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.apiService.getClients()
        .subscribe(
          clients => {
            this.fullData = clients;
            this.clients = clients;
          },
          err => console.log(err),
          () => {
            console.log('Complete');
            this.updateFilterClients();
          }
        )
      });
    }

		/*if (event['type'] == 'click') {
			this.router.navigate(['/details-client/'+ event['row']['id']]);
		}*/

  }

  onEditAnimal(): void{

    if (this.animal) {

      console.log(this.animal);

      const dialogPosition: DialogPosition = {
        left: '15vw'
      };

      const dialogRef = this.dialog.open(AnimalDetailsComponent, {
        data: this.animalId,
        height: '80vh',
        width: '80vw',
        position: dialogPosition,
        disableClose: true,
        autoFocus: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.apiService.getAnimaux()
        .subscribe(
          animaux => {
            this.fullDataAnimaux = animaux;
            this.animaux = animaux;
          },
          err => console.log(err),
          () => {
            console.log('Complete');
            this.updateFilterAnimaux();
          }
        )
      });

      

    }

		/*if (event['type'] == 'click') {
			this.router.navigate(['/details-client/'+ event['row']['id']]);
		}*/

  }

}
