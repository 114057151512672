
<div class="row">
    <div class="col-md-6">
        <div class="card card-user"  *ngIf="client">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                        <h5 class="card-title">Nouvel utilisateur</h5>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Personne</label>
                            <select name="personne" class="form-control" [(ngModel)]="client!.personne">
                                <option value="Physique">Physique</option>
                                <option value="Morale">Morale</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-md-4 pr-1">		
                            <div class="form-group">
                                <label [style]="incomplete && (client.nom === '' || !client.nom) ? 'color: red;' : ''">Nom de famille</label>
                                <input [style]="incomplete && (client.nom === '' || !client.nom) ? 'border-color: red;' : ''" type="text" name="Nom" class="form-control" [(ngModel)]="client!.nom">
                            </div>		  
                        </div>
                        <div class="col-md-4 pr-1">
                            <div class="form-group">
                                <label [style]="incomplete && (client.prenom === '' || !client.prenom) ? 'color: red;' : ''">Prénom</label>
                                <input [style]="incomplete && (client.prenom === '' || !client.prenom) ? 'border-color: red;' : ''" type="text" name="Prenom" class="form-control" [(ngModel)]="client!.prenom" >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Bénévole ?</label>
                                <select name="benevole" class="form-control" [(ngModel)]="client.benevole" value="Non"> 
                                    <option value="Oui">Oui</option>
                                    <option value="Non">Non</option>
                                    <option value="Inactif">Inactif</option>
                                </select>
                            </div>
                        </div>			
                    </div>
                    <div class="row">
                        <div class="col-md-3">		
                            <div class="form-group">
                                <label>Date de naissance</label>
                                <input type="text" name="dateNaissance" class="form-control" readonly matInput [matDatepicker]="dateNaissance" [(ngModel)]="client!.dateNaissance">
                            </div>		  
                        </div>
                        <div class="col-md-1 pl-1">	
                            <label class="datePickerIconLabel">_____</label>
                            <mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
                            <mat-datepicker #dateNaissance></mat-datepicker>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>sexe</label>
                                <select name="sexe" class="form-control" [(ngModel)]="client.sexe">
                                    <option value="Femme">Femme</option>
                                    <option value="Homme">Homme</option>
                                </select>
                            </div>
                        </div>	
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>N° registre national (11 chiffres)</label>
                                <input type="text" name="regNational" class="form-control validated" pattern="[0-9]{11}" [(ngModel)]="client!.regNational" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label [style]="incomplete && (client.adresse === '' || !client.adresse) ? 'color: red;' : ''">Rue & numéro</label>
                                <input [style]="incomplete && (client.adresse === '' || !client.adresse) ? 'border-color: red;' : ''" type="text" name="adresse" class="form-control" [(ngModel)]="client!.adresse" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (!client.idLocalite) ? 'color: red;' : ''">Code postal</label>
                                <input [style]="incomplete && (!client.idLocalite) ? 'border-color: red;' : ''" type="text" name="idLocalite" class="form-control" [(ngModel)]="client!.idLocalite">
                            </div>
                        </div>                
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (client.localite === '' || !client.localite) ? 'color: red;' : ''">Localité</label>                        
                                <input [style]="incomplete && (client.localite === '' || !client.localite) ? 'border-color: red;' : ''" type="text" 
                                    class="form-control"
                                   name="localite"
                                   
                                   [formControl]="localitesControl"
                                   [matAutocomplete]="auto1"
                                   [(ngModel)]="client!.localite"/>
                                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" >
                                    <mat-option *ngFor="let option of filteredLocalitesOptions | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>	
                        </div>
                    </div>
                    <div class="row">              
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (client.email === '' || !client.email) ? 'color: red;' : ''">Email</label>
                                <input [style]="incomplete && (client.email === '' || !client.email) ? 'border-color: red;' : ''"type="text"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="Email" class="form-control validated" [(ngModel)]="client!.email"  >
                            </div>
                        </div>                            
                        <div class="col-md-4 px-1">
                            <div class="form-group">
                                <label [style]="incomplete && (client.tel1 === '' || !client.tel1) ? 'color: red;' : ''">Téléphone principal</label>
                                <input [style]="incomplete && (client.tel1 === '' || !client.tel1) ? 'border-color: red;' : ''" type="text" name="tel1" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel1">
                            </div>
                        </div>                        
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Téléphone secondaire</label>
                                <input type="text" name="tel2" class="form-control" pattern="[0-9]{10}" [(ngModel)]="client!.tel2"  >
                            </div>
                        </div>                
                    </div>     
                    <div class="row" style="border-top: 1px solid rgba(0, 0, 0, 0.25); padding-top: 15px;">              
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (client.login === '' || !client.login) ? 'color: red;' : ''" style="color: black;">Login</label>
                                <input [style]="incomplete && (client.login === '' || !client.login) ? 'border-color: red;' : ''" type="text" name="Login" class="form-control" [(ngModel)]="client!.login"  >
                            </div>
                        </div>                            
                        <div class="col-md-4 px-1">
                            <div class="form-group">
                                <label [style]="incomplete && (client.mdp === '' || !client.mdp) ? 'color: red;' : ''" style="color: black;">Mot de passe</label>
                                <input [style]="incomplete && (client.mdp === '' || !client.mdp) ? 'border-color: red;' : ''" type="text" name="tel1" class="form-control" [(ngModel)]="client!.mdp">
                            </div>
                        </div>                        
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (confirmerMdp === '' || confirmerMdp !== client.mdp) ? 'color: red;' : ''" style="color: black;">Confirmer mot de passe</label>
                                <input [style]="incomplete && (confirmerMdp === '' || confirmerMdp !== client.mdp) ? 'border-color: red;' : ''" type="text" name="tel2" class="form-control" [(ngModel)]="confirmerMdp" (focusout)="checkMdp()" >
                            </div>
                        </div>                
                    </div>                                     
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-user">
            <div class="card-header">
      
                <div class="row">
                <div class="col-md-8">
                    <h5 class="card-title">Informations refuge</h5>
                </div>
                </div>
      
            </div>

            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <form method="post" enctype="multipart/form-data" style="width: 350px;">
                                        <input id="upload" style="opacity: 100; position: relative;" type="file" name="img_file" class="form-control" accept=".jpg" title="Upload PHOTO" (change)="handleFileInput($event)"/>
                                    </form>	
                                    <!--<button mat-raised-button (click)="onSubmit()">OK</button>-->
                                </div>
                                <div style="display: flex; align-items: center; width: 100%; justify-content: center;">
                                    <img [src]="imageToShow"
                                    alt="Place image title"
                                    *ngIf="!isImageLoading; else noImageFound" style="margin-top: 15px; max-height: 150px; border-radius: 25px;">
                                    <ng-template #noImageFound>
                                        <img src="" alt="Aucune image">
                                    </ng-template>	
                                </div>
                                
                            </div>	
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (refuge.nom === '' || !refuge.nom) ? 'color: red;' : ''">Nom du refuge</label>
                                <input [style]="incomplete && (refuge.nom === '' || !refuge.nom) ? 'border-color: red;' : ''" type="text" class="form-control" name="Nom" [(ngModel)]="refuge.nom">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Nom complet</label>
                                <input type="text" class="form-control" name="Nom complet" [(ngModel)]="refuge.nomComplet">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label [style]="incomplete && (refuge.adresse === '' || !refuge.adresse) ? 'color: red;' : ''">Adresse</label>
                                <input [style]="incomplete && (refuge.adresse === '' || !refuge.adresse) ? 'border-color: red;' : ''" type="text" class="form-control" name="Adresse" [(ngModel)]="refuge.adresse">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (refuge.tel === '' || !refuge.tel) ? 'color: red;' : ''">Tel</label>
                                <input [style]="incomplete && (refuge.tel === '' || !refuge.tel) ? 'border-color: red;' : ''" type="text" class="form-control" name="Tel" [(ngModel)]="refuge.tel">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label [style]="incomplete && (refuge.mail === '' || !refuge.mail) ? 'color: red;' : ''">Mail</label>
                                <input [style]="incomplete && (refuge.mail === '' || !refuge.mail) ? 'border-color: red;' : ''" type="text" class="form-control" name="Mail" [(ngModel)]="refuge.mail">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Site Web</label>
                                <input type="text" class="form-control" name="siteWeb" [(ngModel)]="refuge.siteWeb">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Numéro entreprise</label>
                                <input type="text" class="form-control" name="numEntreprise" [(ngModel)]="refuge.numEntreprise">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Numéro agrément</label>
                                <input type="text" class="form-control" name="numAgrement" [(ngModel)]="refuge.numAgrement">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Type de refuge</label>
                                <input type="text" class="form-control" name="typeRefuge" [(ngModel)]="refuge.typeRefuge">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row" style="width: 100%;">
        <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" (click)="creerNouvelUtilisateur()">Créer nouvel utilisateur</button>
        </div>
    </div>
</div>