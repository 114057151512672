import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'animauxPipe'})
export class AnimauxPipe implements PipeTransform {
  
  private _datePipe: DatePipe = new DatePipe('fr-FR');

  transform(value: any): string | null {
    if (typeof value === "string") {
      if (value === '0' || value === '1') {
        if (Boolean(Number(value)) == true) {
          return '✔️';
        } else {
          return '';
        }
      } else {
        if (value == 'S. Spécial' || value == 'Réservé') {
          return '<strong>' + value + '</strong>';
        } else {
          if (((value.substring(0,2) === '20') || (value.substring(0,2) === '19')) && (value.length === 10) && (value.substring(2,3) !== '/')) {
            return this._datePipe.transform(value, 'dd/MM/yyyy');
          } else {
            return value;
          }
        }
      }
    } else {
      return value;
    }
    
  }
}