import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { ClientDetailsComponent } from '../client-details/client-details.component';

import { Client } from '../client';
import { ApiService } from '../api.service';

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  clients: Client[] = [];
  fullData: Client[] = [];
  filtres: Dictionary<string> = {};

	// format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');

  /*columns = [
			{ prop: 'nom', name: "Nom", width: 125, frozenLeft: true },
			{ prop: 'prenom', name: "Prénom", width: 125, frozenLeft: true  },
			{ prop: 'dateNaissance', name: "Date naissance", width: 200 },
			{ prop: 'adresse',  	name: 'Adresse', width: 150 },
			{ prop: 'idLocalite',  	name: 'CP', width: 55},
			{ prop: 'tel1',	name: 'Tel 1', width: 125 },
      { prop: 'tel2',	name: 'Tel 2', width: 125 },
			{ prop: 'email',	name: 'Email' },
			{ prop: 'adoptant',  	name: 'Adopt.', pipe: this.booleanPipe(), width: 65 },
			{ prop: 'benevole',  	name: 'Benev.', pipe: this.benevolePipe(), width: 65 },
			{ prop: 'donateur',  	name: 'Donat.', pipe: this.booleanPipe(), width: 65 },
			{ prop: 'surListeNoire',  	name: 'LN', pipe: this.booleanPipe(), width: 65 },
			{ prop: 'testamentaire',  	name: 'Testa.', pipe: this.booleanPipe(), width: 65 },
			{ prop: 'vip',  	name: 'VIP', pipe: this.booleanPipe(), width: 65 },
			{ prop: 'niveauAcces',  	name: 'Accès', width: 100 },
			];*/

  columns: [{ prop: string; name: string; width: number; frozenLeft: boolean;}] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

  mainColumns = ['nom', 'prenom'];

  constructor(private apiService: ApiService,
  						private router: Router,
              public dialog: MatDialog) {

  }

  ngOnInit(): void {
	  this.getClients();
	  this.resetFilter();
  }

  onSelect(event: any): void{

    if (event['type'] == 'click') {

      const dialogPosition: DialogPosition = {
        left: '15vw'
      };

      const dialogRef = this.dialog.open(ClientDetailsComponent, {
        data: event['row']['id'],
        height: '80vh',
        width: '80vw',
        position: dialogPosition,
        disableClose: true,
        autoFocus: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.apiService.getClients()
        .subscribe(
          clients => {
            this.fullData = clients;
          },
          err => console.log(err),
          () => this.updateFilter()
        )
      });
    }

		/*if (event['type'] == 'click') {
			this.router.navigate(['/details-client/'+ event['row']['id']]);
		}*/

  }

  booleanPipe () {
	  return {transform: (value: boolean) => (Boolean(Number(value)) == true) ? '✔️' : ''};
	}

  // Ajouté par Yoan Gornes pour traiter la valeur de benevole car non boolean

  benevolePipe () {
    return {transform: (value: string) => (value == "Oui") ? '✔️' : ''};
  }

  getClients(): void {
	this.apiService.getClients()
	  .subscribe(clients => {
		  this.fullData = clients;
		  this.clients = clients;
	  },
    err => console.log(err),
    () => this.getColonnes())
  }

  getColonnes(): void {
    this.apiService.getColonnesClients(+localStorage.getItem('refuge')!).subscribe(
      x => {
        const colonnes: string = x[0].colonnesClients;
        const colonnesSplit = colonnes.split(" ");

        let tempCol: [{ prop: string; name: string; width: number; frozenLeft: boolean;}] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

        colonnesSplit.forEach(colonne => {
          let bool: boolean = false;
          let name: string = "";
          if (this.mainColumns.includes(colonne)) {
            bool = true;
          }
          colonne.split(/(?=[A-Z])/).forEach(x => {
            name += x.toUpperCase() + " ";
          });
          tempCol.push({ prop: colonne, name: name, width: 200, frozenLeft: bool })
        });
        tempCol.shift();
        this.columns = tempCol;
      }
    )
  }

  resetFilter(): void {

	  for (let key in this.filtres) {
			this.filtres[key] = "";
	  }

	  this.clients = this.fullData;

  }

  datePipe () {
		return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
	}

  censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  updateFilter() {

	var temp = this.fullData;

	  for (let key in this.filtres) {

		let value = this.filtres[key];

		if (value != '') {

			const val = (key == 'dateNaissance' || key == 'statut') ? value : value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

			temp = temp.filter(function (d: Client) {
        if (key == 'tel' && (d.tel1 || d.tel2)) {
          let tempTel1 = "";
          let tempTel2 = "";

          if (d.tel1) {
            tempTel1 = d.tel1.replace(/[&\/\\#,+()$~%.' ":*?<>{}]/g, '');
            tempTel1 = tempTel1.substring(0, value.length);
          }

          if (d.tel2) {
            tempTel2 = d.tel2.replace(/[&\/\\#,+()$~%.' ":*?<>{}]/g, '');
            tempTel2 = tempTel2.substring(0, value.length);
          }

          return (tempTel1 == value) || (tempTel2 == value);
        }

			  if (key == 'statut' || (d as any)[key] != undefined) {

				  if (key == 'dateNaissance') {

					  let splt = val.split('/')
					  var valObj = new Date(+splt[2], +splt[1]-1, +splt[0]);
					  valObj.setHours(0,0,0,0);

					  var valTab = new Date((d as any)[key]);
					  valTab.setHours(0,0,0,0);

					  //console.log(valObj);
					  //console.log(valTab);
					  //console.log(valTab.getTime() == valObj.getTime())

					  return valTab.getTime() == valObj.getTime();

					} else if (key == 'statut') {

						console.log((d as any)[val]);
            if ((d as any)[val] == "Oui") {
              return true;
            } else {
              return Boolean(Number((d as any)[val]));
            }

					} else {

						return (d as any)[key].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(val) !== -1 || !val;
					  }

			  }

			  return false;

			});

		}

	  }

    this.clients = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

}
