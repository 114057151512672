import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { ClientDetailsComponent } from '../client-details/client-details.component';
import { ApiService } from '../api.service';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { Client } from '../client';

interface Dictionary<T> {
    [Key: string]: T;
}


@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {

  client: Client = new Client();

  clients: Client[] = [];
  fullData: Client[] = [];
  filtres: Dictionary<string> = {};

  clientsOptions: string[] = [];
  clientsControl = new FormControl();
  filteredClientsOptions?: Observable<string[]>;

  // format date
  private _datePipe: DatePipe = new DatePipe('fr-FR');

  connected: boolean = false;

  columns = [
			{ prop: 'nom', name: "nom" },
			{ prop: 'prenom', name: "Prénom" },
			{ prop: 'dateNaissance', name: "Date de naissance"  },
			{ prop: 'adresse',  	name: 'Adresse' },
			{ prop: 'tel1',	name: 'Tel 1'},
			{ prop: 'email',	name: 'Email' },
			{ prop: 'niveauAcces',	name: 'Niveau d\'accès' },
			];

   constructor(private apiService: ApiService, private router: Router, public dialog: MatDialog) {

  }

  ngOnInit(): void {
	  this.getClientsAcces();
	 this.getDropdownOptions();

	this.filteredClientsOptions = this.clientsControl.valueChanges.pipe(
	  startWith(''),
	  map(value => this._filterClients(value))
	);

	if (localStorage.getItem('auth')) {

		if (localStorage.getItem('level') == 'Direction' ||
			localStorage.getItem('level') == 'Administratif') {

				this.connected = true;

		}

	}

  }

  getDropdownOptions(): void {

  // Clients
	this.apiService.getClients()
		.subscribe(options => {

			options.forEach( (option) => {
					this.clientsOptions!.push(option.nom+', '+option.prenom+' ('+option.id+')')
			  });
		})

  }

  private _filterClients(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.clientsOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
  }

  datePipe () {
	return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
	}

  censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  creerAcces(): void {

	  this.client.mdp = "newaccess"; // MUST BE like that for the backend to understand it's access creation

	  this.apiService.updateObjet(this.client, 'id', this.client.id, 'clients').subscribe();

	this.client = new Client();
	this.clientsControl.setValue("");



  }

  onSelect(event: any): void{
	/*if (event['type'] == 'click') {
		this.router.navigate(['/details-client/'+ event['row']['id']]);
	}*/
    if (event['type'] == 'click') {
      const dialogPosition: DialogPosition = {
        left: '15vw'
      };

      const dialogRef = this.dialog.open(ClientDetailsComponent, {
        data: event['row']['id'],
        height: '80vh',
        width: '80vw',
        position: dialogPosition,
        disableClose: true,
        autoFocus: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.apiService.getClients()
        .subscribe(
          clients => {
            this.fullData = clients;
          },
          err => console.log(err),
          () => console.log('Ok')
        )
      });
    }
  }

  setSelectedClient(): void {

		this.client.id = this.clientsControl.value.split('(')[1].split(')')[0];

		this.apiService.getClientID(this.client.id)
		.subscribe(options => {

			this.client.email = options[0].email;
		})
  }


  getClientsAcces(): void {
	this.apiService.getClientsAcces()
	  .subscribe(clients => {
		  this.fullData = clients;
		  this.clients = clients;
	  })
  }

}
