<div class="row">
  <div (click)="closePopup()" class="croix-rouge">❌</div>
<div class="col-md-6">
	<div class="card card-user"  *ngIf="client">
	  <div class="card-header">

		  <div class="row">
		  <div class="col-md-8">
			<h5 class="card-title">{{client.nom}}, {{client.prenom}}</h5>
			<strong>{{statutSpecial}}</strong>

			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>Personne</label>
					<select name="personne" class="form-control"
					[(ngModel)]="client.personne" (change)="updateField('clients', 'personne')">

				  <option value="Physique">Physique</option>
				  <option value="Morale">Morale</option>

				</select>
			  </div>

			</div>
			</div>

	  </div>
	  <div class="card-body">
		<form>
		  <div class="row">

        <div class="col-md-4">
          <div class="form-group">
          <label>Nom de famille</label>
          
            <input type="text" name="nom" class="form-control" [(ngModel)]="client.nom" (focusout)="updateField('clients', 'nom')">
          
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
          <label>Prénom</label>
          
            <input type="text" name="Prenom" class="form-control" [(ngModel)]="client.prenom" (focusout)="updateField('clients', 'prenom')">
          
          </div>
        </div>
		  </div>

		  <div class="row">


				<div class="col-md-3">
				  <div class="form-group">
					<label>Date de naissance</label>
					
						<input type="text" name="dateNaissance" class="form-control" matInput readonly [matDatepicker]="dateNaissance" [(ngModel)]="client.dateNaissance"
								(dateChange)="updateField('clients', 'dateNaissance')">
          

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>

				<div class="col-md-4">
					<div class="form-group">
						<label>sexe</label>

						<select name="sortie" class="form-control"
							[(ngModel)]="client!.sexe" (change)="updateField('clients', 'sexe')">

						  <option value="Femme">Femme</option>
						  <option value="Homme">Homme</option>

						</select>
					</div>
				</div>


			<div class="col-md-4">
			  <div class="form-group">
				<label>N° registre national (11 chiffres)</label>
				
          <input type="text" pattern="[0-9]{11}" name="regNational" class="form-control validated" [(ngModel)]="client.regNational" (focusout)="updateField('clients', 'regNational')">

				
			  </div>
			</div>


		  </div>

		  <div class="row">

			<div class="col-md-8">
			  <div class="form-group">
				<label>Rue & numéro</label>
				
          <input type="text" name="adresse" class="form-control" [(ngModel)]="client.adresse"  (focusout)="updateField('clients', 'adresse')">
        

				
			  </div>
			</div>

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Pas de courrier</label>
				<input type="checkbox" name="pasDeCourrier" class="form-control" [(ngModel)]="client.pasDeCourrier" (focusout)="updateField('clients', 'pasDeCourrier')">
			  </div>
			</div>

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Mail uniquement</label>
				<input type="checkbox" name="mailUniquement" class="form-control" [(ngModel)]="client.mailUniquement" (focusout)="updateField('clients', 'mailUniquement')">
			  </div>
			</div>

		  </div>

		  <div class="row">

			<div class="col-md-4">
			  <div class="form-group">
				<label>Code postal</label>
				<input type="text" name="idLocalite" class="form-control" [(ngModel)]="client.idLocalite"  (focusout)="updateField('clients', 'idLocalite');getLocalite()">
			  </div>
			</div>


			<div class="col-md-4">
					<div class="form-group">
					<label>Localité</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="localite"

							   [formControl]="localitesControl"
							   [matAutocomplete]="auto1"
							   [(ngModel)]="client.localite"
							   (focusout)="updateField('clients', 'localite')"/>

						<mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="updateField('clients', 'localite')">
						  <mat-option *ngFor="let option of filteredLocalitesOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>

			</div>


			<div class="col-md-4">
					<div class="form-group">
					<label>Pays</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="pays"

							   [formControl]="paysControl"
							   [matAutocomplete]="auto"
							   [(ngModel)]="client.pays"/>

						<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="updateField('clients', 'pays')">
						  <mat-option *ngFor="let option of filteredPaysOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>

			</div>
		  </div>


		  <div class="row">

		  <div class="col-md-4">
			  <div class="form-group">
				<label>Email</label>
				
          <input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="Email" class="form-control validated" [(ngModel)]="client.email"  (focusout)="updateField('clients', 'email')">
		  
			  </div>
			</div>

			<div class="col-md-4">
			  <div class="form-group">
				<label>Téléphone principal</label>

          <input type="text" name="tel1" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client.tel1"  (focusout)="updateField('clients', 'tel1')">
		  
			  </div>
			</div>

			<div class="col-md-4">
			  <div class="form-group">
				<label>Téléphone secondaire</label>
				
          <input type="text" name="tel2" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client.tel2"  (focusout)="updateField('clients', 'tel2')">
		  
			  </div>
			</div>

		  </div>



		  <div class="row">

		  <div class="col-md-2">

				<div class="form-group">
					<label>Donateur</label>
					<input type="checkbox" disabled name="donateur" class="form-control" [(ngModel)]="client!.donateur" (change)="updateField('clients', 'donateur');updateStatus()">
				  </div>

			</div>



				<div class="col-md-4" *ngIf="client!.donateur">

				<div class="row">
				<div class="col-md-9">
				  <div class="form-group">
					<label>Date de dernier don</label>
					<input type="text" disabled name="dateDernierDon" class="form-control" matInput [matDatepicker]="dateDernierDon" [(ngModel)]="client.dateDernierDon"
					(dateChange)="updateField('clients', 'dateDernierDon')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDernierDon" ></mat-datepicker-toggle>
					<mat-datepicker #dateDernierDon></mat-datepicker>
				</div>
				</div>
			</div>




		</div>


			<div class="row">

			<div class="col-md-2">

				<div class="form-group">
					<label>Client VIP</label>
					<input type="checkbox" name="vip" class="form-control" [(ngModel)]="client!.vip" (change)="updateField('clients', 'vip');updateStatus()">
				  </div>

			</div>


				<div class="col-md-4" *ngIf="client!.vip">

				<div class="row">
					<div class="col-md-9">
					  <div class="form-group">
						<label>Date client VIP</label>
						<input type="text" name="vipDate" class="form-control" readonly matInput [matDatepicker]="vipDate" [(ngModel)]="client.vipDate"
						(dateChange)="updateField('clients', 'vipDate')">

					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="vipDate" ></mat-datepicker-toggle>
						<mat-datepicker #vipDate></mat-datepicker>
					</div>
				</div>
				</div>

		</div>




		  <div class="row">

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Liste noire</label>
				<input type="checkbox" name="surListeNoire" class="form-control" [(ngModel)]="client.surListeNoire" (change)="updateField('clients', 'surListeNoire');updateStatus()">
			  </div>
			</div>

			<div class="col-md-4 pr-1"  *ngIf="client.surListeNoire">
				<div class="row">

					<div class="col-md-9">
				  <div class="form-group">
					<label>Date ajout liste noire</label>
					<input type="text" name="listeNoireDate" class="form-control" matInput readonly [matDatepicker]="listeNoireDate" [(ngModel)]="client.listeNoireDate"
					(dateChange)="updateField('clients', 'listeNoireDate')">

				  </div>
				</div>
				<div class="col-md-3 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="listeNoireDate" ></mat-datepicker-toggle>
					<mat-datepicker #listeNoireDate></mat-datepicker>
				</div>

			</div>




			</div>

			</div>

			<div class="row"  *ngIf="client.surListeNoire">


			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire ajout liste noire</label>
				<input type="text" name="listeNoireInfo" class="form-control" [(ngModel)]="client.listeNoireInfo"  (focusout)="updateField('clients', 'listeNoireInfo')">
			  </div>
			</div>


		  </div>


		  <div class="row">

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Testamentaire</label>
				<input type="checkbox" name="testamentaire" [(ngModel)]="client.testamentaire" class="form-control" (change)="updateField('clients', 'testamentaire');updateStatus()">
			  </div>
			</div>

				<div class="col-md-4 pr-1">
				<div class="row" *ngIf="client.testamentaire">
				<div class="col-md-9">
					  <div class="form-group">
						<label>Date testamentaire</label>
						<input type="text" name="dateTestamentaire" class="form-control" matInput readonly [matDatepicker]="dateTestamentaire" [(ngModel)]="client.dateTestamentaire"
						(dateChange)="updateField('clients', 'dateTestamentaire')">

					  </div>
					</div>
					<div class="col-md-3 pl-1">
					<label class="datePickerIconLabel">_____</label>
					<mat-datepicker-toggle matSuffix [for]="dateTestamentaire" ></mat-datepicker-toggle>
						<mat-datepicker #dateTestamentaire></mat-datepicker>
					</div>
			  </div>
			  </div>
			</div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Bénévole ?</label>

            <select name="benevole" class="form-control"
              [(ngModel)]="client.benevole" (change)="updateField('clients', 'benevole');updateStatus()">

              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
              <option value="Inactif">Inactif</option>

            </select>
          </div>
        </div>

        <div *ngIf="client.benevole == 'Oui'" style="width: fit-content; display: contents;">

          <div class="col-md-4">
            <div class="form-group">
              <label>Date début</label>
              <input type="text" name="dateDebutBenevole" class="form-control" matInput readonly [matDatepicker]="dateDebutBenevole" [(ngModel)]="client.dateDebutBenevole" (dateChange)="updateField('clients', 'dateDebutBenevole')">


            </div>
          </div>

          <div class="col-md-1 pl-1" style="padding-left: 0px !important;">
            <label class="datePickerIconLabel">_____</label>
            <mat-datepicker-toggle matSuffix [for]="dateDebutBenevole" ></mat-datepicker-toggle>
              <mat-datepicker #dateDebutBenevole></mat-datepicker>
          </div>

          <div class="col-md-4" style="max-width: 25%;">
            <div class="form-group">
              <label>Niveau Bénévole</label>

              <select name="niveauBenevole" class="form-control" [(ngModel)]="client.niveauBenevole" (change)="updateField('clients', 'niveauBenevole')">

                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>

              </select>
            </div>
          </div>
        </div>

      </div>

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire client</label>
				<textarea class="form-control textarea" [(ngModel)]="client.commentaire" name="Commentaire"  (focusout)="updateField('clients', 'commentaire')"></textarea>
			  </div>
			</div>
		  </div>

			<div class="row">

				<div class="col-md-8">
					
					<div class="form-group">
						<label>PDF</label>
						<div style="display: flex; justify-content: start;">
							<form method="post" enctype="multipart/form-data">
								<input id="upload" style="opacity: 100; position: relative;" type="file" name="pdf_file" class="form-control" accept=".pdf" title="Upload PDF" (change)="handleFileInput($event)"/>
							</form>	
							<button mat-raised-button (click)="onSubmit()">OK</button>
						</div>
						<div style="margin-top: 10px;" *ngFor="let pdf of pdfList; index as i;">
							<span style="color: #9A9A9A; margin-right: 10px;">{{pdf}}</span>
							<button mat-raised-button (click)="onDownload(i)" style="color: #5c5c5c; margin-right: 10px;"><mat-icon>file_download</mat-icon></button>
							<button mat-raised-button (click)="onDelete(i)" style="color: #5c5c5c;"><mat-icon>delete</mat-icon></button>
						</div>
					</div>
					
				</div>
					
				<div class="col-md-4" *ngIf="(niveauAcces == 'Direction' || niveauAcces == 'Administratif') && client.niveauAcces">

					<div class="form-group">
					<label>Niveau d'accès</label>

					<select name="filtreStatut" class="form-control"
						[(ngModel)]="client.niveauAcces" (change)="updateField('clients', 'niveauAcces')">

						<option value="Direction">Direction</option>
						<option value="Administratif">Administratif</option>
						<option value="SBA">SBA </option>
						<option value="Accueil 1">Accueil 1</option>
						<option value="Accueil 2">Accueil 2</option>
						<option value="Accueil 3">Accueil 3</option>
						<option value="Accueil 4">Accueil 4</option>

					</select>
					</div>

				</div>
			</div>


			<div *ngIf="donneesDisplay" class="row">
				<div *ngFor="let donnee of donneesDisplay; index as i;">
					
					<div class="col-md-12">
						<div class="form-group" style="padding-left: 15px;">
							<label>{{decamelize(donnee.nom, " ")}}</label>

							<div *ngIf="getType(donnee.valeur) === 'string'" >
								<input type="text" class="form-control" [(ngModel)]="donnee.valeur" (focusout)="updateFieldDisplay('clients', donnee.nom, donnee.valeur)" [ngModelOptions]="{standalone: true}">
							</div>

							<div *ngIf="getType(donnee.valeur) === 'boolean'" >						
								<input type="checkbox" [(ngModel)]="donnee.valeur" (change)="updateFieldDisplay('clients', donnee.nom, donnee.valeur);updateStatus()" [ngModelOptions]="{standalone: true}">
							</div>
					
							<div *ngIf="getType(donnee.valeur) === 'Date'" style="display: flex;">
								<input type="text" class="form-control" readonly matInput [matDatepicker]="i" [(ngModel)]="donnee.valeur"
										(dateChange)="updateFieldDisplay('clients', donnee.nom, donnee.valeur)" [ngModelOptions]="{standalone: true}">
								<label class="datePickerIconLabel">_____</label>
								<mat-datepicker-toggle matSuffix [for]="i" ></mat-datepicker-toggle>
								<mat-datepicker #i></mat-datepicker>
							</div>
						</div>
					</div>
				</div>
			</div>


		</form>
	  </div>
	</div>
</div>



<div class="col-md-6">
	<div class="row">
	<div class="col-md-12">
		<div class="card card-user"  *ngIf="entrees">
		  <div class="card-header">
			<h5 class="card-title">Entrées animaux</h5>
		  </div>
		  <div class="card-body">

			<ngx-datatable  class="material" [rows]="entrees" [columns]="columnsEntrees" (activate)="onSelect($event)" [headerHeight]="50" [rowHeight]="40"  [footerHeight]="0" [scrollbarH]="true" [sorts]="[{prop: 'dateEntree', dir: 'desc'}]" [messages]="messages">
			</ngx-datatable>
		  </div>
		 </div>
	</div>
	</div>
	<div class="row">
	<div class="col-md-12">
		<div class="card card-user"  *ngIf="sorties">
		  <div class="card-header">
			<h5 class="card-title">Sorties animaux</h5>
		  </div>
		  <div class="card-body">
			<ngx-datatable  class="material" [rows]="sorties" [columns]="columnsSorties" (activate)="onSelect($event)" [headerHeight]="50" [rowHeight]="40"  [footerHeight]="0" [scrollbarH]="true" [sorts]="[{prop: 'dateSortie', dir: 'desc'}]" [messages]="messages">
			</ngx-datatable>
		  </div>
		 </div>
		 </div>
	</div>
	<div class="row">
	<div class="col-md-12">
		<div class="card card-user"  *ngIf="dons">
		  <div class="card-header">
			<h5 class="card-title">Dons</h5>
		  </div>
		  <div class="card-body">
			<ngx-datatable  class="material" [rows]="dons" [columns]="columnsDons" (activate)="onSelectDon($event)" [headerHeight]="50" [rowHeight]="40"  [footerHeight]="0" [scrollbarH]="true"
			[sorts]="[{prop: 'dateDon', dir: 'desc'}]" [messages]="messages">
			</ngx-datatable>
		  </div>
		 </div>
		 </div>
	</div>
</div>





		<div class="col-md-12">

		  <div class="card-footer">

			<mat-dialog-actions>
			    <div style="display: flex; align-items: center;">
					<button [color]="deleteConfirm" mat-raised-button (click)="delete('clients', 'id', client!.id)">Supprimer</button>
					<div *ngIf="deleteConfirm == 'warn'" style="color: #9A9A9A; margin-left: 10px;">Cliquez à nouveau pour confirmer</div>
				</div>
				

          <button mat-raised-button (click)="closePopup()">Valider</button>
			</mat-dialog-actions>


			  </div>

			</div>





</div>
