import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Client } from '../client';
import { ApiService } from '../api.service';


// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { ClientDetailsComponent } from '../client-details/client-details.component';
import { Refuge } from '../refuge';
import { Contrat } from '../contrat';
import { Login } from '../login';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'nouveau-user',
  templateUrl: './nouveau-user.component.html',
  styleUrls: ['./nouveau-user.component.css']
})
export class NouveauUserComponent implements OnInit {

  client: Client = new Client();

  imageSrc:any = '';
  status:boolean = false;

	imageToShow: any;
	isImageLoading = true;

  refuge: Refuge = {
    id : 0,
    nom : "",
    nomComplet: "",
    adresse: "",
    tel: "",
    mail: "",
    siteWeb: "",
    typeRefuge: "",
    numEntreprise: "",
    numAgrement: "",
    sites: "",
    photo: ""
  };

  confirmerMdp: string = "";

  incomplete = false;

  localitesOptions: string[] = [];
	localitesControl = new FormControl();
  filteredLocalitesOptions?: Observable<string[]>;

  constructor(
	private router: Router,
	private apiService: ApiService,
  private _snackBar: MatSnackBar,
  public dialog: MatDialog
	) { }

  ngOnInit(): void {

	  this.client.personne = "Physique";
	  this.client.benevole = "Non"

		this.getVilles();
		this.filteredLocalitesOptions = this.localitesControl.valueChanges.pipe(
	      startWith(''),
	      map(value => this._filterLocalites(value))
    );

  }

  private _filterLocalites(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.localitesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  handleFileInput(event: any) {
    this.status = false
      const file = event.target.files[0];
      this.status = event.target.files.length>0?true:false
      if(this.status==true){
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            this.imageSrc = reader.result;          
         }
      }
  }

  onSubmit() {
    if (this.refuge?.photo) {
      this.apiService.uploadPhoto(this.imageSrc, this.refuge!.id, 'refuge', this.refuge?.photo).subscribe(
        response => {
          console.log(response);
        }
      )
    } else {
      this.apiService.uploadPhoto(this.imageSrc, this.refuge!.id, 'refuge').subscribe(
        response => {
          console.log(response);
        }
      )
    }
  }

  checkMdp(){
    if (this.confirmerMdp !== this.client.mdp) {
      window.alert("Les mots de passes sont différents!");
    }
  }

  creerNouvelUtilisateur(): void {

    if ((this.client.adresse !== "" || this.client.adresse) && (this.client.idLocalite) && (this.client.localite !== "" || this.client.localite) && (this.client.email !== "" || this.client.email) && (this.client.login !== "" || this.client.login) && (this.client.mdp !== "" || this.client.mdp) && (this.confirmerMdp === this.client.mdp) && (this.client.nom !== "" || this.client.nom) && (this.client.prenom !== "" || this.client.prenom) && (this.client.tel1 !== "" || this.client.tel1) && (this.refuge.adresse !== "" || this.refuge.adresse) && (this.refuge.mail !== "" || this.refuge.mail) && (this.refuge.nom !== "" || this.refuge.nom) && (this.refuge.tel !== "" || this.refuge.tel)) {

      let log : Login = new Login();
      log.login = "AvecCollier";
      log.mdp = "TestMdp123";

      this.apiService.login(log).subscribe(options => {

        if (options.login == "ERROR_MDP") {
          
        } else if (options.login == "ERROR_LOGIN") {
  
        } else {
          
				  localStorage.setItem('auth', options.auth);
          this.apiService.ajouterNouvelObjet(this.refuge, 'refuge').subscribe(
            x => console.log(x),
            err => console.log(err),
            () => {
              this.apiService.getAllRefuges().subscribe(x => {
                x.forEach(element => {
                  if (element.nom === this.refuge.nom) {
                    this.refuge.id = element.id;
                  }
                });
              },
              err => console.log(err),
              () => {
                if (this.imageSrc) {
                  this.apiService.uploadPhoto(this.imageSrc, this.refuge!.id, 'refuge').subscribe(
                    response => {
                      console.log(response);
                    }
                  )
                }
    
                this.apiService.ajouterNouvelObjet({refuge : this.refuge.id}, 'contrat').subscribe(id => {
                  console.log("return from put " + id);              
                });
      
                this.client.nom = this.client.nom.toUpperCase()
                this.client.prenom = this.client.prenom.toUpperCase()
      
                if (this.client.adresse) {
                  this.client.adresse = this.client.adresse.toUpperCase();
                }
      
                this.client.niveauAcces = "Direction";
      
                this.client.refuge = this.refuge.id;
      
                this.apiService.ajouterNouvelObjet(this.client, 'clients').subscribe(id => {
                  console.log("return from put " + id);
      
                  this._snackBar.open('Utilisateur créé', 'OK');
                  
                });
      
              });
            }
          );

        }  
      });

      
    } else {
      this.incomplete = true;
      window.alert("Les informations sont incomplètes!");
    }

  }

  getVilles(): void {

		this.apiService.getLocalites()
				.subscribe(villes => {

					villes.forEach( (option) => {
						this.localitesOptions!.push(option.Ville)
					});

				})

  }

}
