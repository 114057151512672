import { Component, OnInit } from '@angular/core';

import { ApiService } from '../api.service';
import { Refuge } from '../refuge';
import { Contrat } from '../contrat';
import { AnimalType } from '../animalType';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as XLSX from 'xlsx';
import { Animal } from '../animal';

@Component({
  selector: 'app-personnalisation',
  templateUrl: './personnalisation.component.html',
  styleUrls: ['./personnalisation.component.css']
})

export class PersonnalisationComponent implements OnInit {

  loading = true;
  okay = false;

  imageSrc:any = '';
  status:boolean = false;

	imageToShow: any;
	isImageLoading = true;

  refugeID: number = 0;
  refuge: Refuge = {
    id : 0,
    nom : "",
    nomComplet: "",
    adresse: "",
    tel: "",
    mail: "",
    siteWeb: "",
    typeRefuge: "",
    numEntreprise: "",
    numAgrement: "",
    sites: "",
    photo: ""
  };

  contrat: Contrat = {
    id: 0,
    refuge: 0,
    annexe: "",
    engagementsAdoptant: "",
    engagementsRefuge: "",
    engagementsAutres: ""
  };

  animalTypes: AnimalType[] = [];

  allAnimalTypes: AnimalType[] = [];

  animalTypesSelected: AnimalType[] = [];

  colonnesAffichees : { prop: string; name: string; width: number; frozenLeft: boolean;}[][] = [[{prop: 'oui', name: 'non', width: 1, frozenLeft: true}]];

  colonnesDispo : { prop: string; name: string; width: number; frozenLeft: boolean;}[][] = [[{prop: 'oui', name: 'non', width: 1, frozenLeft: true}]];

  colonnesAfficheesClients : { prop: string; name: string; width: number; frozenLeft: boolean;}[] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

  colonnesDispoClients : { prop: string; name: string; width: number; frozenLeft: boolean;}[] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

  colonnesSelected : { prop: string; name: string; width: number; frozenLeft: boolean;}[] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

  nomNouvelleColonne: string = "";

  typeNouvelleColonne: string = "";

  nouveauType: string = "";

  file: any;
	pdfFile: any[] = [];
	pdfList: string[] = [];

  clauseAdoptant: string = "";
  clauseRefuge: string = "";
  clauseAutre: string = "";
  adoptantList: string[] = [];
  refugeList: string[] = [];
  autreList: string[] = [];

  site: string = "";
  sitesList: string[] = [];

  constructor(
		private apiService: ApiService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (localStorage.getItem('refuge')) {
      this.refugeID = +localStorage.getItem('refuge')!;
      this.getRefugeID();  
      this.getColonnes();
      this.getColonnesClients();
      this.getContrat();
    }
    
  }

  handleFileInputPdf(event: any) {
    this.status = false
    this.file = event.target.files[0];
    this.status = event.target.files.length>0?true:false
  }

  onSubmitPdf() {
    if (this.status) {
      if (this.contrat?.annexe) {
        this.apiService.uploadPdf(this.file, this.refugeID, this.file.name, this.contrat?.annexe, true).subscribe(
          response => {
            console.log(response);
            this.getContrat();
          }
        )
      } else {
        this.apiService.uploadPdf(this.file, this.refugeID, this.file.name, undefined, true).subscribe(
          response => {
            console.log(response);
            this.getContrat();
          }
        )
      }		
    }
  }

  onDownload(index: number): void {
    if (this.pdfFile) {
      window.open(this.pdfFile[index]);
    }
  }
  
  onDelete(index: number): void {
    var name = "./pdf/" + this.pdfList[index];
    var url = "";
    if (this.pdfList[index].slice(-4) !== ".pdf") {
      name += ".pdf";
    }
  
    this.pdfList.splice(index, 1);
  
    this.pdfList.forEach(element => {
      if (element.slice(-4) !== ".pdf") {
        element += ".pdf";
      }
      element = "./pdf/" +element;
      url += element+" ";
    });
    if (url === "") {
      url = " ";
    }
    console.log(url);
    console.log(name);
  
    this.apiService.deletePdf(this.refugeID, name, url, true).subscribe(x => this.getContrat());
    }

  getContrat() {
    this.apiService.getContrat(this.refugeID).subscribe(
      x => {
        this.contrat = x[0];

        if (this.contrat?.annexe) {
          var list = this.contrat.annexe.split(".pdf ");
          var last = list.length - 1;
          if (list[last] === "" || list[last] === " ") {
            list.pop();
          }
          this.pdfList = [];
          this.pdfFile = [];
          list.forEach(element => {
            if (element.slice(-4) !== ".pdf") {
              element += ".pdf";
            }
            this.apiService.getPdf(element).subscribe(
              (data: Blob) => {
                var file = new Blob([data], { type: 'application/pdf' })
                var fileURL = URL.createObjectURL(file);
                this.pdfFile.push(fileURL);
                const nom = element.slice(6);
                this.pdfList.push(nom);
              }
            )					
          });
        }

        if (this.contrat?.engagementsAdoptant) {
          var list = this.contrat.engagementsAdoptant.split("/");
          var last = list.length - 1;
          if (list[last] === "" || list[last] === " ") {
            list.pop();
          }
          this.adoptantList = list;
        }
        if (this.contrat?.engagementsRefuge) {
          var list = this.contrat.engagementsRefuge.split("/");
          var last = list.length - 1;
          if (list[last] === "" || list[last] === " ") {
            list.pop();
          }
          this.refugeList = list;
        }
        if (this.contrat?.engagementsAutres) {
          var list = this.contrat.engagementsAutres.split("/");
          var last = list.length - 1;
          if (list[last] === "" || list[last] === " ") {
            list.pop();
          }
          this.autreList = list;
        }
      }
    )
  }

  getColonnes() {
    ///
    this.loading = true;
    this.okay = false;
    this.colonnesAffichees = [[{prop: 'oui', name: 'non', width: 1, frozenLeft: true}]];
    this.colonnesDispo = [[{prop: 'oui', name: 'non', width: 1, frozenLeft: true}]];
    let tempCol1: [{ prop: string; name: string; width: number; frozenLeft: boolean;}] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];
    this.apiService.getAllColonnes().subscribe(
      col => {
        let colonnes: string[] = [];
        col.forEach(x => {
          colonnes.push(x.COLUMN_NAME);
        })
        
        colonnes.forEach(colonne => {
          let bool: boolean = false;
          let name: string = "";
          colonne.toString().split(/(?=[A-Z])/).forEach(x => {
            name += x.toUpperCase() + " ";
          });
          tempCol1.push({ prop: colonne, name: name, width: 150, frozenLeft: bool })
        })

        tempCol1.shift();
        const yeah = tempCol1.splice(0,3);
        //this.colonnesDispo.push(tempCol1);
        //this.colonnesDispo.shift();
      },
      err => console.log(err),
      () => {
        ///
        this.apiService.getRefugeAnimalTypes(this.refugeID).subscribe(
          types => {
            this.animalTypes = types;

            this.animalTypes.forEach(type => {

              console.log(this.allAnimalTypes);

              this.colonnesDispo.push(tempCol1);

              this.apiService.getColonnes(+localStorage.getItem('refuge')!, type.animaltype).subscribe(
                x => {
                  const colonnes: string = x[0].colonnes;
                  const colonnesSplit = colonnes.split(" ");

                  let tempCol: [{ prop: string; name: string; width: number; frozenLeft: boolean;}] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

                  colonnesSplit.forEach(colonne => {
                    let bool: boolean = false;
                    let name: string = "";
                    colonne.split(/(?=[A-Z])/).forEach(x => {
                      name += x.toUpperCase() + " ";
                    });
                    tempCol.push({ prop: colonne, name: name, width: 150, frozenLeft: bool })
                  });
                  tempCol.shift();
                  this.colonnesAffichees.push(tempCol);
                }
              );
            })
            
            this.colonnesDispo.shift();
            this.colonnesAffichees.shift();

            setTimeout(() => {
              for (let i = 0; i < this.colonnesDispo.length; i++) {
                this.colonnesDispo[i] = this.colonnesDispo[i].filter(ar => !this.colonnesAffichees[i].find(rm => (rm.prop === ar.prop) ))
              }
              this.loading = false;
              this.okay = true;

              this.colonnesAffichees.forEach(
                x => {
                  let wow = x.pop();
                }
              )

            }, 2500);
          },
          err => console.log(err),
          () => {
            console.log(this.colonnesAffichees);
            console.log(this.colonnesDispo);
            this.apiService.getAnimalTypes().subscribe(
              x => {
                this.allAnimalTypes = [];
                x.forEach(type => {
                  let temp : AnimalType = {animaltype: type.id, type: type.type};
                  this.allAnimalTypes.push(temp);
                })
                this.animalTypes.forEach(type => {
                  this.allAnimalTypes = this.allAnimalTypes.filter(el => el.animaltype !== type.animaltype);
                });
              }
            );
          }
        )
      }
    );
    
    

    
  }

  getColonnesClients() {
    this.loading = true;
    this.okay = false;
    this.colonnesAfficheesClients = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];
    this.colonnesDispoClients = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];
    this.apiService.getColonnesClients(this.refugeID).subscribe(
      col => {
        const colonnes: string = col[0].colonnesClients;
        const colonnesSplit = colonnes.split(" ");

        colonnesSplit.forEach(colonne => {
          let bool: boolean = false;
          let name: string = "";
          /*if (this.mainColumns.includes(colonne)) {
            bool = true;
          }*/
          colonne.split(/(?=[A-Z])/).forEach(x => {
            name += x.toUpperCase() + " ";
          });
          this.colonnesAfficheesClients.push({ prop: colonne, name: name, width: 150, frozenLeft: bool });
        });
        this.colonnesAfficheesClients.shift();
      },
      err => console.log(err),
      () => {
        this.apiService.getAllColonnesClients().subscribe(
          col => {
            let colonnes: string[] = [];
            col.forEach(x => {
              colonnes.push(x.COLUMN_NAME);
            })
            
            colonnes.forEach(colonne => {
              let bool: boolean = false;
              let name: string = "";
              colonne.toString().split(/(?=[A-Z])/).forEach(x => {
                name += x.toUpperCase() + " ";
              });
              this.colonnesDispoClients.push({ prop: colonne, name: name, width: 150, frozenLeft: bool });
            })

            setTimeout(() => {
              this.colonnesDispoClients = this.colonnesDispoClients.filter(ar => !this.colonnesAfficheesClients.find(rm => (rm.prop === ar.prop) ));

              this.colonnesDispoClients.shift();

              this.colonnesDispoClients = this.colonnesDispoClients.filter(x => (x.prop !== 'refuge') && (x.prop !== 'login') && (x.prop !== 'mdp') && (x.prop !== 'id') );

              let wow = this.colonnesAfficheesClients.pop();
              
              this.loading = false;
              this.okay = true;

            }, 2500);
          }
        );
      }
    )
  }

  handleFileInput(event: any) {
    this.status = false
      const file = event.target.files[0];
      this.status = event.target.files.length>0?true:false
      if(this.status==true){
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            this.imageSrc = reader.result;          
         }
      }
  }

  onSubmit() {
    if (this.refuge?.photo) {
      this.apiService.uploadPhoto(this.imageSrc, this.refuge!.id, 'refuge', this.refuge?.photo).subscribe(
        response => {
          console.log(response);
          this.getRefugeID();
        }
      )
    } else {
      this.apiService.uploadPhoto(this.imageSrc, this.refuge!.id, 'refuge').subscribe(
        response => {
          console.log(response);
          this.getRefugeID();
        }
      )
    }
  }

  getRefugeID() {
    this.apiService.getRefugeID(this.refugeID).subscribe(
      refuge => {
        this.refuge = refuge[0];
        //console.log(this.refuge);

        var list = this.refuge.sites.split(" ");
        var last = list.length - 1;        
        if (list[last] === "" || list[last] === " ") {
          list.pop();
        }
        this.sitesList = list;

        if(this.refuge?.photo) {
          this.apiService.getImage(this.refuge.photo).subscribe(
            x => {
              this.createImageFromBlob(x);
              this.isImageLoading = false;
            }, error => {
              this.isImageLoading = false;
              console.log(error);
            }
          );              
        }
      }
    )
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
        this.imageToShow = reader.result;
    }, false);
    
    if (image) {
        reader.readAsDataURL(image);
    }
  }

  updateField(table: string, field: string): void {

    this.apiService.updateTable(table, 'id', this.refugeID, field, (this.refuge as any)[field])
    .subscribe(options => {
      //
      console.log("return from put " + options);

    });
    

  }

  onSelect(selected: { prop: string; name: string; width: number; frozenLeft: boolean;}) {
    if (this.colonnesSelected.includes(selected)) {
      this.colonnesSelected.splice(this.colonnesSelected.indexOf(selected), 1);
    } else {
      this.colonnesSelected.push(selected);
    }
    
    console.log(this.colonnesSelected);
  }

  onSelectType(selected: AnimalType) {
    if (this.animalTypesSelected.includes(selected)) {
      this.animalTypesSelected.splice(this.animalTypesSelected.indexOf(selected), 1);
    } else {
      this.animalTypesSelected.push(selected);
    }
    
    console.log(this.animalTypesSelected);
  }

  onChangeTab(){
    this.colonnesSelected = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];
    this.nomNouvelleColonne = "";
    this.typeNouvelleColonne = "";
  }

  onClickSubmitTypes(){
    if (this.animalTypesSelected.length !== 0) {

      this.loading = true;
      this.okay = false;

        this.animalTypesSelected.forEach(
          x => {
            if (this.allAnimalTypes.includes(x)) {
              this.animalTypes.push(x);
              this.allAnimalTypes.splice(this.allAnimalTypes.indexOf(x), 1);

              const obj = {refuge: this.refugeID, animalType: x.animaltype};
              this.apiService.ajouterNouvelObjet(obj, 'refuge_animaltypes_colonnes').subscribe(
                x => console.log(x)
              )

            } else if (this.animalTypes.includes(x)) {
              this.allAnimalTypes.push(x);
              this.animalTypes.splice(this.animalTypes.indexOf(x), 1);

              this.apiService.deleteObjetString('refuge_animaltypes_colonnes', 'animalType', x.animaltype+" AND refuge = "+this.refugeID).subscribe(
                x => console.log(x)
              )
            }
          }
        )

        this.animalTypesSelected = [];

        console.log(this.animalTypes);
        setTimeout(() => {
          this.apiService.getRefugeAnimalTypes(this.refugeID).subscribe(
            types => {
              this.animalTypes = types;
            },
            err => console.log(err),
            () => {
              this.apiService.getAnimalTypes().subscribe(
                x => {
                  this.allAnimalTypes = [];
                  x.forEach(type => {
                    let temp : AnimalType = {animaltype: type.id, type: type.type};
                    this.allAnimalTypes.push(temp);
                  })
                  this.animalTypes.forEach(type => {
                    this.allAnimalTypes = this.allAnimalTypes.filter(el => el.animaltype !== type.animaltype);
                  });
                },
                err => console.log(err),
                () => {
                  this.loading = false;
                  this.okay = true;
                  window.location.reload();
                }
              );
            }
          );
        }, 2000);        
        
    }
  }

  onClickNewType(){
    const obj = {
      type: this.nouveauType
    };

    this.apiService.ajouterNouvelObjet(obj, "animaltypes").subscribe(
      x => this.getColonnes()
    )
    
  }

  onClickSubmit(type: number, index: number){
    let value = "";
    if (this.colonnesSelected.length !== 1) {
      
      if (type === 0) {
        this.colonnesSelected.forEach(
          x => {
            if (this.colonnesDispoClients.includes(x)) {
              this.colonnesAfficheesClients.push(x);
              this.colonnesDispoClients.splice(this.colonnesDispoClients.indexOf(x), 1);
            } else if (this.colonnesAfficheesClients.includes(x)) {
              this.colonnesDispoClients.push(x);
              this.colonnesAfficheesClients.splice(this.colonnesAfficheesClients.indexOf(x), 1);
            }
          }
        )
        this.colonnesSelected = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

        this.colonnesAfficheesClients.forEach(
          x => {
            value += x.prop+" ";
          }
        )

        //console.log(value);
        this.apiService.updateTable("refuge", "id", this.refugeID, "colonnesClients", value).subscribe(
          x => {
            this.getColonnesClients();
          }
        );


      } else {
        this.colonnesSelected.forEach(
          x => {
            if (this.colonnesDispo[index].includes(x)) {
              this.colonnesAffichees[index].push(x);
              this.colonnesDispo[index].splice(this.colonnesDispo[index].indexOf(x), 1);
            } else if (this.colonnesAffichees[index].includes(x)) {
              this.colonnesDispo[index].push(x);
              this.colonnesAffichees[index].splice(this.colonnesAffichees[index].indexOf(x), 1);
            }
          }
        )
        this.colonnesSelected = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];

        this.colonnesAffichees[index].forEach(
          x => {
            value += x.prop+" ";
          }
        )

        //console.log(value);
        this.apiService.updateColonnes("refuge_animaltypes_colonnes", "refuge", this.refugeID+" AND animaltype = "+type, "colonnes", value).subscribe(
          x => {
            this.getColonnes();
          }
        );
      }
      
    }
  }

  onSubmitClause(type: string){
    let newClauses = "";

    switch (type) {
      case 'engagementsAdoptant':
        this.adoptantList.forEach(element => {
          newClauses += element + "/";
        });
    
        newClauses += this.clauseAdoptant + "/";
    
        this.clauseAdoptant = "";
        break;
      case 'engagementsRefuge':
        this.refugeList.forEach(element => {
          newClauses += element + "/";
        });
    
        newClauses += this.clauseRefuge + "/";
    
        this.clauseRefuge = "";
        break;
      case 'engagementsAutres':
        this.autreList.forEach(element => {
          newClauses += element + "/";
        });
    
        newClauses += this.clauseAutre + "/";
    
        this.clauseAutre = "";
        break;
    
      default:
        break;
    }

    

    this.apiService.updateTable("contrat", "refuge", this.refugeID, type, newClauses).subscribe(
      x => this.getContrat()
    );
  }

  onSubmitSite(){
    let newSites = "";

    this.sitesList.forEach(element => {
      newSites += element + " ";
    });

    newSites += this.site + " ";

    this.site = "";

    this.apiService.updateTable("refuge", "id", this.refugeID, "sites", newSites).subscribe(
      x => this.getRefugeID()
    );
  }

  onDeleteClause(index: number, type: string){
    let newClauses = "";

    switch (type) {
      case 'engagementsAdoptant':
        this.adoptantList.splice(index, 1);
        this.adoptantList.forEach(element => {
          newClauses += element + "/";
        });
        break;
      case 'engagementsRefuge':
        this.refugeList.splice(index, 1);
        this.refugeList.forEach(element => {
          newClauses += element + "/";
        });
        break;
      case 'engagementsAutres':
        this.autreList.splice(index, 1);
        this.autreList.forEach(element => {
          newClauses += element + "/";
        });
        break;
    
      default:
        break;
    }

    this.apiService.updateTable("contrat", "refuge", this.refugeID, type, newClauses).subscribe(
      x => this.getContrat()
    );
    
  }

  onDeleteSite(index: number){
    let newSites = "";

    this.sitesList.splice(index, 1);
    this.sitesList.forEach(element => {
      newSites += element + " ";
    });

    this.apiService.updateTable("refuge", "id", this.refugeID, "sites", newSites).subscribe(
      x => this.getRefugeID()
    );
    
  }

  camelize(str: string): string{
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  onClickNew(index: number){

    let nom = this.camelize(this.nomNouvelleColonne);
    let type = this.typeNouvelleColonne;

    this.nomNouvelleColonne = "";
    this.typeNouvelleColonne = "";

    if (index === 0) {
      let ok = true;
      this.colonnesAfficheesClients.forEach(element => {
        if (element.prop === nom) {
          ok = false;
        }
      });
      this.colonnesDispoClients.forEach(element => {
        if (element.prop === nom) {
          ok = false;
        }
      })

      if (ok) {
        console.log("ok");
        this.apiService.alterTable("clients", nom, type).subscribe(
          x => {
            this.getColonnesClients();
          }
        );
      } else {
        window.alert("Cette colonne existe déjà!");
      }

    } else {
      index -=1;
      let ok = true;
      this.colonnesAffichees[index].forEach(element => {
        if (element.prop === nom) {
          ok = false;
        }
      });
      this.colonnesDispo[index].forEach(element => {
        if (element.prop === nom) {
          ok = false;
        }
      })

      if (ok) {
        console.log("ok");
        this.apiService.alterTable("animaux", nom, type).subscribe(
          x => this.getColonnes()
        );
      } else {
        window.alert("Cette colonne existe déjà!");
      }
    }
    
    
  }

  getTemplate(){
    var url: string = encodeURI(this.apiService.PHP_API_SERVER_PARAMS
      +"rapport="+8
      +"&auth="+localStorage.getItem('auth')
      +"&ref="+localStorage.getItem('refuge'));

    window.open(url, "_blank");
  }
  
  xlsUpload(event: any){
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(selectedFile);
    fileReader.onload = (event: any) => {
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: 'binary'});
      workbook.SheetNames.forEach(sheet => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        const text = JSON.stringify(data);
        const json = JSON.parse(text);
        
        let animals: Animal[] = [];
        for (const key in json){
          
          if(json.hasOwnProperty(key)){
            
            const raw = json[key];

            let animal: Animal = new Animal();
            type ObjectKey = keyof typeof animal;
            animal.refuge = this.refugeID;

            for (const keys in raw){

              if(raw.hasOwnProperty(keys)){     
                console.log(keys);
                console.log(raw[keys]);           
                const myVar = keys as ObjectKey;

                if (keys === "animalType") {
                  let type = 0;
                  this.animalTypes.forEach(element => {
                    if (element.type.toUpperCase() === raw[keys].toUpperCase()) {
                      type = element.animaltype;
                    }
                  });
                  animal = Object.assign(animal, { [myVar]: type });
                } else if (keys.slice(0, 4) === "date" || keys.slice(0, 6) === "rappel" || keys === "sterilisationDate") {
                  const date = new Date(raw[keys].toString().slice(-4), +raw[keys].toString().slice(3, 5) - 1, raw[keys].toString().slice(0, 2));
                  animal = Object.assign(animal, { [myVar]: date });
                } else {
                  animal = Object.assign(animal, { [myVar]: raw[keys] });
                }

              }

            }
            console.log("oyeah");
            console.log(animal);
            this.apiService.ajouterNouvelObjet(animal, 'animaux').subscribe(message => {      
              console.log(message);
            });
            
          }
        }
        this._snackBar.open('Animaux ajoutés à la base de données', 'OK');
      })
    }
  }

}
