<div class="row">
<div class="col-md-10">
	<div class="card card-user">

	  <div class="card-body">

<div>
  <!--
<ngx-datatable class="material" [rows]="clients" [columns]="columns"
  	(activate)="onSelect($event)" [rowHeight]="40" [limit]="20" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">

  </ngx-datatable>
-->

  <ngx-datatable  class="material" [rows]="clients"
	(activate)="onSelect($event)"  [limit]="20" [rowHeight]="40" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">

		<ngx-datatable-column *ngFor="let col of columns;" [name]="col.name" [frozenLeft]="col.frozenLeft">
			<ng-template ngx-datatable-header-template>
				{{ col.name }}
			</ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				{{ value | clientsPipe }}
			</ng-template>
		</ngx-datatable-column>

	</ngx-datatable>

</div>

</div>
</div>
</div>

<div class="col-md-2">
	<div class="card card-user">
	  <div class="card-header">
		<h5 class="card-title">Filtrer par</h5>
	  </div>
	  <div class="card-body">

	  <form id="form-fitler">

		  <div class="col-md-12">
			  <div class="form-group">
				<label>Nom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['nom']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Prénom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['prenom']" (keyup)="updateFilter()">
			  </div>

        <div class="form-group">
        <label>Téléphone</label>
        <input type="text" name="filtreTel" class="form-control" [(ngModel)]="filtres['tel']" (keyup)="updateFilter()">
        </div>

			  <div class="form-group">
				<label>Statut</label>

				<select name="filtreStatut" class="form-control"
					[(ngModel)]="filtres['statut']"
					(change)="updateFilter()">

				  <option value=""></option>
				  <option value="adoptant">Adoptant</option>
				  <option value="benevole">Bénévole</option>
				  <option value="donateur">Donateur</option>
				  <option value="surListeNoire">Liste noire</option>
				  <option value="testamentaire">Testamentaire</option>
				  <option value="vip">VIP</option>

				</select>
			  </div>


			  <div class="form-group">
				<label>Date naissance</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateNaissance']" (change)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Code postal</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['idLocalite']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Reg. national</label>
				<input type="text" name="filtreReg" class="form-control" [(ngModel)]="filtres['regNational']" (keyup)="updateFilter()">
			  </div>

        <div class="row">
          <div class="update ml-auto mr-auto">
            <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter()">Reset</button>
          </div>
        </div>

		  </div>

	</form>

</div>
</div>
</div>
