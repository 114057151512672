export class Client {

	id!:number;
	personne!:string;
	nom!:string;
	prenom!:string;
	dateNaissance!:Date;
	adresse!:string;
	idLocalite!:number;
	localite!: string;
	pays!:string;
	regNational!:string;
	tel1!:string;
	tel2!:string;
	email!:string;
	sexe!:number;
	adoptant!:boolean;
	surListeNoire!:boolean;
	listeNoireDate!:Date;
	listeNoireInfo!:string;
	vip!:boolean;
	vipDate!:Date;
	testamentaire!:boolean;
	dateTestamentaire!:Date;
	dateDernierDon!:Date;
	commentaire!:string;
	pasDeCourrier!:boolean;
	mailUniquement!:boolean;
	donateur!:boolean;
	benevole!:string;
  dateDebutBenevole!:Date;
  niveauBenevole!:string;
	niveauAcces!:string;
	mdp!:string;
	login!:string;
	pdfFile!: string;
	refuge!: number;
}
