export class SortieAnimal {

	idSortie!:number;
	Idanimal!:number;
	age!:number;
	Idclient!:number;
	nom!:string;
	prenom!:string;
	typeSortie!:string;
	animalType!:number;
	dateSortie!:Date;
	PAF!:number;
	placeur!:string;
	commentaire!:string;
	localisation!:number;
	refuge!:number;

}