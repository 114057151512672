import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, FormControl, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AuthInterceptor } from './auth.interceptor';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, DateAdapter, NativeDateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule} from "@angular/material/dialog";
import { MatDialog } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { AnimalComponent } from './animal/animal.component';
import { AnimalDetailsComponent } from './animal-details/animal-details.component';
import { MessagesComponent } from './messages/messages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { DonsComponent } from './dons/dons.component';
import { DonDetailsComponent } from './don-details/don-details.component';
import { NouvelleEntreeComponent } from './nouvelle-entree/nouvelle-entree.component';
import { NouvelleSortieComponent } from './nouvelle-sortie/nouvelle-sortie.component';
import { NouveauDonComponent } from './nouveau-don/nouveau-don.component';
import { NouveauClientComponent } from './nouveau-client/nouveau-client.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { LoginComponent } from './login/login.component';
import { LoggingComponent } from './logging/logging.component';
import { RapportsComponent } from './rapports/rapports.component';
import { EntreeSortieComponent } from './entree-sortie/entree-sortie.component';
import { RechercherComponent } from './rechercher/rechercher.component';
import { PersonnalisationComponent } from './personnalisation/personnalisation.component';
import { AnimauxPipe } from './pipes/animaux.pipe';
import { ClientsPipe } from './pipes/clients.pipe';
import { NouveauUserComponent } from './nouveau-user/nouveau-user.component';

export class CustomDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {

    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {

      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date, 12);
    }

    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {

    date = new Date(Date.UTC(
      date.getFullYear(), date.getMonth(), date.getDate(), 12,
      date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });

    const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
    return dtf.format(date).replace(/[\u200e\u200f]/g, '');
  }

}

@NgModule({
  declarations: [
    AppComponent,
    AnimalComponent,
    AnimalDetailsComponent,
    MessagesComponent,
    DashboardComponent,
    ClientsComponent,
    ClientDetailsComponent,
    DonsComponent,
    DonDetailsComponent,
    NouvelleEntreeComponent,
    NouvelleSortieComponent,
    NouveauDonComponent,
    NouveauClientComponent,
    AdminPanelComponent,
    LoginComponent,
    LoggingComponent,
    RapportsComponent,
    EntreeSortieComponent,
    RechercherComponent,
    PersonnalisationComponent,
    AnimauxPipe,
    ClientsPipe,
    NouveauUserComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatSnackBarModule,
  FormsModule,
  NgxDatatableModule,
  BrowserAnimationsModule,
  MatDatepickerModule,
  MatInputModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatProgressBarModule,
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatTabsModule,
  MatListModule,
  MatDialogModule,
  MatProgressSpinnerModule
  ],
  providers: [
  { provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  { provide: DateAdapter, useClass: CustomDateAdapter },
  { provide: LOCALE_ID, useValue: 'fr-FR'},
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
