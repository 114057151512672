
<div class="row">
<div class="col-md-6">
<div class="card card-user"  *ngIf="client">
	  <div class="card-header">
		  
		  <div class="row">
		  <div class="col-md-8">
	  
			<h5 class="card-title">Créer un nouveau client</h5>
			</div>
			<div class="col-md-4">
			<div class="form-group">
					<label>Personne</label>
					<select name="personne" class="form-control" 
					[(ngModel)]="client!.personne">
					
				  <option value="Physique">Physique</option>
				  <option value="Morale">Morale</option>
				  
				</select>
			  </div>
			</div>
		</div>
	</div>
	  <div class="card-body">
		  <form>
		  
		  
		  <div class="row">
			
			<div class="col-md-4 pr-1">		
			  <div class="form-group">
				<label>Nom de famille</label>
				<input type="text" name="Nom" class="form-control" [(ngModel)]="client!.nom">
			  </div>		  
			</div>
			
			<div class="col-md-4 pr-1">
			  <div class="form-group">
				<label>Prénom</label>
				<input type="text" name="Prenom" class="form-control" [(ngModel)]="client!.prenom" >
			  </div>
			</div>
			
				
				<div class="col-md-4">
				
				<div class="form-group">
				<label>Bénévole ?</label>
				
				<select name="benevole" class="form-control" 
					[(ngModel)]="client.benevole" value="Non">
					
				  <option value="Oui">Oui</option>
				  <option value="Non">Non</option>
				  <option value="Inactif">Inactif</option>
				  
				</select>
			  </div>
						
				</div>			

		  </div>
		  
		  <div class="row">
			
			
				<div class="col-md-3">		
				  <div class="form-group">
					<label>Date de naissance</label>
					<input type="text" name="dateNaissance" class="form-control" readonly matInput [matDatepicker]="dateNaissance" [(ngModel)]="client!.dateNaissance"
					>
					
				  </div>		  
				</div>
				<div class="col-md-1 pl-1">	
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>

				<div class="col-md-4">
					<div class="form-group">
						<label>sexe</label>
						
						<select name="sexe" class="form-control" 
							[(ngModel)]="client.sexe">
							
						  <option value="Femme">Femme</option>
						  <option value="Homme">Homme</option>
						</select>
					  </div>
				</div>			
			
			
			<div class="col-md-4">
			  <div class="form-group">
				<label>N° registre national (11 chiffres)</label>
				<input type="text" name="regNational" class="form-control validated" pattern="[0-9]{11}" [(ngModel)]="client!.regNational" >
			  </div>
			</div>
			
		
		  </div>
		  
		  <div class="row">
		  
			<div class="col-md-12">
			  <div class="form-group">
				<label>Rue & numéro</label>
				<input type="text" name="adresse" class="form-control" [(ngModel)]="client!.adresse" >
			  </div>
			</div>
			
		  </div>
		  
		  <div class="row">
			
			<div class="col-md-4">
			  <div class="form-group">
				<label>Code postal</label>
				<input type="text" name="idLocalite" class="form-control" [(ngModel)]="client!.idLocalite">
			  </div>
			</div>


			<div class="col-md-4">
					<div class="form-group">
					<label>Localité</label>
					
						<input type="text" 
								class="form-control"
							   name="localite"
							   
							   [formControl]="localitesControl"
							   [matAutocomplete]="auto1"
							   [(ngModel)]="client!.localite"/>

						<mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" >
						  <mat-option *ngFor="let option of filteredLocalitesOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>
				  
				</div>	
			
			</div>
			
		  </div>
		  
		  
		  <div class="row">
		  
			<div class="col-md-4">
			  <div class="form-group">
				<label>Email</label>
				<input type="text"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="Email" class="form-control validated" [(ngModel)]="client!.email"  >
			  </div>
			</div>
		  
			<div class="col-md-4 px-1">
			  <div class="form-group">
				<label>Téléphone principal</label>
				<input type="text" name="tel1" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel1">
			  </div>
			</div>
			
			<div class="col-md-4">
			  <div class="form-group">
				<label>Téléphone secondaire</label>
				<input type="text" name="tel2" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel2"  >
			  </div>
			</div>
			
		  </div>
		  
		  
		  <div class="row">	  
			
			
			<!--
				<div class="col-md-3">		
				  <div class="form-group">
					<label>Date de dernier don</label>
					<input type="text" name="dateDernierDon" class="form-control" matInput [matDatepicker]="dateDernierDon" [(ngModel)]="client!.dateDernierDon"
					(dateChange)="updateField('clients', 'dateDernierDon')">
					
				  </div>		  
				</div>
				<div class="col-md-1 pl-1">	
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDernierDon" ></mat-datepicker-toggle>
					<mat-datepicker #dateDernierDon></mat-datepicker>
				</div>
			
			-->
			
			<div class="col-md-4">
				
				<div class="form-group">
					<label>Client VIP</label>
					<input type="checkbox" name="croisement" class="form-control" [(ngModel)]="client!.vip" >
				  </div>
			
			</div>
			
		  </div>
		  
		  <!--
		  <div class="row">
		  
			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Liste noire</label>
				<input type="checkbox" name="surListeNoire" class="form-control" [(ngModel)]="client!.surListeNoire" (focusout)="updateField('clients', 'surListeNoire')">
			  </div>
			</div>
			</div>
			
			<div class="row"  *ngIf="client!.surListeNoire">
			
				<div class="col-md-3">		
				  <div class="form-group">
					<label>Date ajout liste noire</label>
					<input type="text" name="listeNoireDate" class="form-control" matInput [matDatepicker]="listeNoireDate" [(ngModel)]="client!.listeNoireDate"
					(dateChange)="updateField('clients', 'listeNoireDate')">
					
				  </div>		  
				</div>
				<div class="col-md-1 pl-1">	
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="listeNoireDate" ></mat-datepicker-toggle>
					<mat-datepicker #listeNoireDate></mat-datepicker>
				</div>
			
			
			<div class="col-md-8">
			  <div class="form-group">
				<label>Commentaire ajout liste noire</label>
				<input type="text" name="listeNoireInfo" class="form-control" [(ngModel)]="client!.listeNoireInfo"  (focusout)="updateField('clients', 'listeNoireInfo')">
			  </div>
			</div>
			
		  
		  </div>
		  -->
		  
		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire client</label>
				<textarea class="form-control textarea" [(ngModel)]="client!.commentaire" name="Commentaire"  ></textarea>
			  </div>
			</div>
		  </div>  
		  
		  <!--
		  <div class="row">
			
			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Testamentaire</label>
				<input type="checkbox" name="testamentaire" [(ngModel)]="client!.testamentaire" class="form-control" (focusout)="updateField('clients', 'testamentaire')">
			  </div>
			</div>
			</div>
			
			<div class="row" *ngIf="client!.testamentaire">
			<div class="col-md-3">		
				  <div class="form-group">
					<label>Date testamentaire</label>
					<input type="text" name="dateTestamentaire" class="form-control" matInput [matDatepicker]="dateTestamentaire" [(ngModel)]="client!.dateTestamentaire"
					(dateChange)="updateField('clients', 'dateTestamentaire')">
					
				  </div>		  
				</div>
				<div class="col-md-1 pl-1">	
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateTestamentaire" ></mat-datepicker-toggle>
					<mat-datepicker #dateTestamentaire></mat-datepicker>
				</div>
		  </div>
		  -->
		  
		  
		  <div class="row">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="creerNouveauClient()">Créer nouveau client</button>
			</div>
		  </div>
		</form>

			</div>