import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';

import { Client } from '../client';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { Don } from '../don';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { ClientDetailsComponent } from '../client-details/client-details.component';


@Component({
  selector: 'app-nouveau-don',
  templateUrl: './nouveau-don.component.html',
  styleUrls: ['./nouveau-don.component.css']
})
export class NouveauDonComponent implements OnInit {

	don: Don = new Don();

	confirmed:boolean = true;
	nomClient: string = "";
	client?: Client;

	clientsOptions: Client[] = [];
	clientsControl = new FormControl();
  filteredClientsOptions?: Observable<Client[]>;
  nonNom = false;
  nonPay = false;
  nonMon = false;

  autreType = '';

  constructor(
    private apiService: ApiService,
	private router: Router,
  public dialog: MatDialog
	) { }

  ngOnInit(): void {
	this.getDropdownOptions();

	this.don.dateDon = new Date();

	this.filteredClientsOptions = this.clientsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClients(value))
    );
  }

  private _filterClients(value: string): Client[] {

    const filterValue = value.toLowerCase();

    return this.clientsOptions.filter(option => (option.nom + ', ' + option.prenom).toLowerCase().indexOf(filterValue) !== -1);
  }

  getDropdownOptions(): void {

	  // Clients
		this.apiService.getClients()
			.subscribe(clients => {

				clients.forEach( (client) => {
						// this.clientsOptions!.push(option.nom+', '+option.prenom+' ('+option.id+')')
						this.clientsOptions!.push(client)
				  });
			})

  }

  setClient(client: Client): void {

  	console.log("uffukd")
		this.client = client
		console.log(client)
    
    this.nonNom = false;

  }

  setPay(): void {
    this.nonPay = false;
  }

  setMon(): void {
    if (this.don.montantDon !== undefined && this.don.montantDon !== null) {
      this.nonMon = false;  
    } else {
      this.nonMon = true;
    }
  }

  ajouterDon(): void {
    console.log(this.don.typeDon)
  	if (this.client === undefined) {
      
      this.nonNom = true;
      window.alert("Client inexistant");

    } else if (this.don.typeDon === undefined) {

      this.nonPay = true;
      window.alert("Moyen de paiement invalide");

    } else if(this.don.montantDon === undefined || this.don.montantDon === null) {

      this.nonMon = true;
      window.alert("Montant du don non spécifié");

    } else {
      this.don.Idclient = this.client!.id;

      if (this.don.typeDon === 'Autre' && this.autreType !== '' && this.autreType !== null) {
        this.don.typeDon = this.autreType;
      }

      console.log(this.don)

      this.apiService.updateTable('clients', 'id', this.don.Idclient, 'dateDernierDon', <any>this.don.dateDon)
        .subscribe();

      this.apiService.updateTable('clients', 'id', this.don.Idclient, 'donateur', <any>1).subscribe();

      this.don.refuge = +localStorage.getItem('refuge')!;

      this.apiService.ajouterNouvelObjet(this.don, 'dons').subscribe(message => {


        this.router.navigateByUrl('/dons');

        const dialogPosition: DialogPosition = {
          left: '15vw'
        };

        const dialogRef = this.dialog.open(ClientDetailsComponent, {
          data: this.don.Idclient,
          height: '80vh',
          width: '80vw',
          position: dialogPosition,
          disableClose: true,
          autoFocus: true
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });

      });
    }

  }


}

