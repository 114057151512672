<div class="row">
	<div class="col-md-12" *ngIf="animalId != 0">

		<div class="card card-user">
		  <div class="card-header">
			<h5 class="card-title">Infos entrée</h5>
		  </div>

		  <div class="card-body">


			<form>
				<div class="row">


				<div class="col-md-3">

						<div class="form-group">
							<label>Type d'entrée</label>

							<select name="entree" class="form-control"
								[(ngModel)]="entree.typeEntree">

							  <option value="Abandon">Abandon</option>
							  <option value="Pension">Pension</option>
							  <option value="Retour">Retour</option>
							  <option value="Saisie">Saisie</option>
							  <option value="Sauvage">Sauvage</option>
							  <option value="Trouvé">Trouvé</option>

							</select>
						</div>

					</div>

						<div class="col-md-3">
						  <div class="form-group">
							<label>PAF (€)</label>
							<input type="text" class="form-control" [(ngModel)]="entree.PAF" name="Nom"/>
						  </div>
						</div>

							<div class="col-md-3">
							  <div class="form-group">
								<label>Date d'entrée</label>
								<input type="text" name="dateEntree" class="form-control" readonly matInput [matDatepicker]="dateEntree" [(ngModel)]="entree.dateEntree">

							  </div>
							</div>
							<div class="col-md-1 pl-1">
							<label class="datePickerIconLabel">_______________</label>
							<mat-datepicker-toggle matSuffix [for]="dateEntree" ></mat-datepicker-toggle>
								<mat-datepicker #dateEntree></mat-datepicker>
							</div>

						 <div class="col-md-2">
							<div class="update ml-auto mr-auto">
						  <button type="submit" class="btn btn-primary btn-round" (click)="creerNouvelEntree()">Créer</button>
							</div>
					  </div>

				</div>
				<div class="row" *ngIf="entree.typeEntree == 'Abandon' || entree.typeEntree == 'Saisie' || entree.typeEntree == 'Trouvé' || entree.typeEntree == 'Retour'">

						<div class="col-md-3" >
						  <div class="form-group">
							<label>Numéro de cession</label>
							<input type="text" class="form-control" [(ngModel)]="entree.numeroCession" name="numeroCession"/>
						  </div>
						</div>

				</div>

				<div class="row" *ngIf="this.entree.typeEntree == 'Pension'">


						<div class="col-md-2" >
						  <div class="form-group">
							<label>Durée prévue (jours)</label>
							<input type="text" class="form-control" [(ngModel)]="entree.durePrevue" name="durePrevue"/>
						  </div>
						</div>


				</div>


			  </form>

			</div>

		</div>
	</div>
</div>




<div class="row">

<div class="col-md-12">
	<div class="card card-user" *ngIf="clientId != 0">
	  <div class="card-header">
		<h5 class="card-title">Identification de l'animal</h5>
	  </div>

	  <div class="card-body">


	  <div class="row mb-5">

      <div class="col-md-4 pc-1">

        <div class="d-flex justify-content-center">

          <button  mat-raised-button (click)="choixAnimal('existant')">Animal existant <mat-icon aria-hidden="false" fontSet="material-icons-outlined">pets</mat-icon></button>

        </div>

      </div>

      <div class="col-md-4 pc-1">

        <div class="d-flex justify-content-center" *ngIf="animalEditerButton">

          <button  mat-raised-button (click)="onEditAnimal()">Editer animal <mat-icon aria-hidden="false" fontSet="material-icons-outlined">person_add_alt</mat-icon></button>

        </div>

      </div>


      <div class="col-md-4">

        <div class="d-flex justify-content-center">

          <button  mat-raised-button (click)="choixAnimal('nouveau')">Nouvel animal <mat-icon aria-hidden="false" fontSet="material-icons-outlined">pets</mat-icon></button>

        </div>

      </div>

    </div>

		<div class="row" *ngIf="animalExistant">

			<div class="col-md-12">

			<h6>Trouver un animal existant</h6>

			</div>

			<div class="col-md-9">

				<ngx-datatable class="material" [rowHeight]="45" [rows]="animaux" [columns]="columnsAnimaux"
				[selectionType]="selectionType"
  				[sorts]="[{prop: 'id', dir: 'desc'}]"
				(activate)="onSelect($event, 'animal')"  [limit]="10" [footerHeight]="30" [scrollbarH]="true"
        #tableAnimaux>
			  	</ngx-datatable>

			</div>

			<div class="col-md-3">

				<div class="col-md-12">

				<div class="form-group">
				<label>Type</label>

				<select name="sortie" class="form-control"
					[(ngModel)]="filtresAnimaux['animalType']" (change)="updateFilterAnimaux()">

					<option *ngFor="let type of types;" [value]="type.animaltype">{{type.type}}</option>

				</select>
			  </div>

				<div class="form-group">
				<label>Nom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtresAnimaux['nom']" (keyup)="updateFilterAnimaux()">
			  </div>


			  <div class="form-group">
				<label>Passeport</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtresAnimaux['numeroPasseport']" (keyup)="updateFilterAnimaux()">
			  </div>



			  <div class="form-group">
				<label>Identification</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtresAnimaux['identificationAnimal']" (keyup)="updateFilterAnimaux()">
			  </div>


			  <div class="row">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter('animal')">Reset</button>
			</div>
		  </div>

			  </div>

			</div>

		</div>


		<div class="row" *ngIf="nouvelAnimal">

			<div class="col-md-12">

			<h6>Nouvel animal</h6>

			<form>


					<div class="row">
				<div class="col-md-4">

					<div class="form-group">
					<label>Type</label>

					<select name="type" class="form-control"
						[(ngModel)]="animal!.animalType" required>

						<option *ngFor="let type of types;" [value]="type.animaltype">{{type.type}}</option>

					</select>
				  </div>


				</div>

					<div class="col-md-4">
					  <div class="form-group">
						<label>Nom</label>
						<input type="text" class="form-control" required [(ngModel)]="animal!.nom" name="Nom"/>
					  </div>
					</div>


				<div class="col-md-4">

				<div class="form-group">
						<label>sexe</label>

						<select name="sexe" class="form-control"
							[(ngModel)]="animal!.sexe">

						  <option value="Femelle">Femelle</option>
						  <option value="Male">Male</option>

						</select>
					</div>


				</div>

				</div>

				<div class="row">


					<div class="col-md-4">

						<div class="form-group">
							<label>Site</label>

							<select name="site" class="form-control"
								[(ngModel)]="animal!.site">

							  <option value="Chastre">Chastre</option>
							  <option value="Perwez">Perwez</option>

							</select>
						</div>

					</div>

							<div class="col-md-3">
							  <div class="form-group">
								<label>Date de naissance</label>
								<input type="text" name="dateNaissance" class="form-control" readonly matInput [matDatepicker]="dateNaissance" [(ngModel)]="animal!.dateNaissance">
							  </div>
							</div>
							<div class="col-md-1 pl-1">
							<label class="datePickerIconLabel">_____</label>
							<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
								<mat-datepicker #dateNaissance></mat-datepicker>
							</div>
					</div>


					 <div class="row">
						<div class="update ml-auto mr-auto">
					  <button type="submit" class="btn btn-primary btn-round" (click)="creerNouvelAnimal()">Créer nouvel animal</button>
						</div>
				  </div>


		  </form>


			</div>

		</div>



	  </div>
	</div>
	</div>

<div class="col-md-12">
	<div class="card card-user">

	  <div class="card-header">
		  <h5 class="card-title">Identification du client</h5>
	  </div>

	  <div class="card-body">

	    <div class="row mb-5">

		    <div class="col-md-4 pc-1">

          <div class="d-flex justify-content-center">

            <button  mat-raised-button (click)="choixClient('existant')">Client existant <mat-icon aria-hidden="false" fontSet="material-icons-outlined">person</mat-icon></button>

          </div>

	      </div>

	      <div class="col-md-4 pc-1">

          <div class="d-flex justify-content-center" *ngIf="clientEditerButton">

            <button  mat-raised-button (click)="onEdit()">Editer client <mat-icon aria-hidden="false" fontSet="material-icons-outlined">person_add_alt</mat-icon></button>

          </div>

	      </div>

        <div class="col-md-4">

          <div class="d-flex justify-content-center">

            <button  mat-raised-button (click)="choixClient('nouveau')">Nouveau client <mat-icon aria-hidden="false" fontSet="material-icons-outlined">person_add_alt</mat-icon></button>

          </div>

        </div>

	    </div>

		  <div class="row" *ngIf="clientExistant">

        <div class="col-md-12">

          <h6>Trouver un client existant</h6>

        </div>

        <div class="col-md-9">
          <ngx-datatable class="material" [rowHeight]="45" [rows]="clients" [columns]="columns"
            [selectionType]="selectionType"
            (activate)="onSelect($event, 'client')" [limit]="10" [footerHeight]="30" [scrollbarH]="true"
            #tableClients>

            </ngx-datatable>

        </div>

			  <div class="col-md-3">

				  <div class="col-md-12">
            <div class="form-group">
            <label>Nom</label>
            <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['nom']" (keyup)="updateFilterClients()">
            </div>

            <div class="form-group">
            <label>Prénom</label>
            <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['prenom']" (keyup)="updateFilterClients()">
            </div>

            <div class="form-group">
            <label>Téléphone</label>
            <input type="text" name="filtreTel" class="form-control" [(ngModel)]="filtres['tel']" (keyup)="updateFilterClients()">
            </div>

            <div class="form-group">
              <label>Statut</label>

              <select name="filtreStatut" class="form-control"
                [(ngModel)]="filtres['statut']"
                (change)="updateFilterClients()">

                <option value=""></option>
                <option value="adoptant">Adoptant</option>
                <option value="benevole">Bénévole</option>
                <option value="donateur">Donateur</option>
                <option value="surListeNoire">Liste noire</option>
                <option value="testamentaire">Testamentaire</option>
                <option value="vip">VIP</option>

              </select>
            </div>

            <div class="form-group">
            <label>Date naissance</label>
            <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateNaissance']" (change)="updateFilterClients()">
            </div>

            <div class="form-group">
            <label>Code postal</label>
            <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['idLocalite']" (keyup)="updateFilterClients()">
            </div>

            <div class="form-group">
            <label>Reg. national</label>
            <input type="text" name="filtreReg" class="form-control" [(ngModel)]="filtres['regNational']" (keyup)="updateFilterClients()">
            </div>

            <div class="row">
              <div class="update ml-auto mr-auto">
                <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter('client')">Reset</button>
              </div>
            </div>

          </div>

			  </div>

      </div>



		<!-- -----   EDITER  CLIENT ------ -->




		<div class="row" *ngIf="clientEditer">

			<div class="col-md-3">
			</div>

			<div class="col-md-6">
			<h6 *ngIf="clientEditer">Editer client</h6>



		  <form>
		  <div class="row">

			<div class="col-md-4 pr-1">
			  <div class="form-group">
				<label>Nom de famille</label>
				<input type="text" name="nom" class="form-control" [(ngModel)]="client!.nom" (focusout)="updateField('clients', 'nom')">
			  </div>
			</div>

			<div class="col-md-4 pr-1">
			  <div class="form-group">
				<label>Prénom</label>
				<input type="text" name="Prenom" class="form-control" [(ngModel)]="client!.prenom" (focusout)="updateField('clients', 'prenom')" >
			  </div>
			</div>


				<div class="col-md-4">
					<div class="form-group">
						<label>Bénévole ?</label>

						<select name="benevole" class="form-control"
							[(ngModel)]="client!.benevole" (change)="updateField('clients', 'benevole')" >

						  <option value="Oui">Oui</option>
						  <option value="Non">Non</option>
						  <option value="Inactif">Inactif</option>

						</select>
					  </div>
				</div>



		  </div>

		  <div class="row">


				<div class="col-md-3">
				  <div class="form-group">
					<label>Date de naissance</label>
					<input type="text" name="dateNaissance" class="form-control" readonly matInput [matDatepicker]="dateNaissance" [(ngModel)]="client!.dateNaissance" (dateChange)="updateField('clients', 'dateNaissance')"
					>

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>

				<div class="col-md-4">
					<div class="form-group">
						<label>sexe</label>

						<select name="sortie" class="form-control"
							[(ngModel)]="client!.sexe" (change)="updateField('clients', 'sexe')" >

						  <option value="Femme">Femme</option>
						  <option value="Homme">Homme</option>

						</select>
					</div>
				</div>


			<div class="col-md-4">
			  <div class="form-group">
				<label>N° registre national (11 chiffres)</label>
				<input type="text" name="regNational" pattern="[0-9]{11}" class="form-control validated" [(ngModel)]="client!.regNational" (focusout)="updateField('clients', 'regNational')" >
			  </div>
			</div>


		  </div>

		  <div class="row">

			<div class="col-md-12">
			  <div class="form-group">
				<label>Rue & numéro</label>
				<input type="text" name="adresse" class="form-control" [(ngModel)]="client!.adresse" (focusout)="updateField('clients', 'adresse')">
			  </div>
			</div>

		  </div>

		  <div class="row">

			<div class="col-md-4">
			  <div class="form-group">
				<label>Code postal</label>
				<input type="text" name="idLocalite" class="form-control" [(ngModel)]="client!.idLocalite" (focusout)="updateField('clients', 'idLocalite')">
			  </div>
			</div>


			<div class="col-md-4">
					<div class="form-group">
					<label>Localité</label>

						<input type="text" pattern=".*\S.*" required
								class="form-control"
							   name="localite"

							   [formControl]="localitesControl"
							   [matAutocomplete]="auto1"
							   [(ngModel)]="client!.localite"/>

						<mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="updateField('clients', 'localite')">
						  <mat-option *ngFor="let option of filteredLocalitesOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>

			</div>

		  </div>


		  <div class="row">

			<div class="col-md-4">
			  <div class="form-group">
				<label>Email</label>
				<input type="text"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="Email" class="form-control validated" [(ngModel)]="client!.email" (focusout)="updateField('clients', 'email')">
			  </div>
			</div>

			<div class="col-md-4 px-1">
			  <div class="form-group">
				<label>Téléphone principal</label>
				<input type="text" name="tel1" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel1"  (focusout)="updateField('clients', 'tel1')">
			  </div>
			</div>

			<div class="col-md-4">
			  <div class="form-group">
				<label>Téléphone secondaire</label>
				<input type="text" name="tel2" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel2"  (focusout)="updateField('clients', 'tel2')">
			  </div>
			</div>

		  </div>


		  <div class="row">


			<!--
				<div class="col-md-3">
				  <div class="form-group">
					<label>Date de dernier don</label>
					<input type="text" name="dateDernierDon" class="form-control" matInput [matDatepicker]="dateDernierDon" [(ngModel)]="client!.dateDernierDon"
					(dateChange)="updateField('clients', 'dateDernierDon')">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDernierDon" ></mat-datepicker-toggle>
					<mat-datepicker #dateDernierDon></mat-datepicker>
				</div>

			-->

			<div class="col-md-4">

				<div class="form-group">
					<label>Client VIP</label>
					<input type="checkbox" name="croisement" class="form-control" [(ngModel)]="client!.vip" (focusout)="updateField('clients', 'vip')">
				  </div>

			</div>


		  </div>

		  <!--
		  <div class="row">

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Liste noire</label>
				<input type="checkbox" name="surListeNoire" class="form-control" [(ngModel)]="client!.surListeNoire" (focusout)="updateField('clients', 'surListeNoire')">
			  </div>
			</div>
			</div>

			<div class="row"  *ngIf="client!.surListeNoire">

				<div class="col-md-3">
				  <div class="form-group">
					<label>Date ajout liste noire</label>
					<input type="text" name="listeNoireDate" class="form-control" matInput [matDatepicker]="listeNoireDate" [(ngModel)]="client!.listeNoireDate"
					(dateChange)="updateField('clients', 'listeNoireDate')">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="listeNoireDate" ></mat-datepicker-toggle>
					<mat-datepicker #listeNoireDate></mat-datepicker>
				</div>


			<div class="col-md-8">
			  <div class="form-group">
				<label>Commentaire ajout liste noire</label>
				<input type="text" name="listeNoireInfo" class="form-control" [(ngModel)]="client!.listeNoireInfo"  (focusout)="updateField('clients', 'listeNoireInfo')">
			  </div>
			</div>


		  </div>
		  -->

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire client</label>
				<textarea class="form-control textarea" [(ngModel)]="client!.commentaire" name="Commentaire"  (focusout)="updateField('clients', 'commentaire')"></textarea>
			  </div>
			</div>
		  </div>

		  <!--
		  <div class="row">

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Testamentaire</label>
				<input type="checkbox" name="testamentaire" [(ngModel)]="client!.testamentaire" class="form-control" (focusout)="updateField('clients', 'testamentaire')">
			  </div>
			</div>
			</div>

			<div class="row" *ngIf="client!.testamentaire">
			<div class="col-md-3">
				  <div class="form-group">
					<label>Date testamentaire</label>
					<input type="text" name="dateTestamentaire" class="form-control" matInput [matDatepicker]="dateTestamentaire" [(ngModel)]="client!.dateTestamentaire"
					(dateChange)="updateField('clients', 'dateTestamentaire')">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateTestamentaire" ></mat-datepicker-toggle>
					<mat-datepicker #dateTestamentaire></mat-datepicker>
				</div>
		  </div>
		  -->

		  <div class="row">
			<div class="update ml-auto mr-auto">

			  <button *ngIf="nouveauClient" type="submit" class="btn btn-primary btn-round" (click)="creerNouveauClient()">Créer nouveau client</button>

			</div>
		  </div>


		</form>

			</div>

			<div class="col-md-3">
			</div>

		</div>



			<!-- -----   NOUVEAU CLIENT ------ -->



		<div class="row" *ngIf="nouveauClient">


			<div class="col-md-12">

			<h6 *ngIf="nouveauClient">Nouveau client</h6>
			<h6 *ngIf="clientEditer">Editer client</h6>



		  <form>
		  <div class="row">

			<div class="col-md-4 pr-1">
			  <div class="form-group">
				<label>Nom de famille</label>
				<input type="text" name="Nom" class="form-control" [(ngModel)]="client!.nom">
			  </div>
			</div>

			<div class="col-md-4 pr-1">
			  <div class="form-group">
				<label>Prénom</label>
				<input type="text" name="Prenom" class="form-control" [(ngModel)]="client!.prenom">
			  </div>
			</div>


				<div class="col-md-4">
					<div class="form-group">
						<label>Bénévole ?</label>

						<select name="benevole" class="form-control"
							[(ngModel)]="client!.benevole">

						  <option value="Oui">Oui</option>
						  <option value="Non">Non</option>
						  <option value="Inactif">Inactif</option>

						</select>
					  </div>
				</div>



		  </div>

		  <div class="row">


				<div class="col-md-3">
				  <div class="form-group">
					<label>Date de naissance</label>
					<input type="text" name="dateNaissance" class="form-control" readonly matInput [matDatepicker]="dateNaissance" [(ngModel)]="client!.dateNaissance">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>

				<div class="col-md-4">
					<div class="form-group">
						<label>sexe</label>

						<select name="sortie" class="form-control"
							[(ngModel)]="client!.sexe">

						  <option value="Femme">Femme</option>
						  <option value="Homme">Homme</option>

						</select>
					</div>
				</div>


			<div class="col-md-4">
			  <div class="form-group">
				<label>N° registre national (11 chiffres)</label>
				<input type="text" name="regNational" pattern="[0-9]{11}" class="form-control validated" [(ngModel)]="client!.regNational" >
			  </div>
			</div>


		  </div>

		  <div class="row">

			<div class="col-md-12">
			  <div class="form-group">
				<label>Rue & numéro</label>
				<input type="text" name="adresse" class="form-control" [(ngModel)]="client!.adresse" >
			  </div>
			</div>

		  </div>

		  <div class="row">

			<div class="col-md-4">
			  <div class="form-group">
				<label>Code postal</label>
				<input type="text" name="idLocalite" class="form-control" [(ngModel)]="client!.idLocalite" (focusout)="getLocalite()">
			  </div>
			</div>


			<div class="col-md-4">
					<div class="form-group">
					<label>Localité</label>

						<input type="text"
								class="form-control"
							   name="localite"

							   [formControl]="localitesControl"
							   [matAutocomplete]="auto1"
							   [(ngModel)]="client!.localite"/>

						<mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="updateField('clients', 'localite')">
						  <mat-option *ngFor="let option of filteredLocalitesOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>

			</div>

		  </div>


		  <div class="row">

			<div class="col-md-4">
			  <div class="form-group">
				<label>Email</label>
				<input type="text"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" name="Email" class="form-control validated" [(ngModel)]="client!.email">
			  </div>
			</div>

			<div class="col-md-4 px-1">
			  <div class="form-group">
				<label>Téléphone principal</label>
				<input type="text" name="tel1" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel1">
			  </div>
			</div>

			<div class="col-md-4">
			  <div class="form-group">
				<label>Téléphone secondaire</label>
				<input type="text" name="tel2" pattern="[0-9]{10}" class="form-control" [(ngModel)]="client!.tel2">
			  </div>
			</div>

		  </div>


		  <div class="row">


			<!--
				<div class="col-md-3">
				  <div class="form-group">
					<label>Date de dernier don</label>
					<input type="text" name="dateDernierDon" class="form-control" matInput [matDatepicker]="dateDernierDon" [(ngModel)]="client!.dateDernierDon"
					(dateChange)="updateField('clients', 'dateDernierDon')">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateDernierDon" ></mat-datepicker-toggle>
					<mat-datepicker #dateDernierDon></mat-datepicker>
				</div>

			-->

			<div class="col-md-4">

				<div class="form-group">
					<label>Client VIP</label>
					<input type="checkbox" name="croisement" class="form-control" [(ngModel)]="client!.vip">
				  </div>

			</div>


		  </div>

		  <!--
		  <div class="row">

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Liste noire</label>
				<input type="checkbox" name="surListeNoire" class="form-control" [(ngModel)]="client!.surListeNoire" (focusout)="updateField('clients', 'surListeNoire')">
			  </div>
			</div>
			</div>

			<div class="row"  *ngIf="client!.surListeNoire">

				<div class="col-md-3">
				  <div class="form-group">
					<label>Date ajout liste noire</label>
					<input type="text" name="listeNoireDate" class="form-control" matInput [matDatepicker]="listeNoireDate" [(ngModel)]="client!.listeNoireDate"
					(dateChange)="updateField('clients', 'listeNoireDate')">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="listeNoireDate" ></mat-datepicker-toggle>
					<mat-datepicker #listeNoireDate></mat-datepicker>
				</div>


			<div class="col-md-8">
			  <div class="form-group">
				<label>Commentaire ajout liste noire</label>
				<input type="text" name="listeNoireInfo" class="form-control" [(ngModel)]="client!.listeNoireInfo"  (focusout)="updateField('clients', 'listeNoireInfo')">
			  </div>
			</div>


		  </div>
		  -->

		  <div class="row">
			<div class="col-md-12">
			  <div class="form-group">
				<label>Commentaire client</label>
				<textarea class="form-control textarea" [(ngModel)]="client!.commentaire" name="Commentaire"></textarea>
			  </div>
			</div>
		  </div>

		  <!--
		  <div class="row">

			<div class="col-md-2 pr-1">
			  <div class="form-group">
				<label>Testamentaire</label>
				<input type="checkbox" name="testamentaire" [(ngModel)]="client!.testamentaire" class="form-control" (focusout)="updateField('clients', 'testamentaire')">
			  </div>
			</div>
			</div>

			<div class="row" *ngIf="client!.testamentaire">
			<div class="col-md-3">
				  <div class="form-group">
					<label>Date testamentaire</label>
					<input type="text" name="dateTestamentaire" class="form-control" matInput [matDatepicker]="dateTestamentaire" [(ngModel)]="client!.dateTestamentaire"
					(dateChange)="updateField('clients', 'dateTestamentaire')">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">_____</label>
				<mat-datepicker-toggle matSuffix [for]="dateTestamentaire" ></mat-datepicker-toggle>
					<mat-datepicker #dateTestamentaire></mat-datepicker>
				</div>
		  </div>
		  -->

		  <div class="row">
			<div class="update ml-auto mr-auto">

			  <button *ngIf="nouveauClient" type="submit" class="btn btn-primary btn-round" (click)="creerNouveauClient()">Créer nouveau client</button>

			</div>
		  </div>


		</form>

			</div>

		</div>




			</div>
		</div>
	</div>
</div>
