<div class="wrapper">

	<div class="sidebar" data-color="white" data-active-color="danger">
		  <div class="logo">
			<div *ngIf="connected; else elseBlock">
				<a class="simple-text logo-normal" style="display: flex; justify-content: center; align-items: center;" >
					<img [src]="imageToShow"
					alt="Place image title"
					*ngIf="!isImageLoading; else noImageFound" style="max-height: 24px; border-radius: 6px; max-width: 24px;">
					<ng-template #noImageFound>
						<img src="" alt="Aucune image">
					</ng-template>	
					<span style="font-size: 15px; margin-left: 10px;">{{nomRefuge}}</span>
				</a>
			</div>
			<ng-template #elseBlock>
				<a class="simple-text logo-normal" style="display: flex; justify-content: center; align-items: center;">
					<mat-icon style="cursor: pointer;">pets</mat-icon><span style="font-size: 15px; margin-left: 10px;">Votre Refuge</span>
				</a>
			</ng-template>
	  		
  </div>
  <div class="sidebar-wrapper example" style="padding-bottom: 0; overflow-y: scroll;">

	<ul class="nav">

	  <!-- <li [ngClass]="{'active' : currentPage=='dashboard'}" *ngIf="connected"> -->
		<!-- <a  routerLink="/dashboard"> -->
		  <!-- <i class="nc-icon nc-shop"></i> -->
		  <!-- <p>Accueil</p> -->
		<!-- </a> -->
	  <!-- </li> -->
		<li [ngClass]="{'active' : currentPage=='personnalisation'}" *ngIf="connected">
			<a routerLink="personnalisation" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">dashboard_customize</mat-icon>
				<p style="font-size: smaller;">Personnalisation</p>
			</a>
		</li>
		<li [ngClass]="{'active' : currentPage=='rechercher'}" *ngIf="connected">
			<a routerLink="rechercher" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">search</mat-icon>
				<p>Recherche</p>
			</a>
		</li>
		<!--
			<li [ngClass]="{'active' : currentPage=='chats'}" *ngIf="connected">
				<a routerLink="chats" style=" display: flex; align-items: center; justify-content: start;">
					<mat-icon style="margin-right: 15px;">list</mat-icon>
					<p>Chats</p>
				</a>
			</li>
			<li [ngClass]="{'active' : currentPage=='chiens'}" *ngIf="connected">
				<a routerLink="chiens" style=" display: flex; align-items: center; justify-content: start;">
					<mat-icon style="margin-right: 15px;">list</mat-icon>
					<p>Chiens</p>
				</a>
			</li>
			<li [ngClass]="{'active' : currentPage=='animals'}" *ngIf="connected">
				<a routerLink="/animaux" style=" display: flex; align-items: center; justify-content: start;">
					<mat-icon style="margin-right: 15px;">list</mat-icon>
					<p>Autres</p>
				</a>
			</li>
		-->
		
		<div *ngFor="let type of animalTypes">
			<li [ngClass]="{'active' : currentPage=='animal'}" *ngIf="connected">
				<a [routerLink]="['/animaux', type.animaltype]" style=" display: flex; align-items: center; justify-content: start;">
					<mat-icon style="margin-right: 15px;">list</mat-icon>
					<p>{{type.type}}</p>
				</a>
			</li>
		</div>
		

		<li [ngClass]="{'active' : currentPage=='clients'}" *ngIf="connected">
			<a routerLink="/clients" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">person</mat-icon>
				<p>Clients</p>
			</a>
		</li>
		<li [ngClass]="{'active' : currentPage=='dons'}" *ngIf="connected">
			<a routerLink="/dons" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">monetization_on</mat-icon>
				<p>Dons</p>
			</a>
		</li>
		<li class="active-pro"  [ngClass]="{'active' : currentPage=='rapports'}" *ngIf="connected">
			<a routerLink="/rapports" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">content_paste</mat-icon>
				<p>Rapports</p>
			</a>
		</li>
		<li class="active-pro"  [ngClass]="{'active' : currentPage=='admin'}" *ngIf="connected && (niveauAcces == 'Direction' || niveauAcces == 'Administratif')">
			<a routerLink="/admin" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">vpn_key</mat-icon>
				<p>Accès</p>
			</a>
		</li>
		<li class="active-pro"  [ngClass]="{'active' : currentPage=='logging'}" *ngIf="connected && (niveauAcces == 'Direction' || niveauAcces == 'Administratif')">
			<a routerLink="/logging" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">timeline</mat-icon>
				<p>Activités</p>
			</a>
		</li>
		<li class="active-pro"  [ngClass]="{'active' : currentPage=='login'}" *ngIf="!connected">
			<a routerLink="/connexion" style=" display: flex; align-items: center; justify-content: start;">
				<mat-icon style="margin-right: 15px;">person</mat-icon>
				<p>Connexion</p>
			</a>
		</li>
	</ul>


  </div>

	</div>

    <div class="main-panel">

<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">

	<div class="container-fluid">

		<div class="navbar-wrapper">

			<a class="navbar-brand" href="javascript:;">{{title}}</a>

		</div>

	  <div class="collapse navbar-collapse justify-content-end" id="navigation">



	  <!-- <div class="row">	 -->

		<div class="col-md-2"></div>
		<div class="col-md-4"><div style="text-align: right;font-weight: bold">{{comptage}}</div></div>

			<div class="col-sd-3" *ngIf="connected">
			  <button  mat-raised-button [matMenuTriggerFor]="menu">
			  	<mat-icon aria-hidden="false" aria-label="clients"  fontSet="material-icons-outlined">groups</mat-icon>
			  	<span style="color:white">_</span>Clients
			  </button>
				<mat-menu #menu="matMenu">
				  <button mat-menu-item (click)="goTo('nouveau-client');">Nouveau client</button>
				  <button mat-menu-item (click)="goTo('nouveau-don');" *ngIf="niveauAcces == 'Direction' || niveauAcces == 'Administratif'">Nouveau don</button>
				</mat-menu>
			</div>


			<div class="col-sd-3" *ngIf="connected">
		  <button  mat-raised-button [matMenuTriggerFor]="menu2"><mat-icon aria-hidden="false" aria-label="entrée / sorties"  fontSet="material-icons-outlined">cached</mat-icon><span style="color:white">_</span>Entrées / Sorties</button>
			<mat-menu #menu2="matMenu">
			  <button mat-menu-item (click)="goTo('nouvelle-entree');">Nouvelle entrée</button>
			  <button mat-menu-item (click)="goTo('nouvelle-sortie');">Nouvelle sortie</button>
			</mat-menu>
		  </div>



		  <div class="col-sd-3" *ngIf="connected">
		  <button  mat-raised-button [matMenuTriggerFor]="menu2"><mat-icon aria-hidden="false" aria-label="entrée / sorties"  fontSet="material-icons-outlined">account_circle</mat-icon><span style="color:white">_</span>{{login}}</button>
			<mat-menu #menu2="matMenu">
			  <button mat-menu-item (click)="logOut();">Déconnexion</button>
			</mat-menu>
		  </div>



		<!--
		<form>
		  <div class="input-group no-border">
			<input type="text" value="" class="form-control" placeholder="Search...">
			<div class="input-group-append">
			  <div class="input-group-text">
				<i class="nc-icon nc-zoom-split"></i>
			  </div>
			</div>
		  </div>
		</form>
		-->

	  <!-- </div> -->
	  </div>
	</div>
	</nav>


      <div class="content">

		<router-outlet></router-outlet>

      </div>

      <footer class="footer footer-black  footer-white ">
        <div class="container-fluid">

			<div class="row">
	<nav class="footer-nav">

	</nav>
	<div class="credits ml-auto">
	  <span class="copyright">
		version 30/09 11:30 © 2021, plateforme développée par Umengo avec le soutien de Sans Collier
	  </span>
	</div>
</div>

        </div>
      </footer>
    </div>
  </div>
