import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({name: 'clientsPipe'})
export class ClientsPipe implements PipeTransform {

  private _datePipe: DatePipe = new DatePipe('fr-FR');

  transform(value: any): string | null {
    if (typeof value === "string") {
      if (value == "Oui" || value == '1') {
        return '✔️'
      } else if (value == "Non" || value == '0') {
        return ''
      } else if (((value.substring(0,2) === '20') || (value.substring(0,2) === '19')) && (value.length === 10) && (value.substring(2,3) !== '/')) {
        return this._datePipe.transform(value, 'dd/MM/yyyy');
      } else {
        return value;
      }
    } else {
      if (value == 1) {
        return '✔️'
      } else if (value == 0) {
        return ''
      } else {
        return value
      }
    }


    
  }
}

/*booleanPipe () {
    return {transform: (value: boolean) => (Boolean(Number(value)) == true) ? '✔️' : ''};
  }

benevolePipe () {
  return {transform: (value: string) => (value == "Oui") ? '✔️' : ''};
}*/