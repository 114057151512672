import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { ApiService } from '../api.service';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { Logging } from '../logging';

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css']
})
export class LoggingComponent implements OnInit {

  loggings: Logging[] = [];
  fullData: Logging[] = [];
  filtres: Dictionary<string> = {};

	// format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');

  connected: boolean = false;

  columns = [
			{ prop: 'login', name: "Identifiant", width: 150 },
			{ prop: 'date', name: "Date", width: 170, pipe: this.datePipe() },
			{ prop: 'log', name: "Activité", width: 500}
			];

  constructor(
	private apiService: ApiService,
	private router: Router,
	private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

	this.getLogging();
	this.resetFilter();
	console.log(new Date());

	if (localStorage.getItem('auth')) {

		if (localStorage.getItem('level') == 'Direction' ||
			localStorage.getItem('level') == 'Administratif') {

				this.connected = true;

		}

	}

  }

  getLogging(): void {
	this.apiService.getLogging()
	  .subscribe(loggings => {
		  this.fullData = loggings;
		  this.loggings = loggings;
	  })
  }

  resetFilter(): void {
	this.filtres['login'] = '';
	this.loggings = this.fullData;

  }

  datePipe () {
	return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
	}

  updateFilter() {

	var temp = this.fullData;

	  for (let key in this.filtres) {

		let value = this.filtres[key];

		if (value != '') {

			var val = value;
			var key_ = key;

			if (key == 'dateNaissance1' || key == 'dateNaissance2') {
				key_ = 'dateNaissance';
			} else if (key != 'statutLogging') {
				val = value.toLowerCase();
			}

			temp = temp.filter(function (d: Logging) {

			if (key_ == 'statutLogging' || (d as any)[key_] != undefined) {

				  if (key_ == 'dateNaissance') {

					  var dPipe = new DatePipe('fr-FR');

					  var valObj = new Date(Date.parse(val));
					  valObj.setHours(0,0,0,0);

					  var valTab = new Date(Date.parse((d as any)[key_]));
					  valTab.setHours(0,0,0,0);

					  // var valObj = dPipe.transform((d as any)[key_], 'dd/MM/yyyy');
					  return (key == 'dateNaissance1') ? valTab! >= valObj! : valTab! <= valObj!;


					} else if (key_ == 'statutLogging') {

						return Boolean(Number((d as any)[val]));

					} else {

						return (d as any)[key_].toLowerCase().indexOf(val) !== -1 || !val;
					 }

			  }

			  return false;

			});

		}

	  }

    this.loggings = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

}
