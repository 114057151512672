import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from  'rxjs';

import { Animal } from './animal';
import { Client } from './client';
import { Don } from './don';
import { SortieAnimal } from './sortieanimale';
import { EntreeAnimal } from './entreeanimal';
import { ChienAnimal } from './chienanimal';
import { ChienFull } from './chienfull';
import { ChatAnimal } from './chatanimal';
import { ChatFull } from './chatfull';
import { Adoption } from './adoption';
import { RaceChien } from './racechien';
import { RaceChat } from './racechat';
import { RobeChien } from './robechien';
import { RobeChat } from './robechat';
import { Ville } from './ville';
import { DbUpdate } from './dbupdate';
import { AdoptionClient } from './adoptionclient';
import { Login } from './login';
import { Logging } from './logging';
import { Pays } from './pays';
import { CompteAnimaux } from './compteanimaux';
import { nbChats } from './nbChats';
import { nbChiens } from './nbChiens';
import { Refuge } from './refuge';
import { AnimalType } from './animalType';
import { nbAnimaux } from './nbAnimaux';
import { Races } from './races';
import { Robes } from './robes';
import { Contrat } from './contrat';

interface Dictionary<T> {
  [Key: string]: T;
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  // PHP_API_SERVER_REF = "/backend-sans-collier/";
  // PHP_API_SERVER_REF = "http://localhost:8010/proxy/"; // DEV local
  // PHP_API_SERVER_REF = "/api"; // DEV ngrok
  PHP_API_SERVER_REF = "https://shelteradmin.org/"; // PROD NEWEST
  // PHP_API_SERVER_REF = "https://sanscollier.me/"; // PROD

  PHP_API_SERVER_LOGIN = this.PHP_API_SERVER_REF+"login.php";
  PHP_API_SERVER = this.PHP_API_SERVER_REF+"api.php";
  PHP_API_SERVER_PARAMS = this.PHP_API_SERVER+"?";

  

  constructor(private httpClient: HttpClient) {}

  activityLogging(logging_: string): Observable<number> {
    const refuge = +localStorage.getItem('refuge')!;
	  var logging = new Logging();
	  logging.login = localStorage.getItem('login')!;
	  logging.date = new Date();
	  logging.log = logging_;

	  return this.httpClient.post<number>(this.PHP_API_SERVER_PARAMS+"table=logging&ref="+refuge, logging);

  }

  uploadPhoto(file: any, id: number, table: string, url?: string): Observable<any>{
    let lien = this.PHP_API_SERVER_PARAMS+"uploadPhoto="+id+"&type="+table;
    if (url) {
      lien = lien + "&url=" + url;
    }
    return this.httpClient.post<any>(lien, {'image' : file});
  }

  uploadPdf(file: any, id: number, name: string, url?: string, contrat?: boolean): Observable<any>{
    let lien = this.PHP_API_SERVER_PARAMS+"uploadPdf="+id+"&name="+name;
    if (url) {
      lien = lien + "&url=" + url;
    }
    if (contrat) {
      lien = lien + "&contrat=1"; 
    }

    return this.httpClient.post<any>(lien, file);
  }

  deletePdf(id: number, name: string, url: string, contrat?: boolean): Observable<any>{
    let lien = this.PHP_API_SERVER_PARAMS+"deletePdf="+id+"&url="+url+"&name="+name;
    if (contrat) {
      lien = lien + "&contrat=1";
    }
    return this.httpClient.get<any>(lien);
  }

  login(log: Login): Observable<Login> {
	  return this.httpClient.post<Login>(this.PHP_API_SERVER_LOGIN, log);
  }

  deleteObjet(table: string, idName: string, id: number): Observable<number> {

    this.activityLogging("Suppression dans la table <strong>" + table + "</strong> (" + idName + ":  " + id + ")").subscribe();
    return this.httpClient.get<number>(this.PHP_API_SERVER_PARAMS+"table="+table+"&idName="+idName+"&delete="+id);

  }

  deleteObjetString(table: string, idName: string, id: string): Observable<number> {

    this.activityLogging("Suppression dans la table <strong>" + table + "</strong> (" + idName + ":  " + id + ")").subscribe();
    return this.httpClient.get<number>(this.PHP_API_SERVER_PARAMS+"table="+table+"&idName="+idName+"&delete="+id);

  }

  ajouterNouvelObjet(obj: any, table: string): Observable<number> {

	  this.activityLogging("Ajout dans la table <strong>" + table + "</strong>").subscribe();
	  return this.httpClient.post<number>(this.PHP_API_SERVER_PARAMS+"table="+table, obj);

	}

	updateObjet(obj: any, idName: string, id: number, table: string): Observable<number> {

	  this.activityLogging("Mise à jour dans la table <strong>" + table + "</strong> (" + idName + ":  " + id + ")").subscribe();
	  return this.httpClient.post<number>(this.PHP_API_SERVER_PARAMS+"tableUpdate="+table+"&idNameWhere="+idName+"&id="+id, obj);

	}  

  updateTable(table: string, idName: string, id: number, field: string, value: any): Observable<any> {

		var update = new DbUpdate();
		update.table = table;
		update.idName = idName;
		update.id = id;
		update.field = field;
		update.value = (typeof value === "boolean") ? String(Number(value)) : value;

    this.activityLogging("Mise à jour dans la table <strong>" + table + "</strong>, champ <strong>" + field + "</strong>  (" + idName + ":  " + id + ")").subscribe();
    return this.httpClient.post<any>(this.PHP_API_SERVER, update);

  }

  updateColonnes(table: string, idName: string, id: string, field: string, value: any): Observable<any> {

		var update : {
      table: string;
      idName: string;
      id: string;
      field: string;
      value: any;
    } = {
      table: table,
      idName: idName,
      id: id,
      field: field,
      value: value
    } ;

	this.activityLogging("Mise à jour dans la table <strong>" + table + "</strong>, champ <strong>" + field + "</strong>  (" + idName + ":  " + id + ")").subscribe();
	return this.httpClient.post<any>(this.PHP_API_SERVER, update);

  }

  alterTable(table: string, idName: string, type: string): Observable<any> {
    return this.httpClient.get<any>(this.PHP_API_SERVER_PARAMS+"alterTable="+table+"&idName="+idName+"&type="+type);
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.httpClient.get(this.PHP_API_SERVER_PARAMS+'img='+imageUrl, { responseType: 'blob' });
  }

  getPdf(pdfUrl: string): Observable<Blob> {
    return this.httpClient.get(this.PHP_API_SERVER_PARAMS+'pdf='+pdfUrl, { responseType: 'blob' });
  }

  getCompteAnimaux(): Observable<CompteAnimaux[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<CompteAnimaux[]>(this.PHP_API_SERVER_PARAMS+"compte_refugefa=1&ref="+refuge);
  }

  getCompteChats(filtres: Dictionary<string>): Observable<nbChats[]>{
    const refuge = +localStorage.getItem('refuge')!;
    let params = new HttpParams();
    let filtre : boolean = false;

    for (let key in filtres) {
      let value = filtres[key];

      if (value != '') {
        if (filtre == false) {
          filtre = true;
          params = params.append('filtre', 1);
        }
        params = params.append(key, value);
      }

    }
    return this.httpClient.get<nbChats[]>(this.PHP_API_SERVER_PARAMS+"compte_chats&ref="+refuge, {params:params});
  }

  getCompteChiens(filtres: Dictionary<string>): Observable<nbChiens[]>{
    const refuge = +localStorage.getItem('refuge')!;
    let params = new HttpParams();
    let filtre : boolean = false;

    for (let key in filtres) {
      let value = filtres[key];

      if (value != '') {
        if (filtre == false) {
          filtre = true;
          params = params.append('filtre', 1);
        }
        params = params.append(key, value);
      }

    }
    return this.httpClient.get<nbChiens[]>(this.PHP_API_SERVER_PARAMS+"compte_chiens&ref="+refuge, {params:params});
  }

  getCompteAnimauxListe(filtres: Dictionary<string>): Observable<nbAnimaux[]>{
    const refuge = +localStorage.getItem('refuge')!;
    let params = new HttpParams();
    let filtre : boolean = false;

    for (let key in filtres) {
      let value = filtres[key];

      if (value != '') {
        if (filtre == false) {
          filtre = true;
          params = params.append('filtre', 1);
        }
        params = params.append(key, value);
      }

    }
    return this.httpClient.get<nbAnimaux[]>(this.PHP_API_SERVER_PARAMS+"compte_animaux&ref="+refuge, {params:params});
  }

  getRapports(type: number, animals: string): Observable<string>{
    const refuge = +localStorage.getItem('refuge')!;
	  return this.httpClient.post<string>(this.PHP_API_SERVER_PARAMS+"rapport="+type+"&animals="+animals+"ref="+refuge, {responseType:'text/csv' as 'text'});
  }

  getAnimaux(): Observable<Animal[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<Animal[]>(`${this.PHP_API_SERVER_PARAMS}t=animaux&ref=${refuge}`);
  }

  getAnimaux60(page: number, filtres: Dictionary<string>): Observable<Animal[]>{
    const refuge = +localStorage.getItem('refuge')!;
    let params = new HttpParams();
    let filtre : boolean = false;

    for (let key in filtres) {
      let value = filtres[key];

      if (value != '') {
        if (filtre == false) {
          filtre = true;
          params = params.append('filtre', 1);
        }
        params = params.append(key, value);
      }

    }

    return this.httpClient.get<Animal[]>(`${this.PHP_API_SERVER_PARAMS}t=animaux&l=${page}&ref=${refuge}`, {params:params});
  }

  getAnimalID(id: number): Observable<Animal[]>{
    return this.httpClient.get<Animal[]>(`${this.PHP_API_SERVER_PARAMS}t=animaux&id=` + id);
  }

  getRefugeID(id: number): Observable<Refuge[]>{
    return this.httpClient.get<Refuge[]>(`${this.PHP_API_SERVER_PARAMS}t=refuge&id=` + id);
  }

  getRefugeAnimalTypes(id: number): Observable<AnimalType[]>{
    return this.httpClient.get<AnimalType[]>(`${this.PHP_API_SERVER_PARAMS}t=animaltypes&j=refuge_animaltypes_colonnes&IdNameJoinTableTo=animaltype&IdNameJoinTableFrom=id&value=animaltype, type&ref=${id}`)
  }

  getAllRefuges(): Observable<Refuge[]>{
    return this.httpClient.get<Refuge[]>(`${this.PHP_API_SERVER_PARAMS}t=refuge&value=id, nom`);
  }

  getAnimalTypes(): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.PHP_API_SERVER_PARAMS}t=animaltypes`)
  }

  getColonnes(refuge: number, animalType: number): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.PHP_API_SERVER_PARAMS}t=refuge_animaltypes_colonnes&id=${animalType}&idNameWhere=animaltype&value=colonnes&ref=${refuge}`)
  }

  getColonnesClients(refuge: number): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.PHP_API_SERVER_PARAMS}t=refuge&id=${refuge}&value=colonnesClients`)
  }

  getAllColonnes(): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.PHP_API_SERVER_PARAMS}allColonnes=1`)
  }

  getAllColonnesClients(): Observable<any[]>{
    return this.httpClient.get<any[]>(`${this.PHP_API_SERVER_PARAMS}allColonnes=0`)
  }

  getChats(): Observable<ChatAnimal[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<ChatAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=chats&j=animaux&ref=${refuge}`);
  }

  getChatsFull(): Observable<ChatFull[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<ChatFull[]>(`${this.PHP_API_SERVER_PARAMS}listechats&ref=${refuge}`);
  }

  getChats60(page: number, filtres: Dictionary<string>): Observable<ChatFull[]>{
    const refuge = +localStorage.getItem('refuge')!;
    let params = new HttpParams();
    let filtre : boolean = false;

    for (let key in filtres) {
      let value = filtres[key];

      if (value != '') {
        if (filtre == false) {
          filtre = true;
          params = params.append('filtre', 1);
        }
        params = params.append(key, value);
      }

    }

    return this.httpClient.get<ChatFull[]>(`${this.PHP_API_SERVER_PARAMS}t=chats&l=${page}&ref=${refuge}`, {params:params});
  }

  getChatID(id: number): Observable<ChatAnimal[]>{
    return this.httpClient.get<ChatAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=chats&j=animaux&id=` + id);
  }

  getRacesChats(): Observable<RaceChat[]>{
    return this.httpClient.get<RaceChat[]>(`${this.PHP_API_SERVER_PARAMS}t=racechat`);
  }

  getRobesChats(): Observable<RobeChat[]>{
    return this.httpClient.get<RobeChat[]>(`${this.PHP_API_SERVER_PARAMS}t=robechat`);
  }

  getRaces(): Observable<Races[]>{
    return this.httpClient.get<Races[]>(`${this.PHP_API_SERVER_PARAMS}t=races`);
  }

  getRobes(): Observable<Robes[]>{
    return this.httpClient.get<Robes[]>(`${this.PHP_API_SERVER_PARAMS}t=robes`);
  }

  getChiens(): Observable<ChienAnimal[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<ChienAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=chiens&j=animaux&ref=${refuge}`);
  }

  getChiensFull(): Observable<ChienFull[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<ChienFull[]>(`${this.PHP_API_SERVER_PARAMS}listechiens=1&ref=${refuge}`);
  }

  getChiens60(page: number, filtres: Dictionary<string>): Observable<ChienFull[]>{
    const refuge = +localStorage.getItem('refuge')!;
    let params = new HttpParams();
    let filtre : boolean = false;

    for (let key in filtres) {
      let value = filtres[key];

      if (value != '') {
        if (filtre == false) {
          filtre = true;
          params = params.append('filtre', 1);
        }
        params = params.append(key, value);
      }

    }

    return this.httpClient.get<ChienFull[]>(`${this.PHP_API_SERVER_PARAMS}t=chiens&l=${page}&ref=${refuge}`, {params:params});
  }

  getChienID(id: number): Observable<ChienAnimal[]>{
    return this.httpClient.get<ChienAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=chiens&j=animaux&id=` + id);
  }

  getRacesChiens(): Observable<RaceChien[]>{
    return this.httpClient.get<RaceChien[]>(`${this.PHP_API_SERVER_PARAMS}t=racechien`);
  }

  getRobesChiens(): Observable<RobeChien[]>{
    return this.httpClient.get<RobeChien[]>(`${this.PHP_API_SERVER_PARAMS}t=robechien`);
  }

  getEntreesAnimal(id: number): Observable<EntreeAnimal[]>{
    return this.httpClient.get<EntreeAnimal[]>(`${this.PHP_API_SERVER_PARAMS}entreeanimal=${id}`);
  }

  getSortiesAnimal(id: number): Observable<SortieAnimal[]>{
    return this.httpClient.get<SortieAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=sortieanimal&idNameWhere=Idanimal&j=clients&IdNameJoinTableFrom=Idclient&id=` + id);
  }

  getSortieID(id: number): Observable<SortieAnimal[]>{
    return this.httpClient.get<SortieAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=sortieanimal&idNameWhere=idSortie&id=` + id);
  }

  getAdoptionIDSortie(id: number): Observable<Adoption[]>{
    return this.httpClient.get<Adoption[]>(`${this.PHP_API_SERVER_PARAMS}t=sortieadoption&id=` + id);
  }

  getAdoptionIDAnimal(id: number): Observable<Adoption[]>{
    return this.httpClient.get<Adoption[]>(`${this.PHP_API_SERVER_PARAMS}t=sortieadoption&idNameWhere=Idanimal&id=` + id);
  }

  getClientsAcces(): Observable<Client[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<Client[]>(`${this.PHP_API_SERVER_PARAMS}t=clients&ref=${refuge}&where=niveauAcces IS NOT NULL`);
  }

  getClients(): Observable<Client[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<Client[]>(`${this.PHP_API_SERVER_PARAMS}t=clients&ref=${refuge}`);
  }

  getClientID(id: number): Observable<Client[]>{
    return this.httpClient.get<Client[]>(`${this.PHP_API_SERVER_PARAMS}t=clients&id=` + id);
  }

  getBenevoles(): Observable<Client[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<Client[]>(`${this.PHP_API_SERVER_PARAMS}t=clients&ref=${refuge}&id=Oui&idNameWhere=benevole`);
  }

  getEntreesClient(id: number): Observable<EntreeAnimal[]>{
    return this.httpClient.get<EntreeAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=entreeanimal&idNameWhere=Idclient&j=animaux&IdNameJoinTableFrom=Idanimal&id=` + id);
  }

  getLastEntree(id: number): Observable<EntreeAnimal[]>{
    return this.httpClient.get<EntreeAnimal[]>(`${this.PHP_API_SERVER_PARAMS}derniere_entree=${id}`);
  }

  getSortiesClient(id: number): Observable<SortieAnimal[]>{
    return this.httpClient.get<SortieAnimal[]>(`${this.PHP_API_SERVER_PARAMS}t=sortieanimal&idNameWhere=Idclient&j=animaux&IdNameJoinTableFrom=Idanimal&id=` + id);
  }

  getContrat(id: number): Observable<Contrat[]>{
    return this.httpClient.get<Contrat[]>(`${this.PHP_API_SERVER_PARAMS}t=contrat&idNameWhere=refuge&id=` + id)
  } 

  getDons(): Observable<Don[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<Don[]>(`${this.PHP_API_SERVER_PARAMS}t=dons&ref=${refuge}&j=clients&IdNameJoinTableFrom=Idclient`);
  }

  getDonID(id: number): Observable<Don[]>{
    return this.httpClient.get<Don[]>(`${this.PHP_API_SERVER_PARAMS}t=dons&j=clients&IdNameJoinTableFrom=Idclient&id=` + id);
  }

  getDonClient(id: number): Observable<Don[]>{
    return this.httpClient.get<Don[]>(`${this.PHP_API_SERVER_PARAMS}t=dons&idNameWhere=Idclient&id=` + id);
  }

  getLocalites(): Observable<Ville[]>{
    return this.httpClient.get<Ville[]>(`${this.PHP_API_SERVER_PARAMS}t=villes`);
  }

  getLocaliteID(codepostal: number): Observable<Ville[]>{
    return this.httpClient.get<Ville[]>(`${this.PHP_API_SERVER_PARAMS}t=villes&idNameWhere=codePostal&id=`+codepostal);
  }

  getLogging(): Observable<Logging[]>{
    const refuge = +localStorage.getItem('refuge')!;
    return this.httpClient.get<Logging[]>(`${this.PHP_API_SERVER_PARAMS}t=logging&ref=${refuge}`);
  }

  getPays(): Observable<Pays[]>{
    return this.httpClient.get<Pays[]>(`${this.PHP_API_SERVER_PARAMS}t=pays`);
  }

}
