import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Don } from '../don';
import { ApiService } from '../api.service';
import { ClientDetailsComponent } from '../client-details/client-details.component';
import { DialogPosition, MatDialog } from '@angular/material/dialog';

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
  selector: 'app-dons',
  templateUrl: './dons.component.html',
  styleUrls: ['./dons.component.css']
})
export class DonsComponent implements OnInit {

  dons: Don[] = [];
  fullData: Don[] = [];
  filtres: Dictionary<string> = {};
  niveauAcces: string = "";

  totalDonsStart: Date = new Date(new Date().getFullYear(), 0, 1);
  totalDonsEnd: Date = new Date();
  totalDonsMontant:number = 0;

  // format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');

  columns = [
			{ prop: 'dateDon',  	name: 'Date', pipe: this.datePipe(), width: 100},
			{ prop: 'typeDon', name: "Type de don", width: 100  },
			{ prop: 'montantDon', name: "Montant", pipe: this.montantPipe(), width: 70},
			{ prop: 'nom',  	name: 'Nom donateur', width: 120 , pipe: this.censorPipe()},
			{ prop: 'prenom',  	name: 'Prénom', width: 100 },
			{ prop: 'sexe',  	name: 'sexe', width: 70 },
			{ prop: 'tel1',  	name: 'Tel 1', width: 120 },
			{ prop: 'email',  	name: 'Email', width: 170 },
			{ prop: 'adresse',  	name: 'Adresse', width: 200 },
			{ prop: 'idLocalite',  	name: 'Code postal', width: 85 },
			{ prop: 'vip',  	name: 'VIP', pipe: this.booleanPipe(), width: 50 },
			{ prop: 'testamentaire',  	name: 'Testamentaire', pipe: this.booleanPipe(), width: 60 },
			];

  constructor(private apiService: ApiService, private router: Router, public dialog: MatDialog) {

  }

  ngOnInit(): void {

		this.getDons()
		this.filtres['nom'] = '';
		this.filtres['prenom'] = '';
		this.filtres['idLocalite'] = '';
		this.filtres['montantDon1'] = '';
		this.filtres['montantDon2'] = '';
		this.filtres['dateDon1'] = '';
		this.filtres['dateDon2'] = '';
		this.filtres['typeDon'] = '';

		this.niveauAcces = localStorage.getItem('level')!;
		// console.log(this.niveauAcces);

  }

  totalDons(){

	  this.totalDonsMontant = 0;

	  this.fullData.forEach( (don) => {

			  var d1 = new Date(don.dateDon)

			  if (d1 >= this.totalDonsStart && d1 <= this.totalDonsEnd) {
				  //console.log(don.montantDon);
				  this.totalDonsMontant = Number(this.totalDonsMontant) + Number(don.montantDon);

			  }
	  });

  }

  booleanPipe () {
	return {transform: (value: boolean) => (Boolean(Number(value)) == true) ? 'Oui' : 'Non'};
	}

  montantPipe () {
	  return {transform: (value: number) => (this.niveauAcces == 'Direction' || this.niveauAcces == 'Administratif') ? value : '-'};
  }

  datePipe () {
	return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
  }

  censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  getDons(): void {
	this.apiService.getDons()
	  .subscribe(dons => {
		  this.fullData = dons;
		  this.dons = dons;

		  this.totalDons();
	  })
  }

  onSelect(event: any): void{
	if (event['type'] == 'click') {

    const dialogPosition: DialogPosition = {
      left: '15vw'
    };

    const dialogRef = this.dialog.open(ClientDetailsComponent, {
      data: event['row']['id'],
      height: '80vh',
      width: '80vw',
      position: dialogPosition,
      disableClose: true,
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.apiService.getDons()
      .subscribe(
        dons => {
          this.fullData = dons;
          this.totalDons();
        },
        err => console.log(err),
        () => this.updateFilter()
      )

    });

		//this.router.navigate(['/details-client/'+ event['row']['Idclient']]);
	}

  }

  resetFilter(): void {
		this.filtres['nom'] = '';
		this.filtres['prenom'] = '';
		this.filtres['montantDon1'] = '';
		this.filtres['montantDon2'] = '';
		this.filtres['dateDon1'] = '';
		this.filtres['dateDon2'] = '';
		this.filtres['typeDon'] = '';
		this.dons = this.fullData;

  }

  updateFilter() {

	var temp = this.fullData;

	  for (let key in this.filtres) {

		let value = this.filtres[key];

		if (value != '') {

			var val:any;
			var key_ = key;

			if (key == 'dateDon1' || key == 'dateDon2') {
				val = value;
				key_ = 'dateDon';
			} else if (key == 'montantDon1' || key == 'montantDon2') {
				key_ = 'montantDon';
				val = +value;
			} else {
				val = value.toLowerCase();
			}

			temp = temp.filter(function (d: Don) {

			if ((d as any)[key_] != undefined) {

				  if (key_ == 'dateDon') {

					  let splt = val.split('/')
					  var valObj = new Date(+splt[2], +splt[1]-1, +splt[0]);
					  valObj.setHours(0,0,0,0);

					  var valTab = new Date((d as any)[key_]);
					  valTab.setHours(0,0,0,0);

					  //console.log(valObj);
					  //console.log(valTab);

					  return (key == 'dateDon1') ? valTab! > valObj! : valTab! < valObj!;


					} else if (key_ == 'montantDon') {

					  console.log(val);
					  console.log((d as any)[key_]);

					  return (key == 'montantDon1') ? val <= (d as any)[key_] : val >= (d as any)[key_];


					} else {

						return (d as any)[key_].toLowerCase().indexOf(val) !== -1 || !val;
					 }

			  }

			  return false;

			});

		}

	  }

    this.dons = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

}
