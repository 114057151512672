import { Component, OnInit, Inject } from '@angular/core';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';

import { ApiService } from '../api.service';

import {MAT_DIALOG_DATA, MatDialogRef, DialogPosition, MatDialog} from "@angular/material/dialog";
import { EntreeAnimal } from '../entreeanimal';
import { SortieAnimal } from '../sortieanimale';
import { ClientDetailsComponent } from '../client-details/client-details.component';
import { AnimalDetailsComponent } from '../animal-details/animal-details.component';

@Component({
  selector: 'app-entree-sortie',
  templateUrl: './entree-sortie.component.html',
  styleUrls: ['./entree-sortie.component.css']
})
export class EntreeSortieComponent implements OnInit {

	entree?: EntreeAnimal;
	sortie?: SortieAnimal;
	isEntree: boolean = true;
	routerSubscription: any;

	deleteConfirm:string = 'basic';

	constructor(
		private apiService: ApiService,
		private router: Router,
		private dialogRef: MatDialogRef<EntreeSortieComponent>,
    private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) data: any) {

		if (data.typeEntree) {

			this.entree = data
			this.isEntree = true

			console.log(this.entree)

		} else {


			this.sortie = data
			this.isEntree = false

			console.log(this.sortie)

		}

	}

	ngOnInit() {

		this.router.events
		 .subscribe(() => {
		   this.dialogRef.close();
		 });

	}

	clickName(type: number, id: number) {
    const dialogPosition: DialogPosition = {
      left: '15vw'
    };

    switch (type) {
      case 0:

        const dialogRefClient = this.dialog.open(ClientDetailsComponent, {
          data: id,
          height: '80vh',
          width: '80vw',
          position: dialogPosition,
          disableClose: true,
          autoFocus: true
        });

        dialogRefClient.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });

        break;

      default:

        const dialogRefAnimal = this.dialog.open(AnimalDetailsComponent, {
          data: id,
          height: '80vh',
          width: '80vw',
          position: dialogPosition,
          disableClose: true,
          autoFocus: true
        });

        dialogRefAnimal.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
        
        break;
    }

		//this.router.navigate(["/details-"+type+"/"+id]);
		this.dialogRef.close();

	}

	close() {
		this.dialogRef.close();
	}

	delete(table: string, idName: string, id: number): void {

		if (this.deleteConfirm == 'warn') {

					this.apiService.deleteObjet(table, idName, id).subscribe(message => {
						this.dialogRef.close();
					})

			} else {
					this.deleteConfirm = 'warn'
					console.log(this.deleteConfirm)
			}

	}



}
