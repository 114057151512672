import { Component, OnInit } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { ChatAnimal } from '../chatanimal';
import { ChienAnimal } from '../chienanimal';
import { Client } from '../client';
import { ClientDetailsComponent } from '../client-details/client-details.component';
import { Animal } from '../animal';
import { AnimalDetailsComponent } from '../animal-details/animal-details.component';
import { Router } from '@angular/router';
import { AnimalType } from '../animalType';

interface Dictionary<T> {
  [Key: string]: T;
}

@Component({
  selector: 'app-rechercher',
  templateUrl: './rechercher.component.html',
  styleUrls: ['./rechercher.component.css']
})
export class RechercherComponent implements OnInit {

  //ANIMAUX
  animaux: Animal[] = [];
  fullDataAnimaux: Animal[] = [];
  filtresAnimaux: Dictionary<string> = {};

  //CLIENTS
  clients: Client[] = [];
	fullDataClients: Client[] = [];
	filtresClients: Dictionary<string> = {};
  
  types: AnimalType[] = [];

  columnsClients = [
    { prop: 'nom', name: "Nom", width: 250  },
    { prop: 'prenom', name: "Prénom", width: 250},
    { prop: 'regNational',  	name: 'Registre National', width: 250 },
    { prop: 'tel1',	name: 'Tel 1', width: 250 },
    { prop: 'tel2',	name: 'Tel 2', width: 250 }
  ];

  columnsAnimaux = [
    { prop: 'nom', name: "Nom", width: 250  },
    { prop: 'identificationAnimal', name: "Identifiant", width: 250 }
    //{ prop: 'numeroPasseport',  	name: 'Passeport', width: 150  },
    //{ prop: 'identificationAnimal',  	name: 'Identification', width: 150  }
    ];

  selection: string = "";

  constructor(private apiService: ApiService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    if (!localStorage.getItem('auth')) {
      this.router.navigateByUrl('/connexion');
    }

    this.apiService.getRefugeAnimalTypes(+localStorage.getItem('refuge')!).subscribe(
      x => {
        this.types = x;
      }
    );
  }

  censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  getClients(): void {
    this.apiService.getClients()
    .subscribe(clients => {
      this.fullDataClients = clients;
      this.clients = clients;
    })
  }

  getAnimaux(): void {
    this.apiService.getAnimaux()
    .subscribe(animaux => {
      this.fullDataAnimaux = animaux;
      this.animaux = animaux;
    })
  }

  updateSelection(selected: string) {
    this.selection = selected;

    switch (selected) {
      case 'Clients':
          this.getClients();
        break;

      case 'Animaux':
          this.getAnimaux();
        break;

      default:
        break;
    }
  }

  onSelect(event: any): void{

    const dialogPosition: DialogPosition = {
      left: '15vw'
    };

    if (event['type'] == 'click') {

      switch (this.selection) {

        case 'Clients':

          const dialogRefClients = this.dialog.open(ClientDetailsComponent, {
            data: event['row']['id'],
            height: '80vh',
            width: '80vw',
            position: dialogPosition,
            disableClose: true,
            autoFocus: true
          });

          dialogRefClients.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            this.apiService.getClients()
            .subscribe(
              clients => {
                this.fullDataClients = clients;
              },
              err => console.log(err),
              () => this.updateFilterClients()
            )
          });

          break;

        case 'Animaux':
          const dialogRefAnimaux = this.dialog.open(AnimalDetailsComponent, {
            data: event['row']['id'],
            height: '80vh',
            width: '80vw',
            position: dialogPosition,
            disableClose: true,
            autoFocus: true
          });

          dialogRefAnimaux.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            this.apiService.getAnimaux()
            .subscribe(
              animals => {
                this.fullDataAnimaux = animals;
                this.animaux = animals;
              },
              err => console.log(err),
              () => this.updateFilterAnimaux()
            )
          });
          break;

        default:
          break;
      }
    }

		/*if (event['type'] == 'click') {
			this.router.navigate(['/details-client/'+ event['row']['id']]);
		}*/

  }


  resetFilter(type: string): void {

	  if (type == 'client') {

      for (let key in this.filtresClients) {
        this.filtresClients[key] = "";
      }
      this.clients = this.fullDataClients;

	  } else if (type == 'animaux') {

      for (let key in this.filtresAnimaux) {
        this.filtresAnimaux[key] = "";
      }
      this.animaux = this.fullDataAnimaux;

	  }

  }

  updateFilterClients() {

    /*var temp = this.fullData;

      for (let key in this.filtres) {

      let value = this.filtres[key];

      if (value != '') {

        const val = (key == 'dateNaissance') ? value : value.toLowerCase();

        temp = temp.filter(function (d: Client) {

          if ((d as any)[key] != undefined) {

            if (key == 'dateNaissance') {

              var dPipe = new DatePipe('fr-FR');
              var valTab = dPipe.transform(val, 'dd/MM/yyyy')
              var valObj = dPipe.transform((d as any)[key], 'dd/MM/yyyy')

              console.log(valTab);
              console.log(valObj);

              return valTab == valObj;

            } else {

              return (d as any)[key].toLowerCase().indexOf(val) !== -1 || !val;

            }

          }

          return false;

        });

      }

      }

      this.clients = temp;
      */

    var temp = this.fullDataClients;

    for (let key in this.filtresClients) {

    let value = this.filtresClients[key];

    if (value != '') {

      const val = (key == 'dateNaissance' || key == 'statut') ? value : value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      temp = temp.filter(function (d: Client) {
        if (key == 'regNational' && d.regNational) {
          let tempReg = "";

          tempReg = d.regNational.substring(0, value.length);

          return (tempReg == value)
        }

        if (key == 'tel' && (d.tel1 || d.tel2)) {
          let tempTel1 = "";
          let tempTel2 = "";

          if (d.tel1) {
            tempTel1 = d.tel1.replace(/[&\/\\#,+()$~%.' ":*?<>{}]/g, '');
            tempTel1 = tempTel1.substring(0, value.length);
          }

          if (d.tel2) {
            tempTel2 = d.tel2.replace(/[&\/\\#,+()$~%.' ":*?<>{}]/g, '');
            tempTel2 = tempTel2.substring(0, value.length);
          }

          return (tempTel1 == value) || (tempTel2 == value);
        }

        if (key == 'statut' || (d as any)[key] != undefined) {

          if (key == 'dateNaissance') {

            let splt = val.split('/')
            var valObj = new Date(+splt[2], +splt[1]-1, +splt[0]);
            valObj.setHours(0,0,0,0);

            var valTab = new Date((d as any)[key]);
            valTab.setHours(0,0,0,0);

            //console.log(valObj);
            //console.log(valTab);
            //console.log(valTab.getTime() == valObj.getTime())

            return valTab.getTime() == valObj.getTime();

          } else if (key == 'statut') {

            console.log((d as any)[val]);
            if ((d as any)[val] == "Oui") {
              return true;
            } else {
              return Boolean(Number((d as any)[val]));
            }

          } else {

            return (d as any)[key].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(val) !== -1 || !val;
            }

        }

        return false;

      });

    }

    }

    this.clients = temp;


      // Whenever the filter changes, always go back to the first page
      // this.table.offset = 0;
  }

  updateFilterAnimaux() {

    var temp = this.fullDataAnimaux;

      for (let key in this.filtresAnimaux) {

        let value = this.filtresAnimaux[key];

        if (value !== '') {

          var val = value;
          var key_ = key;


          temp = temp.filter(function (d: Animal) {

            if ((key_ == 'auRefuge')) {
              return (d as any)[key_] == 1 || !val;
            }
            else {
              if ((d as any)[key_] != undefined) {
                val = val.toLowerCase();
                return (d as any)[key_].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(val) !== -1 || !val;
              }
            }

            return false;

          });

        }

      }

      this.animaux = temp;
      // Whenever the filter changes, always go back to the first page
      // this.table.offset = 0;
    }

  checkIfSpecialCharacters(num: string): boolean {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    return format.test(num);
  }
}
