<div class="row">
<div class="col-md-10">

<div class="row">
<div class="col-md-12">
	<div class="card card-user">

	  <div class="card-body">

			<div>
			  <ngx-datatable  class="material" [rows]="dons" [columns]="columns"
			  (activate)="onSelect($event)" [limit]="15" [headerHeight]="50"  [footerHeight]="50" [rowHeight]="40"  [scrollbarH]="true"
			  [sorts]="[{prop: 'dateDon', dir: 'desc'}]">

			  </ngx-datatable>
			</div>

		</div>
	</div>
	</div>
	</div>


	<div class="row">
<div class="col-md-7">

	<div class="card card-user" *ngIf="(niveauAcces == 'Direction' || niveauAcces == 'Administratif')">
	  <div class="card-header">
		<h5 class="card-title">Total dons sur période</h5>
	  </div>
	  <div class="card-body">

			<div class="row">
				<div class="col-md-3">
				  <div class="form-group">
					<label>De date</label>
					<input type="text" name="totalDonsStart2" class="form-control" matInput [matDatepicker]="totalDonsStart2" [(ngModel)]="totalDonsStart"
					(dateChange)="totalDons()">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">________</label>
				<mat-datepicker-toggle matSuffix [for]="totalDonsStart2" ></mat-datepicker-toggle>
					<mat-datepicker #totalDonsStart2></mat-datepicker>
				</div>

				<div class="col-md-3">
				  <div class="form-group">
					<label>A date</label>
					<input type="text" name="totalDonsEnd2" class="form-control" matInput [matDatepicker]="totalDonsEnd2" [(ngModel)]="totalDonsEnd"
					(dateChange)="totalDons()">

				  </div>
				</div>
				<div class="col-md-1 pl-1">
				<label class="datePickerIconLabel">________</label>
				<mat-datepicker-toggle matSuffix [for]="totalDonsEnd2" ></mat-datepicker-toggle>
					<mat-datepicker #totalDonsEnd2></mat-datepicker>
				</div>

				<div class="col-md-4">
				  <div class="form-group">
					<label>Montant</label>
					<input disabled type="text" name="totalDonsMontant" class="form-control" [(ngModel)]="totalDonsMontant"/>

				  </div>
				</div>


			</div>

		</div>
	</div>
</div>
</div>

</div>

<div class="col-md-2">
	<div class="card card-user">
	  <div class="card-header">
		<h5 class="card-title">Filtrer par</h5>
	  </div>
	  <div class="card-body">

	  <form id="form-fitler">

		  <div class="col-md-12">

			  <div class="form-group">
				<label>Montant min</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['montantDon1']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Montant max</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['montantDon2']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Nom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['nom']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Prénom</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['prenom']" (keyup)="updateFilter()">
			  </div>

			  <div class="form-group">
				<label>Code postal</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['idLocalite']" (keyup)="updateFilter()">
			  </div>


			  <div class="form-group">
				<label>Après date</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateDon1']" (change)="updateFilter()">
			  </div>


			  <div class="form-group">
				<label>Avant date</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtres['dateDon2']" (change)="updateFilter()">
			  </div>
				<div class="form-group">
				<label>Payement</label>

				<select name="filtresexe" class="form-control"
					[(ngModel)]="filtres['typeDon']"
					(change)="updateFilter()">

					<option value=""></option>
				  <option value="Banque">Banque</option>
				  <option value="ccueil cash">Accueil cash</option>
				  <option value="Accueil BC">Accueil BC</option>
				  <option value="Paypal">Paypal</option>
				  <option value="Facebook">Facebook</option>
				  <option value="Autre">Autre</option>
				</select>
			  </div>

			   <div class="row">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter()">Supprimer</button>
			</div>
		  </div>

		  </div>

	</form>

</div>
</div>
</div>
</div>

