import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';

import { ApiService } from '../api.service';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { Client } from '../client';
import { ChatAnimal } from '../chatanimal';
import { SortieAnimal } from '../sortieanimale';
import { EntreeAnimal } from '../entreeanimal';
import { Adoption } from '../adoption';
import { RaceChien } from '../racechien';
import { RobeChien } from '../robechien';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Animal } from '../animal';
import { EntreeSortieComponent } from '../entree-sortie/entree-sortie.component';

@Component({
  selector: 'app-animal-details',
  templateUrl: './animal-details.component.html',
  styleUrls: ['./animal-details.component.css']
})
export class AnimalDetailsComponent implements OnInit {


	PHP_API_SERVER = "http://localhost/sans-collier-backend/api.php"

	messages = {
	  emptyMessage: `
	    <div>
	      <p>Pas de données<s/p>
	    </div>
	  `
	}

	deleteConfirm:string = 'basic';

  @HostListener('window:keydown.enter', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.closePopup();
  }

	@Input() animal?: Animal;
	id: number = 0;
	statutAnimal: string = "";
	dateNaissanceStatut: string = "";
	entrees?: EntreeAnimal[];
	sorties?: SortieAnimal[];
	sortieAdoption?: SortieAnimal = new SortieAnimal();
	adoptionClient?: Client;
	adoptions?: Adoption[];
	adoption?: Adoption;
	adoptant: string = "";
	i_adoption: number = 0;
	sexeStatut: string = "";
	statusColor: string = "black";

	// Dropdowns auto-fill
  	sites: string[] = [];

	sexeOptions: string[] = ['Male', 'Femelle'];
	sexeControl = new FormControl();
    filteredsexeOptions?: Observable<string[]>;

	racesOptions: string[] = [];
	raceControl = new FormControl();
    filteredRaceOptions?: Observable<string[]>;

	robesOptions: string[] = [];
	robe1Control = new FormControl();
    filteredRobe1Options?: Observable<string[]>;
	robe2Control = new FormControl();
    filteredRobe2Options?: Observable<string[]>;

	niveauOptions: string[] = ["0", "1", "2", "3", "4"];
	niveauControl = new FormControl();
    filteredNiveauOptions?: Observable<string[]>;

	siteOptions: string[] = [];
	siteControl = new FormControl();
    filteredSiteOptions?: Observable<string[]>;

	dcdOptions: string[] = ["Au refuge", "Hors refuge"];
	dcdControl = new FormControl();
    filtereddcdOptions?: Observable<string[]>;

	avisOptions: string[] = ["Bon", "Moyen"];
	avisControl1 = new FormControl();
    filteredAvisOptions1?: Observable<string[]>;
	avisControl2 = new FormControl();
    filteredAvisOptions2?: Observable<string[]>;
	avisControl3 = new FormControl();
    filteredAvisOptions3?: Observable<string[]>;

	benevolesOptions: string[] = [];
	benevolesControlAppel = new FormControl();
    filteredBenevolesOptionsAppel?: Observable<string[]>;
	benevolesControlInsp = new FormControl();
    filteredBenevolesOptionsInsp?: Observable<string[]>;
	benevolesControl2deInsp = new FormControl();
    filteredBenevolesOptions2deInsp?: Observable<string[]>;

	clientsOptions: string[] = [];
	resaClientsControl = new FormControl();
    filteredResaClientsOptions?: Observable<string[]>;
	clientsControl = new FormControl();
    filteredClientsOptions?: Observable<string[]>;

	villesOptions: string[] = [];
	villesControl = new FormControl();
    filteredVillesOptions?: Observable<string[]>;

	imageSrc:any = '';
  	status:boolean = false;
	
	imageToShow: any;
	isImageLoading = true;

	// format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');

	columnsSorties = [
			{ prop: 'nom', name: "Nom client", width: 100  },
			{ prop: 'Prenom', name: "Prénom client", width: 100  },
			// { prop: 'age', name: "Age", width: 75 },
			{ prop: 'typeSortie', name: "Type de sortie", width: 100},
			{ prop: 'dateSortie',	name: 'Date de sortie', width: 100  },
			{ prop: 'placeur',	name: 'Placeur', width: 80 }
			// { prop: 'PAF',	name: 'PAF', width: 50 }
			];

	columnsEntrees = [
			{ prop: 'nom', name: "Nom client", width: 100  },
			{ prop: 'Prenom', name: "Prénom client", width: 100  },
			{ prop: 'typeEntree', name: "Type d'entrée", width: 100},
			{ prop: 'dateEntree',	name: "Date d'entrée", width: 100},
			// { prop: 'PAF',	name: 'PAF', width: 50  }
			];

	admin: boolean = false;

	refugeID: number = 0;

	donneesPerma: string[] = ["nom", "sexe", "age", "dateNaissance", "statusSpecial", "confidentiel", "identificationAnimal", "dateIdentification", "commentaireSante", "commentaireComportement", "commentaireAutre", "dateVacc1", "dateVacc2", "rappelVacc1", "rappelVacc2", "numeroPasseport", "transfertIdentification", "site", "dcd", "lieuDCD", "dateDeces", "commentaireDeces", "dateAdoptable", "reservation", "dateReservation", "clientReservation", "commentaireReservation", "ajouteSurSite", "auRefuge", "remisMaitre", "vole", "adopte", "familleAccueil", "statutSortie", "statutEntree", "dateDerniereEntree", "dateDerniereSortie", "numContrat", "photo", "croisement", "robe1", "robe2", "dateFIV", "fiv", "felv", "typeVermifuge", "vermifugePasseport", "dateVermifuge", "rappelVermifuge", "niveau", "race", "sterilisation", "sterilisationDate", "sterilisationDateIconnue", "local", "localisationCage", "localisationOrdre"];
	donneesDisplay: {nom: string, valeur: any}[] = [];

  constructor(
    private route: ActivatedRoute,
	private router: Router,
    private apiService: ApiService,
    private location: Location,
	private dialog: MatDialog,
    public dialogRef: MatDialogRef<AnimalDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData?: number
	) {}

	censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  updateField(table: string, field: string): void {

	  this.apiService.updateTable(table, 'id', this.id, field, (this.animal as any)[field])
		.subscribe(options => {
			//
			 console.log("return from put " + options);

		});

  }

  updateFieldDate(table: string, field: string): void {
	this.apiService.updateTable(table, 'id', this.id, field, new Date((this.animal as any)[field]))
		.subscribe(options => {
			//
			 console.log("return from put " + options);

		});
  }

  customUpdateField(table: string, idName: string, id: number, field: string, obj: any): void {

	  this.apiService.updateTable(table, idName, id, field, (obj as any)[field])
		.subscribe(options => {
			//
			 console.log("return from put -- " + options);

		});

  }

  customUpdateFieldValue(table: string, idName: string, id: number, field: string, value: any): void {

	  this.apiService.updateTable(table, idName, id, field, value)
		.subscribe(options => {
			//
			 console.log("return from put -- " + options);

		});

  }


  ngOnInit(): void {

	//this.id = +(this.route.snapshot.paramMap.get('id') || "0");

	if (this.dialogData) {
		this.id = this.dialogData;
		this.refugeID = +localStorage.getItem('refuge')!;
		this.loadAdoptions();
		this.getAnimalID();
		this.getEntreesAnimal();
		this.getSortiesAnimal();
	}
		
	if (localStorage.getItem('level') == "Direction") {
		this.admin = true;
	}

	this.filteredsexeOptions = this.sexeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filtersexe(value))
    );

	this.filteredRaceOptions = this.raceControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterRace(value))
    );

	this.filteredRobe1Options = this.robe1Control.valueChanges.pipe(
      startWith(''),
      map(value => this._filterRobe(value))
    );

	this.filteredRobe2Options = this.robe2Control.valueChanges.pipe(
      startWith(''),
      map(value => this._filterRobe(value))
    );

	this.filteredNiveauOptions = this.niveauControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterNiveau(value))
    );

	this.filtereddcdOptions = this.dcdControl.valueChanges.pipe(
      startWith(''),
      map(value => this._dcdSite(value))
    );

	this.filteredSiteOptions = this.siteControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSite(value))
    );

	this.filteredAvisOptions1 = this.avisControl1.valueChanges.pipe(
      startWith(''),
      map(value => this._filterAvis(value))
    );

	this.filteredAvisOptions2 = this.avisControl2.valueChanges.pipe(
      startWith(''),
      map(value => this._filterAvis(value))
    );

	this.filteredAvisOptions3 = this.avisControl3.valueChanges.pipe(
      startWith(''),
      map(value => this._filterAvis(value))
    );

	this.filteredBenevolesOptionsAppel = this.benevolesControlAppel.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBenevole(value))
    );

	this.filteredBenevolesOptionsInsp = this.benevolesControlInsp.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBenevole(value))
    );

	this.filteredBenevolesOptions2deInsp = this.benevolesControl2deInsp.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBenevole(value))
    );

	this.filteredResaClientsOptions = this.resaClientsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClients(value))
    );

	this.filteredClientsOptions = this.clientsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterClients(value))
    );

  }

  private _filtersexe(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.sexeOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterRace(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.racesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterRobe(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.robesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterNiveau(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.niveauOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterSite(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.siteOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _dcdSite(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.dcdOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAvis(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.avisOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }


  private _filterBenevole(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.benevolesOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
  }

  private _filterClients(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.clientsOptions.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
  }

  datePipe () {
	return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
	}

  handleFileInput(event: any) {
	this.status = false
    const file = event.target.files[0];
    this.status = event.target.files.length>0?true:false
    if(this.status==true){
       const reader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = () => {
          this.imageSrc = reader.result;          
       }
    }
  }

  onSubmit() {
	if (this.animal?.photo) {
		this.apiService.uploadPhoto(this.imageSrc, this.animal!.id, 'animaux', this.animal?.photo).subscribe(
			response => {
				console.log(response);
				this.getAnimalID();
			}
		)
	} else {
		this.apiService.uploadPhoto(this.imageSrc, this.animal!.id, 'animaux').subscribe(
			response => {
				console.log(response);
				this.getAnimalID();
			}
		)
	}
  }

  getDropdownOptions(): void {

	// Races
	this.apiService.getRaces()
		.subscribe(options => {
			console.log(options);
			console.log(this.animal?.animalType);

			options.forEach( (option) => {
					if (option.animalType === this.animal?.animalType){
						this.racesOptions!.push(option.race);
					}
			  });
			
			  console.log(this.racesOptions);
		});

	// Robes
	this.apiService.getRobes()
		.subscribe(options => {

			options.forEach( (option) => {
					if (option.animalType === this.animal?.animalType){
						this.robesOptions!.push(option.robe);
					}
			  });
		})

	// Bénévoles
	this.apiService.getBenevoles()
		.subscribe(options => {

			options.forEach( (option) => {
					this.benevolesOptions!.push(option.nom+', '+option.prenom)
			  });
		})

	// Clients
	this.apiService.getClients()
		.subscribe(options => {

			options.forEach( (option) => {
					this.clientsOptions!.push(option.nom+', '+option.prenom+' ('+option.id+')')
			  });
		})

	// Sites
	this.apiService.getRefugeID(this.refugeID)
		.subscribe(x => {
			let sitesTemp = x[0].sites;
			this.sites = sitesTemp.split(" ");
			this.sites.forEach( (option) => {
				this.siteOptions!.push(option);
			})
		})

	// Sites
	// this.apiService.getVilles()
		// .subscribe(options => {

			// options.forEach( (option) => {
					// this.villesOptions!.push(option.codePostal+' '+option.Ville)
			  // });
		// })

  }

  updateDateVacc(type: string) {

	  if (type == 'dateVacc1') {

		  this.animal!.rappelVacc1 = new Date();
		  this.animal!.rappelVacc1.setDate(this.animal!.dateVacc1.getDate() + 30);
		  console.log(this.animal!.rappelVacc1);
		  this.updateField('animaux', 'rappelVacc1');

	  } else if (type == 'dateVacc2') {

		  this.animal!.rappelVacc2 = new Date();
		  this.animal!.rappelVacc2.setDate(this.animal!.dateVacc2.getDate() + 30);
		  this.updateField('animaux', 'rappelVacc2');

	  } else if (type == 'dateVacc3') {

		  this.animal!.rappelVacc3 = new Date();
		  this.animal!.rappelVacc3.setDate(this.animal!.dateVacc3.getDate() + 365);
		  this.updateField('animaux', 'rappelVacc3');

	  }  else if (type == 'dateAntipuce') {

		  this.animal!.rappelAntipuce = new Date();
		  this.animal!.rappelAntipuce.setDate(this.animal!.dateAntipuce.getDate() + 90);
		  this.updateField('chats', 'rappelAntipuce');

	  }  else if (type == 'dateVermifuge') {

		  this.animal!.rappelVermifuge = new Date();
		  this.animal!.rappelVermifuge.setDate(this.animal!.dateVermifuge.getDate() + 365);
		  this.updateField('chats', 'rappelVermifuge');

	  }

  }

  getType(value: any): string {
	let type: string = "";

	if (value !== null) {
		type = typeof value;
		let date = new Date(value);
		let date2 = new Date('1970-01-01');
		
		if ( ((date.getDate() !== date2.getDate()) || (date.getMonth() !== date2.getMonth()) || (date.getFullYear() !== date2.getFullYear())) && !isNaN(date.getDate())){
			type = "Date";
		}
	}

	return type;
  }

  decamelize(str: string, separator: string): string{
	let sep = separator;
	sep = typeof sep === 'undefined' ? '_' : sep;

	return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + sep + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + sep + '$2')
        .toLowerCase();
  }

  getAnimalID(): void {

	this.apiService.getAnimalID(this.id)
		.subscribe(animal => {

		this.animal = animal[0];
		console.log(animal[0]);
		console.log(this.animal);

		this.animal.croisement = animal[0].croisement;

		// Dropbox initial value
		this.raceControl.setValue(this.animal!.race);
		this.sexeControl.setValue(this.animal!.sexe);

		// Conversion to boolean for checkbox handling
		this.animal!.croisement = this.convertToBoolean(this.animal!.croisement);
		this.animal!.sterilisation = this.convertToBoolean(this.animal!.sterilisation);
		this.animal!.statusSpecial = this.convertToBoolean(this.animal!.statusSpecial);
		this.animal!.transfertIdentification = this.convertToBoolean(this.animal!.transfertIdentification);
		this.animal!.dcd = this.convertToBoolean(this.animal!.dcd);
		this.animal!.adopte = this.convertToBoolean(this.animal!.adopte);
		this.animal!.remisMaitre = this.convertToBoolean(this.animal!.remisMaitre);
		this.animal!.vole = this.convertToBoolean(this.animal!.vole);
		this.animal!.familleAccueil = this.convertToBoolean(this.animal!.familleAccueil);
		this.animal!.reservation = this.convertToBoolean(this.animal!.reservation);
		this.animal!.auRefuge = this.convertToBoolean(this.animal!.auRefuge);
		this.animal!.ajouteSurSite = this.convertToBoolean(this.animal!.ajouteSurSite);
		this.animal!.confidentiel = this.convertToBoolean(this.animal!.confidentiel);
		this.animal!.sterilisationDateIconnue = this.convertToBoolean(this.animal!.sterilisationDateIconnue);
		this.animal!.fiv = this.convertToBoolean(this.animal!.fiv);
		this.animal!.felv = this.convertToBoolean(this.animal!.felv);
		this.animal!.antipucePasseport = this.convertToBoolean(this.animal!.antipucePasseport);
		this.animal!.vermifugePasseport = this.convertToBoolean(this.animal!.vermifugePasseport);
		
		this.getDropdownOptions();
		this.updateAnimalStatus();

		if(this.animal?.photo) {
			this.apiService.getImage(this.animal.photo).subscribe(
				x => {
					this.createImageFromBlob(x);
					this.isImageLoading = false;
				}, error => {
					this.isImageLoading = false;
					console.log(error);
				}
			);

		}

		this.apiService.getColonnes(this.refugeID, this.animal.animalType).subscribe(
			col => {
				const colonnes: string = col[0].colonnes;
				const colonnesSplit = colonnes.split(" ");
	
				if (this.animal) {
					let k: keyof Animal;
					for (k in this.animal) {
						let v = this.animal[k];
						
						if (v === "0" || v === "1") {
							v = this.convertToBoolean(v);
						}
	
						if (colonnesSplit.includes(k) && !this.donneesPerma.includes(k)) {
							const add = {nom: k, valeur: v};
							this.donneesDisplay.push(add);
						}
					}
					console.log(this.donneesDisplay);
					console.log("wesh");
				}
			});

	})
  }

  updateFieldDisplay(table: string, field: string, value: any): void {
    console.log("update");
	console.log(value);
	console.log(field);

	this.apiService.updateTable(table, 'id', this.animal!.id, field, value)
	.subscribe(options => {});

  }

  createImageFromBlob(image: Blob) {
	let reader = new FileReader();
	reader.addEventListener("load", () => {
		this.imageToShow = reader.result;
	}, false);

	if (image) {
		reader.readAsDataURL(image);
	}
}

updateAnimalStatus(): void {

	this.statusColor = "black"

	this.dateNaissanceStatut = this._datePipe.transform(this.animal!.dateNaissance, 'dd/MM/yyyy') || 'Inconnue'

	  this.sexeStatut = this.animal!.sexe[0]
	  if (this.animal!.croisement) {
		  this.sexeStatut = 'X' + this.sexeStatut
	  }

	this.statutAnimal = ""

	  if (this.animal!.dcd) {
		  this.statutAnimal += "Décédé"
	  }

	  if (this.animal!.confidentiel) {
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Confidentiel"
	  }

	  if (this.animal!.statusSpecial) {
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Statut spécial"
	  }

	  if (this.animal!.auRefuge) {

		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Au refuge"

		  if (this.entrees) {

			  this.entrees.sort((a, b) => (a.dateEntree > b.dateEntree) ? 1 : -1);

			  let typeEntree = this.entrees[this.entrees.length - 1].typeEntree
			  typeEntree += typeEntree == 'Abandon' || typeEntree == 'Saisie' || typeEntree == 'Trouvé' ? ' - ' + this.entrees[this.entrees.length - 1].numeroCession : ''

			  let nDays = Math.round((new Date().valueOf() - new Date(this.entrees[this.entrees.length - 1].dateEntree).valueOf())/(1000*60*60*24))
			  this.statutAnimal += " (" + typeEntree + ", " + nDays + " jour"
			  this.statutAnimal += (nDays > 1) ? "s)" : ")"

		  }

	  }

	  if (this.animal!.ajouteSurSite) {
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Ajouté sur le site web"
	  }

	  if (this.animal!.reservation) {

		  this.statusColor = "red"
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Réservé"
	  }

	  if (this.animal!.familleAccueil) {

		  this.statusColor = "blue"
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "En famille d'accueil"
	  }

	  if (this.animal!.adopte) {

		  this.statusColor = "green"
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Adopté"
	  }

	  if (this.animal!.remisMaitre) {

		  this.statusColor = "green"
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Remis au maître"
	  }

	  if (this.animal!.vole) {
		  this.statutAnimal += (this.statutAnimal) ? ' - ' : '';
		  this.statutAnimal += "Volé"
	  }

}

	auRefuge(): void{

		this.animal!.remisMaitre = false;
		this.updateField('animaux', 'remisMaitre');
		this.animal!.vole = false;
		this.updateField('animaux', 'vole');
		this.animal!.familleAccueil = false;
		this.updateField('animaux', 'familleAccueil');
		this.animal!.adopte = false;
		this.updateField('animaux', 'adopte');
		this.animal!.dcd = false;
		this.updateField('animaux', 'dcd');

	}

	enFA(): void{

		this.animal!.remisMaitre = false;
		this.animal!.vole = false;
		this.animal!.auRefuge = false;
		this.animal!.adopte = false;
		this.animal!.dcd = false;

		this.updateField('animaux', 'remisMaitre');
		this.updateField('animaux', 'vole');
		this.updateField('animaux', 'auRefuge');
		this.updateField('animaux', 'adopte');
		this.updateField('animaux', 'dcd');

	}


	horsRefuge(): void{

		this.animal!.auRefuge = false;
		this.animal!.familleAccueil = false;

		this.updateField('animaux', 'auRefuge');
		this.updateField('animaux', 'familleAccueil');

	}

  convertToBoolean(value: boolean) {
	  return (value == Boolean(Number('0'))) ? false: true;
  }

  getEntreesAnimal(): void {

		this.apiService.getEntreesAnimal(this.id)
			.subscribe(entrees => {

				this.entrees = entrees;
				console.log(entrees);
				this.updateAnimalStatus();

			})

  }

  getSortiesAnimal(): void {

	this.apiService.getSortiesAnimal(this.id)
	.subscribe(sorties =>
		{
			this.sorties = sorties;
			//this.showAdoptions(this.sorties)
		})

  }

  /*showAdoptions(sorties: SortieAnimal[]): void {

	  var BreakException = {};

	  sorties.sort((a, b) => (a.idSortie > b.idSortie) ? -1 : 1)
	  console.log(sorties);

	  try {

		  sorties.forEach( (sortie) => {

			  if (sortie.typeSortie == "Adoption") {

					this.getAdoptionID(sortie.idSortie);
					this.sortieAdoption = sortie;

					this.apiService.getClientID(sortie.Idclient)
					.subscribe(client =>
						{
							this.adoptionClient = client[0];
							this.clientsControl.setValue(client[0].nom + ", " + client[0].prenom+' ('+client[0].id+')');
						});

					throw BreakException;

			  }

		  })

	  } catch (e) {
			if (e !== BreakException) throw e;
	  }

  }*/

  loadAdoptions(): void {

	this.apiService.getAdoptionIDAnimal(this.id!)
	  .subscribe(adoptions => {
		console.log(adoptions);

		  adoptions.sort((a, b) => (a.id > b.id) ? -1 : 1);

		  this.adoptions = adoptions;
		  this.adoption = this.adoptions[0];
		  this.loadSortieAdoptions(this.adoption!.id);
	  });

}

// Charge la sortie correspondante à l'adoption
loadSortieAdoptions(id: number): void {

	this.apiService.getSortieID(id)
	  .subscribe(sortie => {

		  this.sortieAdoption = sortie[0];
		  this.loadClientAdoption(this.sortieAdoption!.Idclient);

		  // Conversion to boolean for checkbox handling
		  this.adoption!.secondeVisite = this.convertToBoolean(this.adoption!.secondeVisite);
		  this.adoption!.suiviUrgent = this.convertToBoolean(this.adoption!.suiviUrgent);
		  this.adoption!.deuxiemeAppelNecessaire = this.convertToBoolean(this.adoption!.deuxiemeAppelNecessaire);
		  this.adoption!.changementContrat = this.convertToBoolean(this.adoption!.changementContrat);

	  });

}

loadClientAdoption(id: number): void {

	this.apiService.getClientID(id)
	  .subscribe(client =>
		  {
			  this.adoptionClient = client[0];
			  /*this.clientsControl.setValue(client[0].nom + ", " + client[0].prenom+' ('+client[0].id+')');*/
			  this.adoptant = client[0].nom + ", " + client[0].prenom;
		  });

}

switchAdoption(type: string): void {

	this.i_adoption += (type == 'next') ? 1 : -1;
	if (this.i_adoption == this.adoptions!.length) {
	this.i_adoption = 0;
	} else if (this.i_adoption < 0) {
	this.i_adoption = this.adoptions!.length-1;
	}
	this.adoption = this.adoptions![this.i_adoption];
	this.loadSortieAdoptions(this.adoption!.id);

	// Conversion to boolean for checkbox handling
	this.adoption!.secondeVisite = this.convertToBoolean(this.adoption!.secondeVisite);
	this.adoption!.suiviUrgent = this.convertToBoolean(this.adoption!.suiviUrgent);
	this.adoption!.deuxiemeAppelNecessaire = this.convertToBoolean(this.adoption!.deuxiemeAppelNecessaire);
	this.adoption!.changementContrat = this.convertToBoolean(this.adoption!.changementContrat);

	this.benevolesControlAppel.setValue(this.adoption!.benevoleAppel)
	this.benevolesControlInsp.setValue(this.adoption!.benevoleInsp)
	this.benevolesControl2deInsp.setValue(this.adoption!.benevoleInsp2)
	this.avisControl1.setValue(this.adoption!.avisAppel);
	this.avisControl2.setValue(this.adoption!.avisInspection);
	this.avisControl3.setValue(this.adoption!.avisInspection2);

}

  /*getAdoptionID(idSortie: number): void {

		console.log(idSortie);
		this.apiService.getAdoptionIDSortie(idSortie)
			.subscribe(adoption => {

				this.adoption = adoption[0];

				// Conversion to boolean for checkbox handling
				this.adoption!.secondeVisite = this.convertToBoolean(this.adoption!.secondeVisite);
				this.adoption!.suiviUrgent = this.convertToBoolean(this.adoption!.suiviUrgent);

				this.benevolesControlAppel.setValue(this.adoption!.benevoleAppel)
				this.benevolesControlInsp.setValue(this.adoption!.benevoleInsp)
				this.benevolesControl2deInsp.setValue(this.adoption!.benevoleInsp2)
				this.avisControl1.setValue(this.adoption!.avisAppel);
				this.avisControl2.setValue(this.adoption!.avisInspection);
				this.avisControl3.setValue(this.adoption!.avisInspection2);

			})

  }*/

  onSelect(event: any): void{
	if (event['type'] == 'click') {

		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = '500px';

		dialogConfig.data = <EntreeAnimal>event['row'];
		//dialogConfig.data.animalType = "chat";

		let dialogRef = this.dialog.open(EntreeSortieComponent, dialogConfig);

		dialogRef.afterClosed().subscribe(result => {


			this.getEntreesAnimal()
			this.getSortiesAnimal()

		});

		// this.router.navigate(['/details-client/'+ event['row']['Idclient']]);
	}

  }

  goBack(): void{
	  this.location.back();
  }

  delete(table: string, idName: string, id: number): void {

	if (this.deleteConfirm == 'warn') {

			this.apiService.deleteObjet(table, idName, id).subscribe(message => {

				this.dialogRef.close();

			})

	} else {
			this.deleteConfirm = 'warn'
	}

}

  closePopup() {
    this.dialogRef.close();
  }

}
