import { Component, OnInit, Input  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ApiService } from '../api.service';

import { Don } from '../don';

@Component({
  selector: 'app-don-details',
  templateUrl: './don-details.component.html',
  styleUrls: ['./don-details.component.css']
})
export class DonDetailsComponent implements OnInit {

	@Input() don?: Don;
	id?:number;

	niveauAcces: string = "";
	deleteConfirm:string = 'basic';

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private location: Location
	) {}

  ngOnInit(): void {
  	this.niveauAcces = localStorage.getItem('level')!;
    this.getDon();
  }
  
  getDon(): void {
	  
	if (this.route && this.route.snapshot && this.route.snapshot.paramMap) {
		
		this.id = +(this.route.snapshot.paramMap.get('id') || "0");
		
		this.apiService.getDonID(this.id)
			.subscribe(don => this.don = don[0])
	}
	
  }
	

  updateField(table: string, field: string): void {
	  
	  this.apiService.updateTable(table, 'id', this.id!, field, (this.don as any)[field]) 
		.subscribe();
	  
  }
  
  goBack(): void{
	  this.location.back();
  }

  delete(table: string, idName: string, id: number): void {

  		if (this.deleteConfirm == 'warn') {

					this.apiService.deleteObjet(table, idName, id).subscribe(message => {

						window.history.back();

					})

			} else {
					this.deleteConfirm = 'warn'
			}		

	}

}