export class Animal {

	id!: number;
	animalType!: number;
	race!: string;
	nom!: string;
	sexe!: string;
	age!: string;
	sterilisation!:boolean;
	dcd!:boolean;
	sterilisationDate!:Date;
	dateNaissance!:Date;
	statusSpecial!:boolean;
	identificationAnimal!:string;
	dateIdentification!:Date;
	commentaireSante!:string;
	commentaireComportement!:string;
	commentaireAutre!:string;
	dateVacc1!:Date;
	dateVacc2!:Date;
	dateVacc3!:Date;
	rappelVacc1!:any;
	rappelVacc2!:any;
	rappelVacc3!:any;
	typeAntipuce!: number;
	antipucePasseport!: any;
	dateAntipuce!: Date;
	rappelAntipuce!: any;
	typeVermifuge!: number;
	vermifugePasseport!: any;
	dateVermifuge!: Date;
	rappelVermifuge!: any;
	numeroPasseport!: string;
	transfertIdentification!:boolean;
	site!:string;
	dateAdoptable!: Date;
	auRefuge!:boolean;
	remisMaitre!:boolean;
	familleAccueil!:boolean;
	vole!:boolean;
	adopte!:boolean;
	reservation!:boolean;
	dateDerniereEntree!: Date;
	dateDerniereSortie!: Date;
	numContrat!: number;
	photo!: string;
	refuge!: number;
	statut!: string;
	Local!: string;
	localisationCage!: number;
	localisationOrder!: number;
	robe1!: string;
	robe2!: string;
	ajouteSurSite!: boolean;
  dateFIV!: Date;
	fiv!: any;
	felv!: any;
	numeroCession!: number;
	nomClient!: string;
	prenom!: string;
	idLocalite!: number;
	localite!: string;
	typeSortie!: string;
	dateSortie!: Date;
	nomAdoptant!: string;
	prenomAdoptant !: string;
	CPAdoptant!: number;
	VilleAdoptant!: string;
	regNationalAdoptant!: number;
	numContratAdoption!: number;
	dateInspection!: Date;
	dateInspection2!: Date;
	dateAppel!: Date;
	niveau!: number;
	croisement!: boolean;
	localisationOrdre!: number;
	sterilisationDateIconnue!:boolean;
	confidentiel!:boolean;
  commentaireAPrevoir!:string;
	lieuDCD!:string;
	dateDeces!:Date;
	commentaireDeces!:string;
	dateReservation!:Date;
	clientReservation!:string;
	commentaireReservation!:string;
	statutSortie!:number;
	statutEntree!:number;

}
