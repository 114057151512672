export class Don {

  id!: number;
  Idclient!: number;
  nom!:string;
  prenom!:string;
  dateNaissance!:Date;
  adresse!:string;
  idLocalite!:number;
  vip!:boolean;
  testamentaire!:boolean;
  sexe!:number;
  tel1!:string;
  email!:string;
  typeDon!: string;
  montantDon!: number;
  dateDon!: Date;
  refuge!: number;
}