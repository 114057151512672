import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { ApiService } from '../api.service';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EntreeSortieComponent } from '../entree-sortie/entree-sortie.component';

import { Client } from '../client';
import { SortieAnimal } from '../sortieanimale';
import { EntreeAnimal } from '../entreeanimal';
import { Don } from '../don';
import { Pays } from '../pays';
import { AnimalType } from '../animalType';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent implements OnInit {

  @HostListener('window:keydown.enter', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.closePopup();
  }

	@Input() client?: Client;
	statutSpecial: string = "";
	entrees?: EntreeAnimal[];
	sorties?: SortieAnimal[];
	niveauAcces: string = "";

	deleteConfirm:string = 'basic';

	paysOptions: string[] = [];
	paysControl = new FormControl();
  	filteredPaysOptions?: Observable<string[]>;

  	localitesOptions: string[] = [];
	localitesControl = new FormControl();
  	filteredLocalitesOptions?: Observable<string[]>;

	types: AnimalType[] = [];

	dons: Don[] = [];
	localite: string = "Inconnue";

	pdfSrc:any = '';
  	status:boolean = false;
	file: any;
	pdfFile: any[] = [];
	pdfList: string[] = [];

	refugeID: number = 0;

	messages = {
	  emptyMessage: `
	    <div>
	      <p>Pas de données<s/p>
	    </div>
	  `
	}

	donneesPerma: string[] = ["personne", "nom", "prenom", "dateNaissance", "adresse", "idLocalite", "localite", "pays", "regNational", "tel1", "tel2", "email", "sexe", "adoptant", "surListeNoire", "listeNoireDate", "listeNoireInfo", "vip", "vipDate", "testamentaire", "dateTestamentaire", "commentaire", "donateur", "dateDernierDon", "benevole", "niveauAcces"];
	donneesDisplay: {nom: string, valeur: any}[] = [];

	// { prop: 'id', 		name: "ID sortie" },
	columnsSorties = [
			{ prop: 'animalType',  	name: 'Animal', width: 60, pipe: this.typePipe() },
			{ prop: 'nom', name: "Nom", width: 100 },
			// { prop: 'age', name: "Age", width: 80  },
			{ prop: 'typeSortie', name: "Type de sortie", width: 150  },
			{ prop: 'dateSortie',	name: 'Date de sortie', width: 150, pipe: this.datePipe() },
			// { prop: 'PAF',	name: 'PAF', width: 80 }
			];

	// { prop: 'id', 		name: "ID entrée" },
	columnsEntrees = [
			{ prop: 'animalType',  	name: 'Animal', width: 60, pipe: this.typePipe() },
			{ prop: 'nom', name: "Nom", width: 100 },
			// { prop: 'age', name: "Age", width: 80  },
			{ prop: 'typeEntree', name: "Type d'entrée", width: 150    },
			{ prop: 'dateEntree',	name: "Date d'entrée", width: 150, pipe: this.datePipe()   },
			// { prop: 'PAF',	name: 'PAF', width: 80 }
			];

	columnsDons = [
			{ prop: 'dateDon',  	name: 'Date', pipe: this.datePipe(), width: 100},
			{ prop: 'typeDon', name: "Type de don", width: 150  },
			{ prop: 'montantDon', name: "Montant", pipe: this.montantPipe(), width: 120},
			];

	// format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private location: Location,
		private dialog: MatDialog,
		private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ClientDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData?: number
	) {}

  ngOnInit(): void {

    this.niveauAcces = localStorage.getItem('level')!;
	this.refugeID = +localStorage.getItem('refuge')!;

    this.getClient();
	this.getEntreesClient();
	this.getSortiesClient();

	this.getPays();
	this.getVilles();
	this.apiService.getRefugeAnimalTypes(+localStorage.getItem('refuge')!).subscribe(
        x => {
          this.types = x;
        }
    );

	this.filteredPaysOptions = this.paysControl.valueChanges.pipe(
	      startWith(''),
	      map(value => this._filterPays(value))
    );

		this.filteredLocalitesOptions = this.localitesControl.valueChanges.pipe(
	      startWith(''),
	      map(value => this._filterLocalites(value))
    );

  }

  handleFileInput(event: any) {
	this.status = false
    this.file = event.target.files[0];
    this.status = event.target.files.length>0?true:false
  }

  onSubmit() {
	if (this.status) {
		if (this.client?.pdfFile) {
			this.apiService.uploadPdf(this.file, this.client!.id, this.file.name, this.client?.pdfFile).subscribe(
				response => {
					console.log(response);
					this.getClient();
				}
			)
		} else {
			this.apiService.uploadPdf(this.file, this.client!.id, this.file.name).subscribe(
				response => {
					console.log(response);
					this.getClient();
				}
			)
		}		
	}
  }

  updateField(table: string, field: string): void {
    console.log("update");
	var value = (this.client as any)[field];
	value = (field == 'adresse') ? value.toUpperCase() : value;
	value = (field == 'nom') ? value.toUpperCase() : value;
	value = (field == 'prenom') ? value.toUpperCase() : value;

	this.apiService.updateTable(table, 'id', this.client!.id, field, value)
	.subscribe(options => {});

  }

  updateFieldDisplay(table: string, field: string, value: any): void {
    console.log("update");
	console.log(value);
	console.log(field);

	this.apiService.updateTable(table, 'id', this.client!.id, field, value)
	.subscribe(options => {});

  }

  private _filterPays(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.paysOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterLocalites(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.localitesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  datePipe () {
	return {transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')};
  }

  montantPipe () {
	  return {transform: (value: number) => (this.niveauAcces == 'Direction' || this.niveauAcces == 'Administratif') ? value : '-'};
  }

  typePipe () {
	return {transform: (value: string) => {
		let rep = "autre";
		this.types.forEach(type => {
			if (value === type.animaltype.toString()) {
				rep = type.type;
			}
		});
		return rep;
	}};
  }

  getDonClient(): void {
	this.apiService.getDonClient(this.client!.id)
	  .subscribe(dons => {
		  this.dons = dons;
      console.log(this.dons);
	  })
  }


  getType(value: any): string {
	let type: string = "";

	if (value !== null) {
		type = typeof value;
		let date = new Date(value);
		let date2 = new Date('1970-01-01');
		
		if ( ((date.getDate() !== date2.getDate()) || (date.getMonth() !== date2.getMonth()) || (date.getFullYear() !== date2.getFullYear())) && !isNaN(date.getDate())){
			type = "Date";
		}
	}

	return type;
  }

  decamelize(str: string, separator: string): string{
	let sep = separator;
	sep = typeof sep === 'undefined' ? '_' : sep;

	return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + sep + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + sep + '$2')
        .toLowerCase();
  }

  onDownload(index: number): void {
	if (this.pdfFile) {
		window.open(this.pdfFile[index]);
	}
  }

  onDelete(index: number): void {
	var name = "./pdf/" + this.pdfList[index];
	var url = "";
	if (this.pdfList[index].slice(-4) !== ".pdf") {
		name += ".pdf";
	}

	this.pdfList.splice(index, 1);

	this.pdfList.forEach(element => {
		if (element.slice(-4) !== ".pdf") {
			element += ".pdf";
		}
		element = "./pdf/" +element;
		url += element+" ";
	});
	if (url === "") {
		url = " ";
	}
	console.log(url);
	console.log(name);

	this.apiService.deletePdf(this.client!.id, name, url).subscribe(x => this.getClient());
  }

  getClient(): void {
    if (this.dialogData) {
      const id = +this.dialogData;
      this.apiService.getClientID(id)
          .subscribe(client => {
            this.client = client[0];

            this.client!.pasDeCourrier = this.convertToBoolean(this.client!.pasDeCourrier);
            this.client!.mailUniquement = this.convertToBoolean(this.client!.mailUniquement);
            this.client!.testamentaire = this.convertToBoolean(this.client!.testamentaire);
            this.client!.surListeNoire = this.convertToBoolean(this.client!.surListeNoire);
            this.client!.adoptant = this.convertToBoolean(this.client!.adoptant);
            this.client!.vip = this.convertToBoolean(this.client!.vip);
            this.client!.donateur = this.convertToBoolean(this.client!.donateur);

            this.updateStatus();
            this.getDonClient();

			if (this.client?.pdfFile) {
				var list = this.client.pdfFile.split(".pdf ");
				var last = list.length - 1;
				if (list[last] === "" || list[last] === " ") {
					list.pop();
				}
				this.pdfList = [];
				this.pdfFile = [];
				list.forEach(element => {
					if (element.slice(-4) !== ".pdf") {
						element += ".pdf";
					}
					this.apiService.getPdf(element).subscribe(
						(data: Blob) => {
							var file = new Blob([data], { type: 'application/pdf' })
							var fileURL = URL.createObjectURL(file);
							this.pdfFile.push(fileURL);
							const nom = element.slice(6);
							this.pdfList.push(nom);
						}
					)					
				});
			}

			this.apiService.getColonnesClients(this.refugeID).subscribe(
				col => {
					const colonnes: string = col[0].colonnesClients;
					const colonnesSplit = colonnes.split(" ");
					this.donneesDisplay = [];
					if (this.client) {
						let k: keyof Client;
						for (k in this.client) {
							let v = this.client[k];
		
							if (colonnesSplit.includes(k) && !this.donneesPerma.includes(k)) {
								const add = {nom: k, valeur: v};
								this.donneesDisplay.push(add);
							}
						}
						console.log(this.donneesDisplay);
						console.log("wesh");
					}
				});
          }
        )
    }
    else {
      if (this.route && this.route.snapshot && this.route.snapshot.paramMap) {

        const id = +(this.route.snapshot.paramMap.get('id') || "0");

        this.apiService.getClientID(id)
          .subscribe(client => {
            this.client = client[0];

            this.client!.pasDeCourrier = this.convertToBoolean(this.client!.pasDeCourrier);
            this.client!.mailUniquement = this.convertToBoolean(this.client!.mailUniquement);
            this.client!.testamentaire = this.convertToBoolean(this.client!.testamentaire);
            this.client!.surListeNoire = this.convertToBoolean(this.client!.surListeNoire);
            this.client!.adoptant = this.convertToBoolean(this.client!.adoptant);
            this.client!.vip = this.convertToBoolean(this.client!.vip);
            this.client!.donateur = this.convertToBoolean(this.client!.donateur);

            this.updateStatus()
            this.getDonClient()

          }
        )
      }
    }
  }

  updateStatus(): void {

  	this.statutSpecial = "";

		if (this.client!.adoptant) {
			this.statutSpecial += "Adoptant"
		}

		if (this.client!.benevole == "Oui") {
			this.statutSpecial += (this.statutSpecial) ? ', ' : '';
			this.statutSpecial += "Bénévole"
		}

		if (this.client!.dateDernierDon && this.client!.donateur) {
			this.statutSpecial += (this.statutSpecial) ? ', ' : '';
			this.statutSpecial += "Donateur"
		}

		if (this.client!.testamentaire) {
			this.statutSpecial += (this.statutSpecial) ? ', ' : '';
			this.statutSpecial += "Testamentaire"
		}

		if (this.client!.vipDate && this.client!.vip) {
			this.statutSpecial += (this.statutSpecial) ? ', ' : '';
			this.statutSpecial += "VIP"
		}

		if (this.client!.surListeNoire) {
			this.statutSpecial += (this.statutSpecial) ? ', ' : '';
			this.statutSpecial += "Liste Noire"
		}

		if (this.statutSpecial) {
			this.statutSpecial = "[" + this.statutSpecial + "]";
		}

  }

  getLocalite(){

	  this.apiService.getLocaliteID(this.client!.idLocalite).subscribe(
		ville => {

			this.client!.localite = ville[0].Ville;
			this.updateField('clients', 'localite')

		})

  }

  convertToBoolean(value: boolean) {
	  return (value == Boolean(Number('0'))) ? false: true;
  }

  getEntreesClient(): void {
    if (this.dialogData) {
      const id = +this.dialogData;

      this.apiService.getEntreesClient(id)
				.subscribe(entrees => {
          this.entrees = entrees;
        })

    } else {
      if (this.route && this.route.snapshot && this.route.snapshot.paramMap) {

        const id = +(this.route.snapshot.paramMap.get('id') || "0");

        this.apiService.getEntreesClient(id)
          .subscribe(entrees => {
            this.entrees = entrees;
            console.log(this.entrees);
          })
      }
    }


  }

  getPays(): void {

		this.apiService.getPays()
				.subscribe(pays => {

					pays.forEach( (option) => {
						this.paysOptions!.push(option.nom_fr_fr)
					});

				})

  }

  getVilles(): void {

		this.apiService.getLocalites()
				.subscribe(villes => {

					villes.forEach( (option) => {
						this.localitesOptions!.push(option.Ville)
					});

				})

  }

  getSortiesClient(): void {

    if (this.dialogData) {
      const id = +this.dialogData;

      this.apiService.getSortiesClient(id)
        .subscribe(sorties => {
          this.sorties = sorties;
          console.log(this.sorties);
        })
    } else {
      if (this.route && this.route.snapshot && this.route.snapshot.paramMap) {

        const id = +(this.route.snapshot.paramMap.get('id') || "0");

        this.apiService.getSortiesClient(id)
          .subscribe(sorties => {
            this.sorties = sorties;
            console.log(this.sorties);
          })
      }
    }



  }

  onSelect(event: any): void{

	if (event['type'] == 'click') {

		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.width = '500px';

		dialogConfig.data = <EntreeAnimal>event['row'];
		dialogConfig.data.animalType = event['row']['animalType'];
		dialogConfig.data.nom = event['row']['nom'];

		this.dialog.open(EntreeSortieComponent, dialogConfig);

		// this.router.navigate(['/details-' + type +'/'+ event['row']['Idanimal']]);
	}
}


  onSelectDon(event: any): void{

	if (event['type'] == 'click' && (this.niveauAcces == 'Direction' || this.niveauAcces == 'Administratif')) {

		this.router.navigate(['/details-don/'+ event['row']['id']]);
    this.closePopup();
	}

  }

  goBack(): void{
	  this.location.back();
  }


  delete(table: string, idName: string, id: number): void {

			if (this.deleteConfirm == 'warn') {

					this.apiService.deleteObjet(table, idName, id).subscribe(message => {

						this.router.navigate(['/clients']);
						this._snackBar.open('Client supprimé', 'OK');
						this.closePopup();

					})

			} else {
					this.deleteConfirm = 'warn'
			}

	}

  closePopup() {
    this.dialogRef.close();
  }

}
