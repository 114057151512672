export class EntreeAnimal {

	id!:number;
	Idanimal!:number;
	Idclient!:number;
	nom!:string;
	Prenom!:string;
	typeEntree!:string;
	animalType!:number;
	dateEntree!:Date;
	PAF!:number;
	durePrevue!:number;
	numeroCession!:number;
	refuge!: number;

}