<div class="row">
  <div class="col-md-12">
    <div class="card card-user">
      <div class="card-header">
        <h5 class="card-title">Type de recherche</h5>
      </div>

      <div class="card-body">

        <div class="row mb-5">

          <div class="col-md-6 pc-1">

            <div class="d-flex justify-content-center">

              <button  mat-raised-button (click)="updateSelection('Animaux')">Animaux</button>

            </div>

          </div>

          <div class="col-md-6">

            <div class="d-flex justify-content-center">

              <button  mat-raised-button (click)="updateSelection('Clients')">Client</button>

            </div>

          </div>

        </div>

        <div class="row" [ngSwitch]="selection">

          <div *ngSwitchCase="'Clients'" style="width: 100%;">
            <div class="col-md-12">

              <h6>Trouver un client existant</h6>

            </div>

            <div class="col-md-12" style="display: flex; justify-content: space-between; flex-wrap: wrap;">
              <div class="form-group">
              <label>Nom</label>
              <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtresClients['nom']" (keyup)="updateFilterClients()">
              </div>

              <div class="form-group">
              <label>Prénom</label>
              <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtresClients['prenom']" (keyup)="updateFilterClients()">
              </div>

              <div class="form-group">
              <label>Téléphone</label>
              <input type="text" name="filtreTel" pattern="[0-9]{10}" class="form-control" [(ngModel)]="filtresClients['tel']" (keyup)="updateFilterClients()">
              <mat-error *ngIf="checkIfSpecialCharacters(filtresClients['tel'])">Caractère non autorisé utilisé</mat-error>
              </div>

              <div class="form-group">
              <label>Reg. national</label>
              <input type="text" name="filtreReg" class="form-control" [(ngModel)]="filtresClients['regNational']" (keyup)="updateFilterClients()">
              </div>

              <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter('client')" style="height: 45px;">Reset</button>

            </div>

            <div class="col-md-12">
              <ngx-datatable class="material" [rowHeight]="45" [rows]="clients" [columns]="columnsClients"
                (activate)="onSelect($event)" [limit]="20" [headerHeight]="50" [footerHeight]="30" [scrollbarH]="true"
                #tableClients>

                </ngx-datatable>

            </div>


          </div>

          <div *ngSwitchCase="'Animaux'" style="width: 100%;">
            <div class="col-md-12">

              <h6>Trouver un animal existant</h6>

            </div>

            <div class="col-md-12" style="display: flex; justify-content: space-between; flex-wrap: wrap;">
              <div class="form-group">
              <label>Nom</label>
              <input type="text" name="filtreNom" class="form-control" [(ngModel)]="filtresAnimaux['nom']" (keyup)="updateFilterAnimaux()">
              </div>

              <div class="form-group">
              <label>Identification</label>
              <input type="text" name="filtreID" class="form-control" [(ngModel)]="filtresAnimaux['identificationAnimal']" (keyup)="updateFilterAnimaux()">
              </div>

              <div class="form-group">
                <label>Type</label>
        
              <select name="sortie" class="form-control"
                [(ngModel)]="filtresAnimaux['animalType']" (change)="updateFilterAnimaux()">
      
                <option *ngFor="let type of types;" [value]="type.animaltype">{{type.type}}</option>
      
              </select>
              </div>

              <div class="form-group">
                <label>Présents <br> au refuge</label>
                <input type="checkbox" name="filtreAuRefuge" class="form-control" [(ngModel)]="filtresAnimaux['auRefuge']" (change)="updateFilterAnimaux()">
              </div>

              <button type="submit" class="btn btn-primary btn-round" (click)="resetFilter('animaux')" style="height: 45px;">Reset</button>

            </div>

            <div class="col-md-6">
              <ngx-datatable class="material" [rowHeight]="45" [rows]="animaux" [columns]="columnsAnimaux"
                (activate)="onSelect($event)" [limit]="20" [headerHeight]="50" [footerHeight]="30" [scrollbarH]="true"
                #tableClients>

                </ngx-datatable>

            </div>


          </div>

        </div>

      </div>

    </div>

  </div>

</div>
