import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { ApiService } from '../api.service';

// Autcomplete dropdown
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import { Animal } from '../animal';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { AnimalDetailsComponent } from '../animal-details/animal-details.component';

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
  selector: 'app-animal',
  templateUrl: './animal.component.html',
  styleUrls: ['./animal.component.css']
})
export class AnimalComponent implements OnInit {

	typeAnimaux: number = 0;

	animals: Animal[] = [];
	fullData: Animal[] = [];
	filtres: Dictionary<string> = {};
	animauxSpeciaux: string[] = [];
	animauxReserves: string[] =[];

	// Vars optimisation
	nbAnimaux: number = 0;
	nbPages: number = 0;
	currentPage: number = 0;
	loading: boolean = true;

	today?: Date;
	todayP5?: Date;

	racesOptions: string[] = [];
	raceControl = new FormControl();
	filteredRaceOptions?: Observable<string[]>;

  columnsOk: boolean = false;

	// format date
	private _datePipe: DatePipe = new DatePipe('fr-FR');
	
	iconsCSS = { sortAscending: 'face', sortDescending: 'glyphicon glyphicon-chevron-up', pagerLeftArrow: 'glyphicon glyphicon-chevron-left', pagerRightArrow: 'glyphicon glyphicon-chevron-right', pagerPrevious: 'glyphicon glyphicon-backward', pagerNext: 'glyphicon glyphicon-forward' };
  
  columns: [{ prop: string; name: string; width: number; frozenLeft: boolean;}] = [{prop: 'oui', name: 'CHARGEMENT', width: 1000, frozenLeft: true}];

  mainColumns = ['statut', 'dateDerniereEntree', 'dateAdoptable', 'site', 'nom'];

  changeAnimaux = false;
  
  /* = [
			{ prop: 'site', name: "Site", width: 70 },
			{ prop: 'auRefuge', name: "Au refuge", width: 75, pipe: this.booleanPipe() },
			{ prop: 'Reservation', name: "Réservé", width: 70, pipe: this.booleanPipe() },
			{ prop: 'dateAdoptable', name: "Date Adoptable", width: 110, pipe: this.datePipe() },
			{ prop: 'identificationAnimal', name: "Identification", width: 140 },
			{ prop: 'nom', name: "Nom", width: 110, frozenLeft: true  },
			{ prop: 'race', name: "Race", width: 200  },
			{ prop: 'sexe',  	name: 'sexe', width: 70 }, 
			{ prop: 'dateNaissance',	name: 'Date de naissance', width: 120, pipe: this.datePipe() }, 
			{ prop: 'dateDerniereEntree',	name: 'Dernière entrée', width: 120, pipe: this.datePipe() }, 
			{ prop: 'dateDerniereSortie',	name: 'Dernière sortie', width: 120, pipe: this.datePipe() },
			{ prop: 'numContrat', name: "N° de contrat", width: 120},
			{ prop: 'dateVacc1',	name: 'Vaccin 1', width: 95, pipe: this.datePipe() },
			{ prop: 'rappelVacc1',	name: 'Rappel 1', width: 95, pipe: this.datePipe() },
			{ prop: 'dateVacc2',	name: 'Vaccin 2', width: 95, pipe: this.datePipe() },
			{ prop: 'rappelVacc2',	name: 'Rappel 2', width: 95, pipe: this.datePipe() },
			{ prop: 'dateVacc3',	name: 'Vaccin 3', width: 95, pipe: this.datePipe() },
			{ prop: 'rappelVacc3',	name: 'Rappel 3', width: 95, pipe: this.datePipe() },
			];*/

  constructor(
	private apiService: ApiService,
	private router: Router,
	private route: ActivatedRoute,
	public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (+this.route.snapshot.paramMap.get('id')! !== this.typeAnimaux && !this.changeAnimaux) {
        this.changeAnimaux = true;

        this.animals = [];
        this.fullData = [];
        this.filtres = {};
        this.animauxSpeciaux = [];
        this.animauxReserves =[];
        this.racesOptions = [];

        this.nbAnimaux = 0;
        this.nbPages = 0;
        this.currentPage = 0;
        this.loading = true;
        
        this.typeAnimaux = +(this.route.snapshot.paramMap.get('id') || "0");
    
        this.today = new Date();
        this.today.setHours(0,0,0,0);
        this.todayP5 = new Date();
        this.todayP5.setDate(this.todayP5.getDate()+5);
        this.todayP5.setHours(0,0,0,0);

        this.filtres['typeAnimaux'] = ''+this.typeAnimaux;
        this.filtres['nomAnimal'] = '';
        this.filtres['statutAnimal'] = '';
        this.filtres['race'] = '';
        this.filtres['identificationAnimal'] = '';
        this.filtres['Sexe'] = '';
        this.filtres['Age'] = '';
        this.filtres['dateNaissance1'] = '';
        this.filtres['dateNaissance2'] = '';
        this.filtres['numeroCession'] = '';
        this.filtres['numContratAdoption'] = '';
        this.filtres['localite'] = '';
        this.filtres['VilleAdoptant'] = '';
        this.filtres['dateDerniereEntree1'] = '';
        this.filtres['dateDerniereEntree2'] = '';
        this.filtres['rappelDans'] = '';
        this.filtres['sterilisation'] = '';
        this.filtres['Local'] = '';

        this.animals = this.fullData;

        this.currentPage = 0;
        this.filtres['statutAnimal'] = 'auRefuge';
        
        this.getDropdownOptions();

        this.filteredRaceOptions = this.raceControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterRace(value))
          );
          
        this.getAnimaux60(this.currentPage);
      }
    });

    this.typeAnimaux = +(this.route.snapshot.paramMap.get('id') || "0");
    
    this.today = new Date();
    this.today.setHours(0,0,0,0);
    this.todayP5 = new Date();
    this.todayP5.setDate(this.todayP5.getDate()+5);
    this.todayP5.setHours(0,0,0,0);

    this.filtres['typeAnimaux'] = ''+this.typeAnimaux;
    this.filtres['nomAnimal'] = '';
    this.filtres['statutAnimal'] = '';
    this.filtres['race'] = '';
    this.filtres['identificationAnimal'] = '';
    this.filtres['Sexe'] = '';
    this.filtres['Age'] = '';
    this.filtres['dateNaissance1'] = '';
    this.filtres['dateNaissance2'] = '';
    this.filtres['numeroCession'] = '';
    this.filtres['numContratAdoption'] = '';
    this.filtres['localite'] = '';
    this.filtres['VilleAdoptant'] = '';
    this.filtres['dateDerniereEntree1'] = '';
    this.filtres['dateDerniereEntree2'] = '';
    this.filtres['rappelDans'] = '';
    this.filtres['sterilisation'] = '';
    this.filtres['Local'] = '';

    this.animals = this.fullData;

    this.currentPage = 0;
    this.filtres['statutAnimal'] = 'auRefuge';
    
    this.getDropdownOptions();

    this.filteredRaceOptions = this.raceControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterRace(value))
      );

    this.getAnimaux60(this.currentPage);
  }
  
  private _filterRace(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.racesOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  
  getDropdownOptions(): void {
	  
	  // Races
	this.apiService.getRaces()
		.subscribe(options => {
			console.log(options);
			console.log(this.typeAnimaux);

			options.forEach( (option) => {
					if (option.animalType === this.typeAnimaux){
						this.racesOptions!.push(option.race);
					}
			  });
			
			  console.log(this.racesOptions);
		});
	  
  }

  onSelect(event: any): void{

    if (event['type'] == 'click') {

      const dialogPosition: DialogPosition = {
        left: '15vw'
      };

      const dialogRef = this.dialog.open(AnimalDetailsComponent, {
        data: event['row']['id'],
        height: '80vh',
        width: '80vw',
        position: dialogPosition,
        disableClose: true,
        autoFocus: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.getAnimaux60(this.currentPage);
      });
    }

    /*if (event['type'] == 'click') {
      this.router.navigate(['/details-chat/'+ event['row']['id']]);
    }*/
  }

  getCompteAnimaux(){
    this.apiService.getCompteAnimauxListe(this.filtres)
    .subscribe(nb => {
      this.nbAnimaux = nb[0].nbAnimaux;
      this.nbPages = Math.ceil(this.nbAnimaux/60);
    });
  }

  getColonnesAnimaux(){
    this.apiService.getColonnes(+localStorage.getItem('refuge')!, this.typeAnimaux).subscribe(
      x => {
        const colonnes: string = x[0].colonnes;
        const colonnesSplit = colonnes.split(" ");
        let tempCol: [{ prop: string; name: string; width: number; frozenLeft: boolean;}] = [{prop: 'oui', name: 'non', width: 1, frozenLeft: true}];
        colonnesSplit.forEach(colonne => {
          let bool: boolean = false;
          let name: string = "";
          if (this.mainColumns.includes(colonne)) {
            bool = true;
          }
          colonne.split(/(?=[A-Z])/).forEach(x => {
            name += x.toUpperCase() + " ";
          });
          tempCol.push({ prop: colonne, name: name, width: 200, frozenLeft: bool })
        });
        tempCol.shift();
        this.columns = tempCol;
      },
      err => console.log(err),
      () => {
        this.columnsOk = true;
        this.loading = false;
        this.changeAnimaux = false;
      }
    )
  }

  datePipe1 () {
		return {
				transform: (value: string) => this._datePipe.transform(value, 'dd/MM/yyyy')
				};
	}

  datePipe2 () {
		return {
				transform: (value: string) => (new Date(value) >= this.today!) ? '<u><strong class="red">'+this._datePipe.transform(value, 'dd/MM/yyyy') + '</strong></u>' : ((new Date(value) <= this.todayP5!) ? '<u><strong>'+this._datePipe.transform(value, 'dd/MM/yyyy') + '</strong></u>' : this._datePipe.transform(value, 'dd/MM/yyyy'))
				};
	}

  censorPipe () {
    return {transform: (value: string) => "*******"};
  }

  statusPipe () {
		return { transform: (value: string) => (value == 'S. Spécial' || value == 'Réservé') ? '<strong>' + value + '</strong>' : value };
	}

  booleanPipe () {
		return {transform: (value: boolean) => (Boolean(Number(value)) == true) ? '✔️' : ''};
	}

  getAnimaux60(page: number): void {
	this.loading = true;
  this.columns = [{prop: 'oui', name: 'CHARGEMENT', width: 1000, frozenLeft: true}];
    this.apiService.getAnimaux60(page*60, this.filtres)
		  .subscribe(animals => {

	  		animals.forEach( (animal) => {

	  			if (animal.auRefuge == true) {

	  				animal['statut'] = 'Au refuge'

	  			}

	  			if (animal.reservation == true) {

	  				animal['statut'] = 'Réservé';
            //animal['nom'] = '<u><span class="blue">'+ animal['nom'] +'</span></u>';

	  			}

	  			if (animal.remisMaitre == true) {

	  				animal['statut'] = 'Remis M.'

	  			} else if (animal.familleAccueil == true) {

	  				animal['statut'] = 'FA'

	  			} else if (animal.vole == true) {

	  				animal['statut'] = 'Volé'

	  			} else if (animal.adopte == true) {

	  				animal['statut'] = 'Adopté'

	  			}

	  			if (animal.dcd == true) {

	  				animal['statut'] = 'Décédé'

	  			}

	  			if (animal.statusSpecial == true) {

	  				animal['statut'] = 'S. Spécial'
            //animal['nom'] = '<u><span class="red">'+ animal['nom'] +'</span></u>';

	  			}

          // PIPE fiv felv  ✔️' : '❌

          if (animal.dateFIV) {
            if (+animal.fiv == 1) {
              animal['fiv'] = '✔️';
            } else {
              animal['fiv'] = '❌';
            }

            if (+animal.felv == 1) {
              animal['felv'] = '✔️';
            } else {
              animal['felv'] = '❌';
            }
          } else {
            animal['fiv'] = '';
            animal['felv'] = '';
          }

          //Gestion dates rappel
          if (animal.rappelVacc3) {
            if (new Date(animal.rappelVacc3) <= this.today!) {
              animal['rappelVacc3'] = '<u><strong class="red">'+this._datePipe.transform(animal.rappelVacc3, 'dd/MM/yyyy') + '</strong></u>';
            } else if (new Date(animal.rappelVacc3) <= this.todayP5!) {
              animal['rappelVacc3'] = '<u><strong>'+this._datePipe.transform(animal.rappelVacc3, 'dd/MM/yyyy') + '</strong></u>';
            } else {
              animal['rappelVacc3'] = this._datePipe.transform(animal.rappelVacc3, 'dd/MM/yyyy');
            }
            animal['rappelVacc2'] = this._datePipe.transform(animal.rappelVacc2, 'dd/MM/yyyy');
            animal['rappelVacc1'] = this._datePipe.transform(animal.rappelVacc1, 'dd/MM/yyyy');
          } else if (animal.rappelVacc2) {
            if (new Date(animal.rappelVacc2) <= this.today!) {
              animal['rappelVacc2'] = '<u><strong class="red">'+this._datePipe.transform(animal.rappelVacc2, 'dd/MM/yyyy') + '</strong></u>';
            } else if (new Date(animal.rappelVacc2) <= this.todayP5!) {
              animal['rappelVacc2'] = '<u><strong>'+this._datePipe.transform(animal.rappelVacc2, 'dd/MM/yyyy') + '</strong></u>';
            } else {
              animal['rappelVacc2'] = this._datePipe.transform(animal.rappelVacc2, 'dd/MM/yyyy');
            }
            animal['rappelVacc1'] = this._datePipe.transform(animal.rappelVacc1, 'dd/MM/yyyy');
          } else if (animal.rappelVacc1) {
            if (new Date(animal.rappelVacc1) <= this.today!) {
              animal['rappelVacc1'] = '<u><strong class="red">'+this._datePipe.transform(animal.rappelVacc1, 'dd/MM/yyyy') + '</strong></u>';
            } else if (new Date(animal.rappelVacc1) <= this.todayP5!) {
              animal['rappelVacc1'] = '<u><strong>'+this._datePipe.transform(animal.rappelVacc1, 'dd/MM/yyyy') + '</strong></u>';
            } else {
              animal['rappelVacc1'] = this._datePipe.transform(animal.rappelVacc1, 'dd/MM/yyyy');
            }
          }

          if (animal.rappelVermifuge) {
            if (animal.vermifugePasseport != 1) {
              if (new Date(animal.rappelVermifuge) <= this.today!) {
                animal['rappelVermifuge'] = '<u><strong class="red">'+this._datePipe.transform(animal.rappelVermifuge, 'dd/MM/yyyy') + '</strong></u>';
              } else if (new Date(animal.rappelVermifuge) <= this.todayP5!) {
                animal['rappelVermifuge'] = '<u><strong>'+this._datePipe.transform(animal.rappelVermifuge, 'dd/MM/yyyy') + '</strong></u>';
              } else {
                animal['rappelVermifuge'] = this._datePipe.transform(animal.rappelVermifuge, 'dd/MM/yyyy');
              }
            } else {
              animal['rappelVermifuge'] = this._datePipe.transform(animal.rappelVermifuge, 'dd/MM/yyyy');
            }

          }

          if (animal.rappelAntipuce) {
            if (animal.antipucePasseport != 1) {
              if (new Date(animal.rappelAntipuce) <= this.today!) {
                animal['rappelAntipuce'] = '<u><strong class="red">'+this._datePipe.transform(animal.rappelAntipuce, 'dd/MM/yyyy') + '</strong></u>';
              } else if (new Date(animal.rappelAntipuce) <= this.todayP5!) {
                animal['rappelAntipuce'] = '<u><strong>'+this._datePipe.transform(animal.rappelAntipuce, 'dd/MM/yyyy') + '</strong></u>';
              } else {
                animal['rappelAntipuce'] = this._datePipe.transform(animal.rappelAntipuce, 'dd/MM/yyyy');
              }
            } else {
              animal['rappelAntipuce'] = this._datePipe.transform(animal.rappelAntipuce, 'dd/MM/yyyy');
            }

          }

			  }); 


			  this.fullData = animals
			  this.animals = animals

        console.log(this.fullData);

		  },
      err => console.log(err),
      () => {
        //this.updateFilter();
        this.getCompteAnimaux();
        this.getColonnesAnimaux();
      }
    );

	/*this.apiService.getAnimaux()
	  .subscribe(animals => {

		  this.fullData = animals;
		  this.animals = animals;
		  console.log(this.fullData);

			this.filtres['animalType'] = 'autre';
			this.updateFilter()

	  })*/
  }
  
  resetFilter(): void {
	 
		this.filtres['nomAnimal'] = '';
		this.filtres['statutAnimal'] = '';
		this.filtres['race'] = '';
		this.filtres['identificationAnimal'] = '';
		this.filtres['Sexe'] = '';
		this.filtres['Age'] = '';
		this.filtres['dateNaissance1'] = '';
		this.filtres['dateNaissance2'] = '';
		this.filtres['numeroCession'] = '';
		this.filtres['numContratAdoption'] = '';
		this.filtres['localite'] = '';
		this.filtres['VilleAdoptant'] = '';
		this.filtres['dateDerniereEntree1'] = '';
		this.filtres['dateDerniereEntree2'] = '';
		this.filtres['rappelDans'] = '';
		this.filtres['sterilisation'] = '';
		this.filtres['Local'] = '';
		
		this.animals = this.fullData;

    	this.currentPage = 0;
    	this.getAnimaux60(this.currentPage);
	  
  }
  
  updateFilter() {
	  
	/*var temp = this.fullData;
	  
	  for (let key in this.filtres) {
		  
		let value = this.filtres[key];
		
		if (value != '') {
			
			var val = value;
			var key_ = key;
			
			if (key == 'dateNaissance1' || key == 'dateNaissance2') {
				key_ = 'dateNaissance';				
			} else if (key != 'statutAnimal') {
				val = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
			}
			
			temp = temp.filter(function (d: Animal) {
				
			if (key_ == 'statutAnimal' || (d as any)[key_] != undefined) {
				  
				  if (key_ == 'dateNaissance') {
						  
				    let splt = val.split('/')
					  var valObj = new Date(+splt[2], +splt[1]-1, +splt[0]);
					  valObj.setHours(0,0,0,0);
					  
					  var valTab = new Date((d as any)[key_]);
					  valTab.setHours(0,0,0,0);
					  
					  //console.log(valObj);
					  //console.log(valTab);
					  
					  return (key == 'dateNaissance1') ? valTab! > valObj! : valTab! < valObj!;
						
					
					} else if (key_ == 'statutAnimal') {

				  	if (val == 'auRefuge/FA') {

				  		let val1 = 'auRefuge'
				  		let val2 = 'familleAccueil'


				  		return (Boolean(Number((d as any)[val1])) || Boolean(Number((d as any)[val2])));

				  	} else {

							return Boolean(Number((d as any)[val]));

				  	}

				  } else {
						
						return (d as any)[key_].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(val) !== -1 || !val;
				 	}
			  
			  }
			  
			  return false;
			
			});
			
		}
		
	  }

    this.animals = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;*/

	  this.currentPage = 0;
    this.getAnimaux60(this.currentPage);
  }

  onPaginated(event : number){

    switch (event) {
      case 1:
        if (this.currentPage != 0) {
          this.currentPage = 0;
          this.getAnimaux60(this.currentPage);
        }
        break;

      case 2:
        if (this.currentPage > 1) {
          this.currentPage -= 2;
          this.getAnimaux60(this.currentPage);
        }
        break;

      case 3:
        if (this.currentPage > 0) {
          this.currentPage -= 1;
          this.getAnimaux60(this.currentPage);
        }
        break;

      case 4:
        if (this.currentPage < this.nbPages-1) {
          this.currentPage += 1;
          this.getAnimaux60(this.currentPage);
        }
        break;

      case 5:
        if (this.currentPage < this.nbPages-2) {
          this.currentPage += 2;
          this.getAnimaux60(this.currentPage);
        }
        break;

      case 6:
        if (this.currentPage != this.nbPages-1) {
          this.currentPage = this.nbPages-1;
          this.getAnimaux60(this.currentPage);
        }
        break;
      default:
        break;
    }

  }

}