<div class="row" *ngIf="don">

<div class="col-md-6">
	<div class="card card-user">
	  <div class="card-header">
		<h5 class="card-title">Détails du don</h5>
	  </div>
	  <div class="card-body">
			
			
		<div class="col-md-12">
		  <form>
		  
		  <div class="row">
		  
		  <div class="col-md-4">
			
			  <div class="form-group">
					<label>Nom donateur</label>
					
						<input disabled type="text"
								class="form-control"
							   name="Nom" [(ngModel)]="don.nom">
				  
				</div>		  
		  </div>		  
		  
		  <div class="col-md-4">
			
			  <div class="form-group">
					<label>Prénom donateur</label>
					
						<input disabled type="text"
								class="form-control"
							   name="prenom" [(ngModel)]="don.prenom">
				  
				</div>		  
		  </div>		  
		
		
			  
		  </div>
			
			<div class="row">
			
			<div class="col-md-4">

			  <div class="form-group">
				<label>Payement</label>
				
				<select name="typeDon" class="form-control" 
					[(ngModel)]="don.typeDon" (change)="updateField('dons', 'typeDon')">
					
				  <option value="Banque">Banque</option>
				  <option value="Accueil cash">Accueil cash</option>
				  <option value="Accueil BC">Accueil BC</option>
				  <option value="Paypal">Paypal</option>
				  <option value="Facebook">Facebook</option>
				  <option value="Autre">Autre</option>
				</select>
			  </div>
			  </div>
			
				<div class="col-md-4 pr-1">
				
				  <div class="form-group">
					<label>Montant (€)</label>
					<input type="text" name="montantDon" class="form-control" [(ngModel)]="don.montantDon" (change)="updateField('dons', 'montantDon')">
				  </div>
				</div>

				
				<div class="col-md-3">		
				  <div class="form-group">
					<label>Date du don</label>
					<input type="text" name="dateNaissance" class="form-control" readonly matInput [matDatepicker]="dateNaissance" [(ngModel)]="don.dateDon" (dateChange)="updateField('dons', 'dateDon')">
					
				  </div>		  
				</div>
				
				<div class="col-md-1">	
				<label class="datePickerIconLabel" style="color:white">_____ </label>
				<mat-datepicker-toggle matSuffix [for]="dateNaissance" ></mat-datepicker-toggle>
					<mat-datepicker #dateNaissance></mat-datepicker>
				</div>
		</div>
		
		</form>

		</div>
			
	</div>
</div>
</div>
</div>



<div class="row" *ngIf="(niveauAcces == 'Direction' || niveauAcces == 'Administratif')">


	<div class="col-md-12">
	
	  <div class="card-footer">

		<mat-dialog-actions>
		    <button [color]="deleteConfirm" mat-raised-button (click)="delete('dons', 'id', id!);">Supprimer</button>
		</mat-dialog-actions>


		  </div>

		</div>


</div>