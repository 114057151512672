<div class="row">



<div class="col-md-6">

	<div class="card card-user">

	  <div class="card-header">
		<h5 class="card-title">Configuration rapport</h5>
	  </div>

	  <div class="card-body">

	  <div class="row">


		  <div class="col-md-6">
				<div class="form-group">
					<label>Rapport</label>

					<select name="sortie" class="form-control"
						[(ngModel)]="type">

					  <option value="1">Adoptions</option>
					  <option value="2">Entrées</option>
					  <option value="3">Pensionaires / FA</option>
					  <option value="4">Nouveaux donateurs</option>
					  <option value="5">Visites</option>
					  <option value="6">Infos clients et dons</option>
					  <option value="7">Attestations donations</option>

					</select>
				  </div>
				  </div>

			<div class="col-md-6" *ngIf="type < 4">
				<div class="form-group">
					<label>Animaux</label>

					<select name="sortie" class="form-control"
						[(ngModel)]="animals">

					  <option value="tous">Tous</option>
					  <option value="chat">Chat</option>
					  <option value="chien">Chien</option>
					  <option value="autre">Autre</option>

					</select>
				  </div>
				  </div>
	  </div>

	  <div class="row" *ngIf="type == 5">


		  <div class="col-md-2 pr-1">

				<div class="form-group">
					<label>Visites réalisées</label>
					<input type="checkbox" name="croisement" class="form-control" [(ngModel)]="visitesRealisees" >
				  </div>

			</div>


			<div class="col-md-2 pr-1">

				<div class="form-group">
					<label>Dossier envoyé</label>
					<input type="checkbox" name="croisement" class="form-control" [(ngModel)]="dossierEnvoye" >
				  </div>

			</div>

			<div class="col-md-2 pr-1">
			</div>


			<div class="col-md-6">
					<div class="form-group">
					<label>Bénévole</label>

						<input type="text" class="form-control" name="benevole"
							   [formControl]="benevolesControl"
							   [matAutocomplete]="auto5">

						<mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">
						  <mat-option *ngFor="let option of filteredBenevolesOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>

				</div>
				</div>
	  </div>

	  <div class="row"  *ngIf="type < 6 || type == 7">
	  	<div class="col-md-5">
				  <div class="form-group">
					<label>Du</label>
					<input type="date" name="firstD" class="form-control" matInput
					[(ngModel)]="firstDay"><!--[matDatepicker]="firstD"-->

				  </div>
				</div>
        <!--
          <div class="col-md-1 pl-1">
				<label style="color:white">_____</label>
				<mat-datepicker-toggle matSuffix [for]="firstD" ></mat-datepicker-toggle>
					<mat-datepicker #firstD></mat-datepicker>
				</div>
        -->


			<div class="col-md-5">
				  <div class="form-group">
					<label>Au</label>
					<input type="date" name="today" class="form-control" matInput
					[(ngModel)]="today2"> <!--[matDatepicker]="today"-->

				  </div>
				</div>
        <!--
          <div class="col-md-1 pl-1">
				<label style="color:white">_____</label>
				<mat-datepicker-toggle matSuffix [for]="today" ></mat-datepicker-toggle>
					<mat-datepicker #today></mat-datepicker>
				</div>
        -->

	</div>

	<div class="row  mb-3">



		  <div class="col-md-3 pc-1">

			<label style="color:white">_____</label>
			<button  mat-raised-button (click)="genererRapport()"><mat-icon aria-hidden="false" fontSet="material-icons-outlined">addchart</mat-icon><span style="color:white">_</span>Générer rapport</button>

		  </div>



	  </div>



	  </div>
	  </div>
	  </div>




	  </div>
