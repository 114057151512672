export class Adoption {

	id!: number;
	Idanimal!: number;
	numContrat!: number;
	changementContrat!: boolean;
	dateEnvoiSMS!:Date;
	dateEnvoiDossier!:Date;
	benevoleAppel!:string;
	dateAppel!:Date;
	avisAppel!:string;
	deuxiemeAppelNecessaire!: boolean;
	commentaireAppel!:string;
	dateEnvoiDossierInsp!:Date;
	benevoleInsp!:string;
	dateInspection!:Date;
	avisInspection!:string;
	commentaireInspection!:string;
	secondeVisite!:boolean;
	suiviUrgent!:boolean;
	typeSuiviUrgent!:string;
	dateSuiviUrgent!:Date;
	dateEnvoiDossierInsp2!:Date;
	benevoleInsp2!:string;
	dateInspection2!:Date;
	avisInspection2!:string;
	commentaireInspection2!:string;

}