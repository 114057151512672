import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from '../api.service';

import { Login } from '../login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	log: Login = new Login();
	result: string = "";

	mode = true;

  constructor(
	private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }

  switchMode() {
	this.mode = !this.mode;
  }

  connexion(): void {

	  this.apiService.login(this.log)
		.subscribe(options => {

			if (options.login == "ERROR_MDP") {

				this.result = "mot de passe incorrecte.";

			} else if (options.login == "ERROR_LOGIN") {

				this.result = "identifiant iconnu.";

			} else {

				localStorage.setItem('auth', options.auth);
				localStorage.setItem('level', options.mdp);
				localStorage.setItem('login', options.login);
				localStorage.setItem('refuge', options.refuge.toString());

				this.apiService.getCompteAnimaux().subscribe(compte => {

					console.log(compte);
					let cpt = 0;
					compte.forEach(c => {
							cpt += +c.nbr;
							localStorage.setItem('animal'+c.animalType.toString(), c.nbr.toString());
						}
					)

					localStorage.setItem('cptAnimal', cpt.toString());

					console.log(localStorage)

				});

				this.apiService.activityLogging("Connexion").subscribe();
				this.router.navigateByUrl("/rechercher");

			}

		},
    err => console.log(err),
    () => console.log("wow"));

  }

}
