import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ApiService } from './api.service';
import { CompteAnimaux } from './compteanimaux';
import { AnimalType } from './animalType';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  currentPage = '';
  title = '';

  connected: boolean = false;
  niveauAcces: string = "";
  login: string = "";
  comptage:string = '' ;

  idRefuge: number = 0;
  nomRefuge: string = '';
  imgRefuge: string = '';

  imageToShow: any;
  isImageLoading = true;

  animalTypes: AnimalType[] =[];
  
  loading = false;

  constructor(
		private route: ActivatedRoute,
  	private router: Router,
		private apiService: ApiService,
	) {}


	 ngOnInit() {

		this.router.events.subscribe(event => {
			if (localStorage.getItem('refuge') && this.loading === false) {
				this.idRefuge = +localStorage.getItem('refuge')!;
				console.log("bonjour");
				this.loading = true;
				this.getRefugeID();
			}

			if(event instanceof NavigationEnd) {

				this.currentPage = this.route.root.firstChild!.snapshot.data['page'];
				this.title = this.route.root.firstChild!.snapshot.data['title'];

				if (!localStorage.getItem('auth')) {

						this.connected = false;
						this.niveauAcces = '';

						localStorage.removeItem('auth');
						localStorage.removeItem('level');
						localStorage.removeItem('login');
						localStorage.removeItem('refuge');

					} else {

						this.connected = true;
						this.niveauAcces = localStorage.getItem('level')!;
						this.login = localStorage.getItem('login')!;
						
						this.comptage = localStorage.getItem('cptAnimal')! + ' animaux au refuge';

					}

			}

		});

	}

	getRefugeID() {
		this.apiService.getRefugeID(this.idRefuge).subscribe(
			refuge => {
				this.nomRefuge = refuge[0].nom;
				this.imgRefuge = refuge[0].photo;

				this.apiService.getRefugeAnimalTypes(this.idRefuge).subscribe(
					x => {
						this.animalTypes = [];
						x.forEach(element => {
							this.animalTypes.push(element);
						});
					}
				);

				setTimeout(() => {
					this.comptage = localStorage.getItem('cptAnimal')! + ' animaux au refuge';
				}, 1000);
				

				if(this.imgRefuge) {
					this.apiService.getImage(this.imgRefuge).subscribe(
						x => {
							this.createImageFromBlob(x);
							this.isImageLoading = false;
						}, error => {
							this.isImageLoading = false;
							console.log(error);
						}
					);              
				}
			},
			err => console.log(err),
			() => {
				this.loading = false;
			}
		)
	}

	createImageFromBlob(image: Blob) {
	let reader = new FileReader();
	reader.addEventListener("load", () => {
		this.imageToShow = reader.result;
	}, false);
	
	if (image) {
		reader.readAsDataURL(image);
	}
	}

	go(direction: string): void {

		if (direction == 'forward') {

			window.history.forward();


		} else {

			window.history.back();

		}

	}

	goTo(path: string): void {

		this.router.navigateByUrl('/'+path);

	}

	logOut(): void {
		localStorage.removeItem('auth');
		localStorage.removeItem('level');
		localStorage.removeItem('login');
		localStorage.removeItem('refuge');

		this.router.navigateByUrl('/connexion');
	}



}
