<div class="row">

<div class="col-md-9">
	<div class="card card-user">
	  <div class="card-header">
		<h5 class="card-title">Liste des ayant-accès</h5>
	  </div>
	  <div class="card-body">

<div>
  <ngx-datatable  class="material" [rows]="clients" [columns]="columns"
  (activate)="onSelect($event)" [rowHeight]="40" [limit]="10" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">

  </ngx-datatable>
</div>

</div>
</div>
</div>

<div class="col-md-3">
	<div class="card card-user">
	  <div class="card-body">
	  
		  <div class="card-header">
			<h5 class="card-title">Nouvel accès</h5>
		  </div>
		  
		  
		  <form id="form-fitler">
		  
			<div class="col-md-12">
			
			<div class="row">			
				<div class="col-md-12">
					<div class="form-group">
					<label>Client</label>
					
						<input type="text"
								class="form-control"
							   name="adoptant"
							   [formControl]="clientsControl"
							   [matAutocomplete]="auto7">
							   
						<mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete" (optionSelected)="setSelectedClient()">
						  <mat-option *ngFor="let option of filteredClientsOptions | async" [value]="option">
							{{option}}
						  </mat-option>
						</mat-autocomplete>
				  
				</div>			
				</div>			
			</div>
			  
			  <div class="form-group">
				<label>Niveau d'accès</label>
				
				<select name="filtreStatut" class="form-control" 
					[(ngModel)]="client.niveauAcces">
					
					<option value="Direction">Direction</option>
					  <option value="Administratif">Administratif</option>
					  <option value="SBA">SBA </option>
					  <option value="Accueil 1">Accueil 1</option>
					  <option value="Accueil 2">Accueil 2</option>
					  <option value="Accueil 3">Accueil 3</option>
					  <option value="Accueil 4">Accueil 4</option>
				  
				</select>
			  </div>

			 <div class="form-group">
				<label>Email (pour envoie lien d'activation)</label>
				<input type="text" name="filtreNom" class="form-control" [(ngModel)]="client.email">
			  </div>
			  
			  
			   <div class="row" *ngIf="client.niveauAcces && client.email">
			<div class="update ml-auto mr-auto">
			  <button type="submit" class="btn btn-primary btn-round" (click)="creerAcces()">Créer accès</button>
			</div>
			</div>
		  
		  
		  </div>
		</form>
	

</div>
</div>
</div>

</div>
