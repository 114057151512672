<div class="row">
	<div *ngIf="mode; else elseBlock" style="width: 100%;">
		<div class="col-md-4">
			<div class="card card-user">
				<div class="card-body">
					
					<div class="card-header">
						<h5 class="card-title">Veuillez vous identifier</h5>
					</div>
				
					<div class="alert alert-warning alert-dismissible fade show" *ngIf="result">
						<span><b> Erreur - </b> {{result}}</span>
					</div>
				
					<form id="form-fitler">
						<div class="col-md-12">
							<div class="form-group">
								<label>Identifiant</label>
								<input type="text" name="filtreNom" class="form-control" [(ngModel)]="log.login">
							</div>
					
							<div class="form-group">
								<label>Mot de passe</label>
								<input type="password" name="filtreNom" class="form-control" [(ngModel)]="log.mdp">
							</div>
					
							<div class="row">
								<div class="update ml-auto mr-auto">
									<button type="submit" class="btn btn-primary btn-round" (click)="connexion()">Connexion</button>
								</div>
								<div class="update ml-auto mr-auto">
									<button type="submit" class="btn btn-primary btn-round" style="opacity: 0.75;" (click)="switchMode()">Créer un compte</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	<ng-template #elseBlock>
		<mat-icon style="font-size: xx-large; color: black; cursor: pointer; margin-bottom: 15px;" (click)="switchMode()">arrow_backward</mat-icon>
		<nouveau-user></nouveau-user>
	</ng-template>
</div>