<div class="row">
  <div class="col-md-12">
    <div class="card card-user">
      <div class="card-header">
      </div>

      <div class="card-body">

        <div class="row mb-5">
          <mat-tab-group style="width: 100%;" (selectedTabChange)="onChangeTab()">

            <mat-tab label="Profil">
              <div class="row">
                <div class="col-md-6">
                  <div class="card card-user">
                    <div class="card-header">
                
                      <div class="row">
                        <div class="col-md-8">
                          <h5 class="card-title">Informations refuge</h5>
                        </div>
                      </div>
                
                    </div>
  
                    <div class="card-body">
                      <form>
                        <div class="row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <div style="display: flex; justify-content: space-between; align-items: center;">
                                <form method="post" enctype="multipart/form-data" style="width: 350px;">
                                  <input id="upload" style="opacity: 100; position: relative;" type="file" name="img_file" class="form-control" accept=".jpg" title="Upload PHOTO" (change)="handleFileInput($event)"/>
                                </form>	
                              
                                <button mat-raised-button (click)="onSubmit()">OK</button>
                              </div>
                              <div style="display: flex; align-items: center; width: 100%; justify-content: center;">
                                <img [src]="imageToShow"
                                alt="Place image title"
                                *ngIf="!isImageLoading; else noImageFound" style="margin-top: 15px; max-height: 150px; border-radius: 25px;">
                                <ng-template #noImageFound>
                                  <img src="" alt="Aucune image">
                                </ng-template>	
                              </div>
                              
                            </div>	
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Nom du refuge</label>
                              <input type="text" class="form-control" name="Nom" [(ngModel)]="refuge.nom" (focusout)="updateField('refuge', 'nom')">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Nom complet</label>
                              <input type="text" class="form-control" name="Nom complet" [(ngModel)]="refuge.nomComplet" (focusout)="updateField('refuge', 'nomComplet')">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Adresse</label>
                              <input type="text" class="form-control" name="Adresse" [(ngModel)]="refuge.adresse" (focusout)="updateField('refuge', 'adresse')">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Tel</label>
                              <input type="text" class="form-control" name="Tel" pattern="[0-9]{10}" [(ngModel)]="refuge.tel" (focusout)="updateField('refuge', 'tel')">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Mail</label>
                              <input type="text" class="form-control" name="Mail" [(ngModel)]="refuge.mail" (focusout)="updateField('refuge', 'mail')">
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Site Web</label>
                              <input type="text" class="form-control" name="siteWeb" [(ngModel)]="refuge.siteWeb" (focusout)="updateField('refuge', 'siteWeb')">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Numéro entreprise</label>
                              <input type="text" class="form-control" name="numEntreprise" [(ngModel)]="refuge.numEntreprise" (focusout)="updateField('refuge', 'numEntreprise')">
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Numéro agrément</label>
                              <input type="text" class="form-control" name="numAgrement" [(ngModel)]="refuge.numAgrement" (focusout)="updateField('refuge', 'numAgrement')">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Type de refuge</label>
                              <input type="text" class="form-control" name="typeRefuge" [(ngModel)]="refuge.typeRefuge" (focusout)="updateField('refuge', 'typeRefuge')">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Sites du refuge</label>
                              <div style="display: flex; justify-content: start;">
                                <form method="post" enctype="multipart/form-data" style="width: 100%;">
                                  <textarea id="site" style="opacity: 100; position: relative; height: 40px;" class="form-control" [(ngModel)]="site" [ngModelOptions]="{standalone: true}"></textarea>
                                </form>	
                                <button mat-raised-button (click)="onSubmitSite()">OK</button>
                              </div>
                              <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: center;" *ngFor="let site of sitesList; index as i;">
                                <span style="color: #9A9A9A; margin-right: 10px;">{{site}}</span>
                                <button mat-raised-button (click)="onDeleteSite(i)" style="color: #5c5c5c;"><mat-icon>delete</mat-icon></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">  
                            <label>Ajout d'animaux par tableur</label>
                            <input type="file" class="form-control" name="xlsUpload" accept=".xls,.xlsx,.csv" (change)="xlsUpload($event)">
                          </div>
                          <div class="col-md-6" style="text-align: center; display: flex; justify-content: flex-start; align-items: end;">
                            <button mat-raised-button (click)="getTemplate()">Télécharger modèle de tableur</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card card-user">
                    <div class="card-header">
                
                      <div class="row">
                        <div class="col-md-8">
                          <h5 class="card-title">Contrat d'adoption</h5>
                        </div>
                      </div>
                
                    </div>
  
                    <div class="card-body">

                      <div class="col-md-8">
                        
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.<br>
                        </span>

                        <div class="form-group" style="margin-top: 15px;">
                          <h6 class="card-title">Gérer les annexes du contrat</h6>
                          <label>Annexes du contrat</label>
                          <div style="display: flex; justify-content: start;">
                            <form method="post" enctype="multipart/form-data" style="width: 100%;">
                              <input id="upload" style="opacity: 100; position: relative;" type="file" name="pdf_file" class="form-control" accept=".pdf" title="Upload PDF" (change)="handleFileInputPdf($event)"/>
                            </form>	
                            <button mat-raised-button (click)="onSubmitPdf()">OK</button>
                          </div>
                          <div style="margin-top: 10px;" *ngFor="let pdf of pdfList; index as i;">
                            <span style="color: #9A9A9A; margin-right: 10px;">{{pdf}}</span>
                            <button mat-raised-button (click)="onDownload(i)" style="color: #5c5c5c; margin-right: 10px;"><mat-icon>file_download</mat-icon></button>
                            <button mat-raised-button (click)="onDelete(i)" style="color: #5c5c5c;"><mat-icon>delete</mat-icon></button>
                          </div>
                        </div>
                        
                      </div>

                      <body style="font-size:9.5">

                        <table><tr><td width="10%">
                          <img [src]="imageToShow"
                                alt="Place image title"
                                *ngIf="!isImageLoading; else noImageFound" style="margin-top: 15px; max-height: 150px; border-radius: 25px;">
                                <ng-template #noImageFound>
                                  <img src="" alt="Aucune image">
                                </ng-template>	
                        </td>
                              <td width="30%" class="changeable"><br />' NOM COMPLET '<br /></td>
                            </tr>
                        </table>
                        
                        <p style="text-align:right;">
                        <span class="changeable">' NOM DU REFUGE '</span> - <span class="changeable">' ADRESSE '</span><br />
                        Tel. <span class="changeable">' TEL '</span> - Mail. <a class="changeable">' MAIL '</a></p>
                        
                        <p><br/><br/></p>
                        <p><br/><br/></p>
                        
                        <p style="text-align:center;"><u>CONTRAT D&rsquo;ADOPTION</u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;N&deg;&nbsp;: ' Num&eacute;ro Contrat '</p>
                        
                        <p><br/><br/></p>
                        <p><br/><br/></p>
                        
                        <p style="text-align:left;">Contrat d&rsquo;adoption d&rsquo;un animal entre les soussign&eacute;s :</p>
                        
                        <p style="text-align:left;" class="changeable"><b>' NOM COMPLET '</b></p>
                        
                        <p style="text-align:justify;"><i> <span class="changeable">' TYPE DE REFUGE '</span>. Agr&eacute;&eacute; conform&eacute;ment &agrave; l&rsquo;AR du 27 avril 2007. Num&eacute;ro d&rsquo;entreprise: <span class="changeable">' NUM&Eacute;RO ENTREPRISE '</span> - Num&eacute;ro d&rsquo;agr&eacute;ment : <span class="changeable">' NUM&Eacute;RO AGR&Eacute;MENT '</span></i></p>
                        
                        <p>Si&egrave;ge social: <span class="changeable">' ADRESSE '</span> - Site Internet : <a class="c16" class="changeable">' SITE WEB '</a></p>
                      
                        <p><br/><br/></p>
                        
                        <table  width="100%" cellpadding="2" >
                          <tr cellpadding="5">
                            <td colspan="2" style="font-weight: bold;">L'ADOPTANT<br/></td>
                            <td colspan="2" style="font-weight: bold;">L'ANIMAL</td>
                          </tr>
                          <tr >
                            <td width="20%"><p style="font-style: italic;">M./Mme</p></td>
                            <td width="30%"><p class="c3">' Nom Client ', ' Prénom Client '</p></td>
                            <td width="20%"><p style="font-style: italic;">Un:</p></td>
                            <td width="30%"><p class="c3">' Type Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">Rue: </p></td>
                            <td><p class="c3">' Adresse Client '</p></td>
                            <td><p style="font-style: italic;">Race: </p></td>
                            <td><p class="c3">' Race Animal '</p></td>
                          </tr>
                          <tr>
                            <td><p style="font-style: italic;">Code Postal: </p></td>
                            <td><p class="c3">' Code Postal Client '</p></td>
                            <td><p style="font-style: italic;">Nom: </p></td>
                            <td><p class="c3">' Nom Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">Localité: </p></td>
                            <td><p class="c3">' Ville Client '</p></td>
                            <td><p style="font-style: italic;">Sexe: </p></td>
                            <td><p class="c3">' Sexe Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">Date de naissance: </p></td>
                            <td><p class="c3">' Date Naissance Client '</p></td>
                            <td><p style="font-style: italic;">Date de naissance: </p></td>
                            <td><p class="c3">' Date Naissance Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">N° RN:  </p></td>
                            <td><p class="c3">' Numéro Registre National Client '</p></td>
                            <td><p style="font-style: italic;">Identification: </p></td>
                            <td><p class="c3">' Numéro Identification Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">Téléphone 1: </p></td>
                            <td><p class="c3">' Numéro Téléphone Client 1 '</p></td>
                            <td><p style="font-style: italic;">N° de passeport: </p></td>
                            <td><p class="c3">' Numéro Passeport Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">Téléphone 2: </p></td>
                            <td><p class="c3">' Numéro Téléphone Client 2 '</p></td>
                            <td><p style="font-style: italic;">N° de décharge: </p></td>
                            <td><p class="c3">' Numéro de décharge Animal '</p></td>
                          </tr>
                          <tr>
                            <td ><p style="font-style: italic;">Mail: </p></td>
                            <td><p class="c3">' Mail Client '</p></td>
                            <td><p style="font-style: italic;">Signe(s) particulier: </p></td>
                            <td><p class="c3">' Signes Particuliers Animal '</p></td>
                          </tr>
                          <tr>
                            <td ></td>
                            <td></td>
                            <td><p style="font-style: italic;">Stérilisé: </p></td>
                            <td><p class="c3">' Stérilisation '</p></td>
                          </tr>		
                        </table>
                      
                      
                        <p class="c3 c7"><span class="c4 c11"></span></p><p class="c3"><span class="c11 c4"><b>ENGAGEMENTS DE L&rsquo;ADOPTANT - CLAUSES CONTRACTUELLES</b></span></p>
                        
                        <div class="form-group" style="margin-top: 30px;">
                          <label>Ajouter clauses du contrat</label>
                          <div style="display: flex; justify-content: start;">
                            <form method="post" enctype="multipart/form-data" style="width: 100%;">
                              <textarea id="clause" style="opacity: 100; position: relative;" class="form-control" [(ngModel)]="clauseAdoptant" [ngModelOptions]="{standalone: true}"></textarea>
                            </form>	
                            <button mat-raised-button (click)="onSubmitClause('engagementsAdoptant')">OK</button>
                          </div>
                          <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: center;" *ngFor="let clause of adoptantList; index as i;">
                            <span style="color: #9A9A9A; margin-right: 10px;">{{clause}}</span>
                            <button mat-raised-button (click)="onDeleteClause(i, 'engagementsAdoptant')" style="color: #5c5c5c;"><mat-icon>delete</mat-icon></button>
                          </div>
                        </div>

                        <p><b>ENGAGEMENTS DE <span class="changeable">' NOM DU REFUGE '</span></b></p>
                        
                        <div class="form-group" style="margin-top: 30px;">
                          <label>Ajouter clauses du contrat</label>
                          <div style="display: flex; justify-content: start;">
                            <form method="post" enctype="multipart/form-data" style="width: 100%;">
                              <textarea id="clause" style="opacity: 100; position: relative;" class="form-control" [(ngModel)]="clauseRefuge" [ngModelOptions]="{standalone: true}"></textarea>
                            </form>	
                            <button mat-raised-button (click)="onSubmitClause('engagementsRefuge')">OK</button>
                          </div>
                          <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: center;" *ngFor="let clause of refugeList; index as i;">
                            <span style="color: #9A9A9A; margin-right: 10px;">{{clause}}</span>
                            <button mat-raised-button (click)="onDeleteClause(i, 'engagementsRefuge')" style="color: #5c5c5c;"><mat-icon>delete</mat-icon></button>
                          </div>
                        </div>

                        <p><b>AUTRES CLAUSES DU CONTRAT</b></p>

                        <div class="form-group" style="margin-top: 30px;">
                          <label>Ajouter clauses du contrat</label>
                          <div style="display: flex; justify-content: start;">
                            <form method="post" enctype="multipart/form-data" style="width: 100%;">
                              <textarea id="clause" style="opacity: 100; position: relative;" class="form-control" [(ngModel)]="clauseAutre" [ngModelOptions]="{standalone: true}"></textarea>
                            </form>	
                            <button mat-raised-button (click)="onSubmitClause('engagementsAutres')">OK</button>
                          </div>
                          <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: center;" *ngFor="let clause of autreList; index as i;">
                            <span style="color: #9A9A9A; margin-right: 10px;">{{clause}}</span>
                            <button mat-raised-button (click)="onDeleteClause(i, 'engagementsAutres')" style="color: #5c5c5c;"><mat-icon>delete</mat-icon></button>
                          </div>
                        </div>
                        
                        <p class="c2"><span class="c0"></span></p><p class="c2"><span class="c0"></span></p>
                        
                        <p style="text-align:justify;"><p><span class="c4"><b>PARTICIPATION AUX FRAIS</b></span><span>&nbsp;(cotisation, frais v&eacute;t&eacute;rinaires, frais administratifs, enregistrement, ...) <span class="changeable">' PAF '</span>€ TVAC</span></p><p class="c3"><span class="c10">Les Parties d&eacute;clarent avoir parcouru ensemble la liste des questions &agrave; se poser avant l&rsquo;acquisition d&rsquo;un chien, sa d&eacute;claration de cession et sa fiche d&rsquo;appr&eacute;ciation du comportement au refuge et avoir ainsi pris connaissance de certaines des particularit&eacute;s du chien. </span></p><p class="c3 c7"><span class="c10"></span></p><p class="c3"><span class="c10">Par la signature du contrat d&#39;adoption, l&#39;adoptant acquiert la qualit&eacute; de &quot;gardien &quot;de l&#39;animal au sens de l&#39;article 1384 du Code civil. <span class="changeable">' NOM DU REFUGE '</span> ne pourra en aucun cas &ecirc;tre tenue responsable des agissements de l&#39;animal quels qu&#39;ils soient et quelle qu&#39;en soit la cause.</span></p><p class="c3 c7"><span class="c0"></span></p><p class="c3"><span class="c0">Fait en double exemplaire dont un exemplaire a &eacute;t&eacute; remis &agrave; l&rsquo;adoptant et un autre au refuge.</span></p><p class="c3 c7"><span class="c0"></span></p><p class="c3"><span>Le </span><span class="c8">' DATE DU CONTRAT ' </span><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pour <span class="changeable">' NOM DU REFUGE '</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pour l&rsquo;adoptant</span></p></body>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Menu">
              <div class="card-header" [class.display]="!okay">
              
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title" style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">Types d'animaux à afficher</h5>
                  </div>
                </div>
          
              </div>

              <div class="spinner" [class.display]="!loading">
                <mat-progress-spinner
                    color="warm"
                    mode="indeterminate">
                </mat-progress-spinner>
              </div>

              <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly; padding-top: 25px;" [class.display]="!okay">
                <div style="width: 35%; text-align: center;">
                  ANIMAUX DISPONIBLES

                  <div class="card" style="overflow: hidden; margin-top: 25px;">
                    <mat-list style="max-height: 350px; overflow-y: scroll;">
                      <mat-list-item *ngFor="let colonne of allAnimalTypes" checkboxPosition="before" style="border: solid 1px rgb(225, 225, 225);" (click)="onSelectType(colonne)" [class.selected]="animalTypesSelected.includes(colonne)">
                        {{colonne.type}}
                      </mat-list-item>
                    </mat-list>
                  </div>

                </div>

                <div style="display: flex; flex-direction: column;" (click)="onClickSubmitTypes()">
                  <mat-icon style="font-size: xx-large; color: black; cursor: pointer;" [class.grey]="animalTypesSelected.length === 0">arrow_forward</mat-icon>
                  <mat-icon style="font-size: xx-large; color: black; cursor: pointer;" [class.grey]="animalTypesSelected.length === 0">arrow_backward</mat-icon>
                </div>

                <div style="width: 35%; text-align: center;">
                  ANIMAUX AFFICH&Eacute;S

                  <div class="card" style="overflow: hidden; margin-top: 25px;">
                    <mat-list style="max-height: 350px; overflow-y: scroll;">
                      <mat-list-item *ngFor="let colonne of animalTypes" style="border: solid 1px rgb(225, 225, 225); text-align: center;" (click)="onSelectType(colonne)" [class.selected]="animalTypesSelected.includes(colonne)">
                        {{colonne.type}}
                      </mat-list-item>
                    </mat-list>
                  </div>

                </div>
              </div>
              <div class="col-md-12"  [class.display]="!okay">
                <h5 class="card-title" style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">Nouveau type</h5>
                <div style="display: flex; justify-content: center; align-items: end;">
                  <div class="form-group">
                    <label>Type</label>
                    <input type="text" class="form-control" name="nomType" [(ngModel)]="nouveauType">
                  </div>
                  <button mat-raised-button style="margin-bottom: 10px; margin-left: 10px; height: 40px;" (click)="onClickNewType()">OK</button>
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Clients">
              <div class="card-header" [class.display]="!okay">
              
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="card-title" style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">Choix des donn&eacute;es à afficher</h5>
                  </div>
                </div>
          
              </div>

              <div class="spinner" [class.display]="!loading">
                <mat-progress-spinner
                    color="warm"
                    mode="indeterminate">
                </mat-progress-spinner>
              </div>

              <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly; padding-top: 25px;" [class.display]="!okay">
                
                <div style="width: 35%; text-align: center;">
                  DONN&Eacute;ES DISPONIBLES

                  <div class="card" style="overflow: hidden; margin-top: 25px;">
                    <mat-list style="max-height: 350px; overflow-y: scroll;">
                      <mat-list-item *ngFor="let colonne of colonnesDispoClients" checkboxPosition="before" style="border: solid 1px rgb(225, 225, 225);" (click)="onSelect(colonne)" [class.selected]="colonnesSelected.includes(colonne)">
                        {{colonne.name}}
                      </mat-list-item>
                    </mat-list>
                  </div>

                </div>

                <div style="display: flex; flex-direction: column;" (click)="onClickSubmit(0,0)">
                  <mat-icon style="font-size: xx-large; color: black; cursor: pointer;" [class.grey]="colonnesSelected.length === 1">arrow_forward</mat-icon>
                  <mat-icon style="font-size: xx-large; color: black; cursor: pointer;" [class.grey]="colonnesSelected.length === 1">arrow_backward</mat-icon>
                </div>

                <div style="width: 35%; text-align: center;">
                  DONN&Eacute;ES AFFICH&Eacute;ES

                  <div class="card" style="overflow: hidden; margin-top: 25px;">
                    <mat-list style="max-height: 350px; overflow-y: scroll;">
                      <mat-list-item *ngFor="let colonne of colonnesAfficheesClients" style="border: solid 1px rgb(225, 225, 225); text-align: center;" (click)="onSelect(colonne)" [class.selected]="colonnesSelected.includes(colonne)">
                        {{colonne.name}}
                      </mat-list-item>
                    </mat-list>
                  </div>

                </div>
              </div>

              <div class="col-md-12"  [class.display]="!okay">
                <h5 class="card-title" style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">Nouvelle donn&eacute;e</h5>
                <div style="display: flex; justify-content: center; align-items: end;">
                  <div class="form-group">
                    <label>Nom</label>
                    <input type="text" [(ngModel)]="nomNouvelleColonne" class="form-control" name="nomColonne">
                  </div>
                  <div class="form-group" style="margin-left: 10px;">
                    <label>Type</label>
                    <select name="typeColonne" [(ngModel)]="typeNouvelleColonne" class="form-control">

                      <option value="VARCHAR(255)">Texte</option>
                      <option value="DATE">Date</option>
                      <option value="TINYINT(1)">Item cochable</option>

                    </select>
                  </div>
                  <button mat-raised-button style="margin-bottom: 10px; margin-left: 10px; height: 40px;" (click)="onClickNew(0)">OK</button>
                </div>
              </div>
            </mat-tab>

            <div *ngFor="let type of animalTypes; index as i;">
              <mat-tab [label]="type.type">
                <div class="card-header">
              
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="card-title" style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">Choix des donn&eacute;es à afficher</h5>
                    </div>
                  </div>
            
                </div>
                <div class="spinner" [class.display]="!loading">
                  <mat-progress-spinner
                      color="warm"
                      mode="indeterminate">
                  </mat-progress-spinner>
                </div>

                <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly; padding-top: 25px;" [class.display]="!okay">
                  <div style="width: 35%; text-align: center;">
                    DONN&Eacute;ES DISPONIBLES

                    <div class="card" style="overflow: hidden; margin-top: 25px;">
                      <mat-list style="max-height: 350px; overflow-y: scroll;">
                        <mat-list-item *ngFor="let colonne of colonnesDispo[i]" checkboxPosition="before" style="border: solid 1px rgb(225, 225, 225);" (click)="onSelect(colonne)" [class.selected]="colonnesSelected.includes(colonne)">
                          {{colonne.name}}
                        </mat-list-item>
                      </mat-list>
                    </div>
  
                  </div>
  
                  <div style="display: flex; flex-direction: column;" (click)="onClickSubmit(type.animaltype, i)">
                    <mat-icon style="font-size: xx-large; color: black; cursor: pointer;" [class.grey]="colonnesSelected.length === 1">arrow_forward</mat-icon>
                    <mat-icon style="font-size: xx-large; color: black; cursor: pointer;" [class.grey]="colonnesSelected.length === 1">arrow_backward</mat-icon>
                  </div>
  
                  <div style="width: 35%; text-align: center;">
                    DONN&Eacute;ES AFFICHEES
  
                    <div class="card" style="overflow: hidden; margin-top: 25px;">
                      <mat-list style="max-height: 350px; overflow-y: scroll;">
                        <mat-list-item *ngFor="let colonne of colonnesAffichees[i]" style="border: solid 1px rgb(225, 225, 225); text-align: center;" (click)="onSelect(colonne)" [class.selected]="colonnesSelected.includes(colonne)">
                          {{colonne.name}}
                        </mat-list-item>
                      </mat-list>
                    </div>
  
                  </div>
                </div>
                <div class="col-md-12"  [class.display]="!okay">
                  <h5 class="card-title" style="width: 100%; display: flex; align-items: center; justify-content: space-evenly;">Nouvelle donn&eacute;e</h5>
                  <div style="display: flex; justify-content: center; align-items: end;">
                    <div class="form-group">
                      <label>Nom</label>
                      <input type="text" [(ngModel)]="nomNouvelleColonne" class="form-control" name="nomColonne">
                    </div>
                    <div class="form-group" style="margin-left: 10px;">
                      <label>Type</label>
                      <select name="typeColonne" [(ngModel)]="typeNouvelleColonne" class="form-control">

                        <option value="VARCHAR(255)">Texte</option>
                        <option value="DATE">Date</option>
                        <option value="TINYINT(1)">Item cochable</option>

                      </select>
                    </div>
                    <button mat-raised-button style="margin-bottom: 10px; margin-left: 10px; height: 40px;" (click)="onClickNew(type.animaltype)">OK</button>
                  </div>
                </div>
              </mat-tab>
            </div>

            <!--

              <mat-tab label="Chiens">
              <div style="width: 100%; display: flex; align-items: center; justify-content: space-evenly; padding-top: 25px;">
                <div style="width: 35%; text-align: center;">
                  COLONNES DISPONIBLES

                  <div class="card" style="overflow: hidden; margin-top: 25px;">
                    <mat-selection-list style="max-height: 350px; overflow-y: scroll;">
                      <mat-list-option *ngFor="let colonne of colonnesDispo" checkboxPosition="before" style="border: solid 1px rgb(225, 225, 225);">
                        {{colonne.name}}
                      </mat-list-option>
                    </mat-selection-list>
                  </div>

                </div>

                <div>
                  <mat-icon style="font-size: xx-large; color: black; cursor: pointer;">arrow_forward</mat-icon>
                </div>

                <div style="width: 35%; text-align: center;">
                  COLONNES AFFICHEES

                  <div class="card" style="overflow: hidden; margin-top: 25px;">
                    <mat-list style="max-height: 350px; overflow-y: scroll; ">
                      <mat-list-item *ngFor="let colonne of colonnesAffichees" style="border: solid 1px rgb(225, 225, 225); text-align: center;">
                        
                      </mat-list-item>
                    </mat-list>
                  </div>

                </div>
              </div>
            </mat-tab>

            -->
            
          </mat-tab-group>

        </div>
      </div>
    </div>
  </div>
</div>

