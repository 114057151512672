import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
// import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	
	constructor(
		// private toastr: ToastrService, 
		private router: Router
	) {}

	 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		 
		var auth = 'notoken';
		if (localStorage.getItem('auth')) {
			auth = localStorage.getItem('auth')!;
		}
		// console.log(auth);
		
		request = request.clone({
		  headers: request.headers
			.set('Content-Type',  'application/json') // this is a CLIENT & SERVER SIDE header
			.set('Accept', auth), // this is a CLIENT & SERVER SIDE header
		});
		
	   return next.handle(request)
		 .pipe(
		   retry(1),
		   catchError((error: any) => {
			   
					if (error instanceof HttpErrorResponse) {
						
						console.log(" -- ERROR CATCHED -- : " + error.status);
					 
					  if (error.error instanceof ErrorEvent) {
						  
						console.log("Error Event");
						
					  } else {
						  
						console.log(`error status : ${error.status} ${JSON.stringify(error.error)}`);
						
						switch (error.status) {
						  case 403:
							localStorage.removeItem('auth');
							this.router.navigateByUrl("/connexion");
							break;
						  case 0:
						  case 401:
						  case 400:
						  case 405:
						  case 406:
						  case 409:
						  case 500:
							this.handleAuthError(error);
							break;
						}
					}
					
				} else {
					
				  console.error("something else happened");
				  
				}

				return throwError(error);
				
		   })
		 )
	 }
 
	public handleAuthError(error: any) {
		console.log("error ", error);
		let msg = "";
		if (error !== undefined && typeof error === "string") {
		  msg = error;
		} else if (error.error !== undefined && typeof error.error === "string") {
		  msg = error.error;
		} else if (
		  error.error.error !== undefined &&
		  typeof error.error.error === "string"
		) {
		  msg = error.error.error;
		} else {
		  msg = error.error.error.errors
			? error.error.error.errors[0].errorMessage
			: "Something went wrong";
		}
		// this.toastr.error(msg, "", {
		  // timeOut: 3000,
		  // positionClass: "toast-bottom-center",
		// });
	  }
}